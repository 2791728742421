import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

function NewsletterForm(props) {
  const { url, children, dispatch: _dispatch, ...other } = props

  if (!url) return null

  const handleSubmit = () => {
    // Blur the input field in order to close the keyboard on iOS (see UNITY-5604)
    document.activeElement.blur()
  }

  return (
    <form {...other} action={url} method="post" target="_blank" onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

NewsletterForm.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  url: PropTypes.string,
}

export default connect((state) => {
  // The newsletter ScriptTag is a special case for "CleverReach" only.
  const scriptTags = state.get('scriptTags')
  const item = scriptTags && scriptTags.find((item) => item.get('type') === 'NEWSLETTER')

  return {
    url: item && item.get('url'),
  }
})(NewsletterForm)
