import PropTypes from 'prop-types'
import React from 'react'

import Content from '../../templateComponents/Workspace'

export default function Home({ themeSettings }) {
  return (
    <div className="template-home">
      <pre>
        Version 2 Home.rt Theme Settings:
        {JSON.stringify(themeSettings, 0, 2)}
      </pre>
      <hr />
      <Content />
    </div>
  )
}

Home.propTypes = { themeSettings: PropTypes.object.isRequired }
