import PropTypes from 'prop-types'
import React from 'react'

import Link from '../../templateComponents/Link'

export default function ErrorComponent({ t, error, shop }) {
  return (
    <div className="error-page">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>{t('views.storefrontView.errorPageSection.errorcode', { code: error.statusCode })}</h1>
            <div className="error-page-info">
              <h3>
                {t(
                  error.statusCode === 404
                    ? 'views.storefrontView.errorPageSection.error404'
                    : 'views.storefrontView.errorPageSection.error500',
                )}
              </h3>
              {error.requestId && (
                <p className="error-page-info-explanation">
                  {t('views.storefrontView.errorPageSection.idExplanation')} <code>{error.requestId}</code>
                </p>
              )}
              <p>
                <Link to={shop.href} className="error-page-info-link">
                  {t('views.storefrontView.returnToStartpageLink.label')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ErrorComponent.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
}
