import PropTypes from 'prop-types'
import React from 'react'

import Breadcrumb from '../epages.base/Breadcrumb'
import CategoryContent from '../../templateComponents/CategoryContent'
import Content from '../../templateComponents/Workspace'
import ScrollToButton from '../../ScrollToButton'
import ToolbarTop from '../../templateComponents/ToolbarTop'

export default function Category({
  t,
  breadcrumb,
  productData,
  category,
  sortingOptions,
  onScrollIntoView,
  showScrollButton,
}) {
  return (
    <div className="category">
      <section className="section">
        <div className="wrapper">
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <ToolbarTop onScrollIntoView={onScrollIntoView} />
            <h1 className="category-headline">{category.title}</h1>
            <CategoryContent
              categoryId={category.categoryId}
              productData={productData}
              pageSize={12}
              t={t}
              sortingOptions={sortingOptions}
              categoryData={category}
              showTopbar={false}
            />
            {showScrollButton && <ScrollToButton className="scroll-to-button" t={t} />}
          </div>
        </div>
      </section>
      <section className="section-dali">
        <Content />
      </section>
    </div>
  )
}

Category.propTypes = {
  t: PropTypes.func.isRequired,
  breadcrumb: PropTypes.array.isRequired,
  category: PropTypes.object.isRequired,
  productData: PropTypes.object.isRequired,
  sortingOptions: PropTypes.object.isRequired,
  onScrollIntoView: PropTypes.func.isRequired,
  showScrollButton: PropTypes.bool.isRequired,
}
