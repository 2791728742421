import { isPlainObject, kebabCase, merge, set } from 'lodash'
import { object, oneOf } from 'prop-types'
import { useSelector } from 'react-redux'
import React from 'react'
import buildURL from 'axios/lib/helpers/buildURL'

import * as BaseComponents from './themeComponents/epages.base'
import * as EditorialComponents from './themeComponents/epages.editorial'
import * as EssenceComponents from './themeComponents/epages.essence'
import * as LimelightComponents from './themeComponents/epages.limelight'
import * as NeutralComponents from './themeComponents/epages.neutral'
import * as StructureComponents from './themeComponents/epages.structure'
import * as Test2Components from './themeComponents/epages.test2'
import * as TestComponents from './themeComponents/epages.test'
import * as UptownComponents from './themeComponents/epages.uptown'
import * as VisionComponents from './themeComponents/epages.vision'
import { createT } from '../utils/translate'
import { i18nInstancesContext } from './I18nProvider'

const themes = {}
themes['epages.neutral'] = { ...BaseComponents, ...NeutralComponents }
themes['epages.editorial'] = { ...themes['epages.neutral'], ...EditorialComponents }
themes['epages.essence'] = { ...BaseComponents, ...EssenceComponents }
themes['epages.structure'] = { ...themes['epages.essence'], ...StructureComponents }
themes['epages.test'] = { ...BaseComponents, ...TestComponents }
themes['epages.test2'] = { ...themes['epages.test'], ...Test2Components }
themes['epages.uptown'] = { ...themes['epages.neutral'], ...UptownComponents }
themes['epages.vision'] = { ...BaseComponents, ...VisionComponents }
themes['epages.limelight'] = { ...themes['epages.essence'], ...LimelightComponents }

export function imageUrl(url, width, height) {
  return isPlainObject(width) ? buildURL(url, width) : buildURL(url, { width, height })
}

export default function ThemeView({ view, ...props }) {
  const name = useSelector((state) => state.getIn(['shop', 'theme', 'name']))
  const isBeyond = useSelector((state) => Boolean(state.getIn(['shop', 'beyond'])))
  const hashedThemeAssets = useSelector((state) => state.getIn(['view', 'hashedThemeAssets']))
  const { shopI18n } = React.useContext(i18nInstancesContext)
  const translate = createT(shopI18n, isBeyond)

  if (props && props.shop) {
    // set a boolean on social networks as indicator if at least one social network is active
    props = merge({}, props, {
      shop: {
        userSettings: {
          socialNetworks: {
            show: Object.keys(props.shop.userSettings.socialNetworks).some(
              (key) => props.shop.userSettings.socialNetworks[key] === true,
            ),
          },
        },
      },
    })
    props.themeSettings = merge(
      {},
      props.shop.theme.themeSettings.settings,
      props.shop.themeStyle.themeStyleSettings.settings,
    )
    // only pass activated header USPs to the theme
    set(
      props,
      'shop.userSettings.headerUsps',
      (props.shop.userSettings.headerUsps || []).filter((usp) => usp.active),
    )
    // only pass activated payment logos to the theme
    set(
      props,
      'shop.userSettings.paymentLogos',
      (props.shop.userSettings.paymentLogos || [])
        .filter((logo) => logo.active)
        .map((logo) => ({
          ...logo,
          filename: hashedThemeAssets.getIn(['payments', `${kebabCase(logo.id)}.svg`]),
        })),
    )
    // only pass activated shipping logos to the theme
    set(
      props,
      'shop.userSettings.shippingLogos',
      (props.shop.userSettings.shippingLogos || [])
        .filter((logo) => logo.active)
        .map((logo) => ({
          ...logo,
          filename: hashedThemeAssets.getIn(['logistics', logo.filename]),
        })),
    )
  }

  const Component = themes[name][view]

  return <Component t={translate} {...props} />
}

ThemeView.propTypes = {
  shop: object,
  view: oneOf([
    'Error',
    'Checkout',
    'ForgotPassword',
    'Legal',
    'Order',
    'Page',
    'ResetPassword',
    'Cart',
    'Category',
    'FacetedSearch',
    'Home',
    'Layout',
    'Product',
    'Search',
  ]),
}
