import { bool, object } from 'prop-types'
import { connect } from 'react-redux'
import { set } from 'lodash'
import React from 'react'

import Category from './Category'
import Page from './Page'

const shouldUsePageComponent = (state) => Boolean(state.getIn(['shop', 'beyond']))

function setRouterProps(props, usePageComponent) {
  if (usePageComponent) return set(props, 'params.pageSlug', 'home')
  return set(props, 'isHomepage', !props.params.splat)
}

class Home extends React.Component {
  static propTypes = {
    usePageComponent: bool.isRequired,
    params: object.isRequired,
  }

  static storeUpdate = (props, state) => {
    const usePageComponent = shouldUsePageComponent(state)
    const Component = usePageComponent ? Page : Category

    return Component.storeUpdate(setRouterProps(props, usePageComponent), state)
  }

  render() {
    const { usePageComponent, ...otherProps } = this.props
    const Component = usePageComponent ? Page : Category

    return <Component {...setRouterProps(otherProps, usePageComponent)} />
  }
}

export default connect((state) => ({
  usePageComponent: shouldUsePageComponent(state),
}))(Home)
