import { bool, func, number, shape, string } from 'prop-types'
import React from 'react'
import cc from 'classcat'

import EnergyLabel from './EnergyLabel'
import LazyImage from '../LazyImage'
import Link from './Link'
import PlaceholderImage from '../PlaceholderImage'
import translate from '../../utils/translate'
import withI18n from '../withI18n'

export default withI18n('shop')(translate()(ProductListItem))

function ProductListItem({ t, product, productIndex, trackProductClick }) {
  return (
    <div className="product-item" itemScope itemType="http://schema.org/Product">
      <Link
        to={product.href}
        className="product-item-link"
        onClick={() => trackProductClick && trackProductClick(product, productIndex)}
      >
        <div className="product-item-image-container">
          {product.image ? (
            <LazyImage
              className="product-item-image"
              src={product.image.url}
              width={585}
              height={585}
              alt={product.name}
            />
          ) : (
            <PlaceholderImage className="product-item-image" width="540" />
          )}
        </div>
        <h2 className="product-item-title" itemProp="name">
          {product.name}
        </h2>
        {product.gtin && <link itemProp={`gtin${product.gtin.length}`} content={product.gtin} />}
      </Link>
      <div>
        {product.energyLabel && (
          <EnergyLabel t={t} energyLabel={product.energyLabel} energyLabelSourceFile={product.energyLabelSourceFile} />
        )}
        {(product.price || product.lowestPrice) && (
          <div
            className={cc([
              'product-item-price',
              { 'no-stock-level': !product.hasStockLevel, 'has-variations': product.hasVariations },
            ])}
            itemProp="offers"
            itemScope
            itemType="http://schema.org/Offer"
          >
            {product.price && (
              <>
                <link itemProp="price" content={product.price.amount} />
                <link itemProp="priceCurrency" content={product.price.currency} />
              </>
            )}
            {product.stockLevelMicrodata && (
              <link itemProp="availability" href={`http://schema.org/${product.stockLevelMicrodata}`} />
            )}
            {product.basePrice && <span className="product-item-price-reference">({product.basePrice.formatted})</span>}
            {!product.lowestPrice && (
              <h3 className="product-item-price-new">
                {product.bulkPrices?.find((bulkPrice) => bulkPrice.quantity.amount === 1)?.price.formatted ||
                  product.price.formatted}
              </h3>
            )}
            {product.listPrice && !product.hasVariations && (
              <h3 className="product-item-price-old">{product.listPrice.formatted}</h3>
            )}
            {!product.listPrice && product.manufacturerPrice && !product.hasVariations && (
              <h3 className="product-item-price-old">{product.manufacturerPrice.formatted}</h3>
            )}
            {product.lowestPrice && (
              <h3 className="product-item-price-new">
                {t('components.productComponent.variationLowestPrice', {
                  amount: product.lowestPrice.formatted,
                })}
              </h3>
            )}
          </div>
        )}
      </div>
      {product.hasVariations && (
        <div className="product-item-availability">
          <Link to={product.href} className="product-item-availability-variations">
            {t('components.productComponent.variationsAvailable')}
          </Link>
        </div>
      )}
      {product.stockLevelClass && (
        <div className="product-item-availability">
          {product.availabilityText.includes('NOT_AVAILABLE') ? (
            <span className="product-item-availability-info">
              {t('components.productComponent.availability.notAvailableNote')}
            </span>
          ) : (
            <>
              <span className={`product-item-availability-icon ${product.stockLevelClass}-stock-icon`} />
              <span className="product-item-availability-info">{t(product.availabilityText)}</span>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const priceShape = shape({
  amount: number.isRequired,
  currency: string.isRequired,
  formatted: string.isRequired,
})

ProductListItem.propTypes = {
  t: func.isRequired,
  product: shape({
    gtin: string,
    name: string.isRequired,
    href: string.isRequired,
    image: shape({ url: string.isRequired }),
    hasStockLevel: bool,
    hasVariations: bool,
    stockLevelClass: string,
    availabilityText: string.isRequired,
    energyLabel: shape({ colorId: number.isRequired, text: string.isRequired }),
    energyLabelSourceFile: string,
    price: priceShape,
    basePrice: shape({ formatted: string.isRequired }),
    lowestPrice: priceShape,
    manufacturerPrice: priceShape,
    stockLevelMicrodata: string,
  }).isRequired,
  productIndex: number.isRequired,
  trackProductClick: func,
}
