import PropTypes from 'prop-types'
import React from 'react'

import { imageUrl } from '../../ThemeView'
import LazyImage from '../../LazyImage'

const Payments = ({ paymentLogos, t }) => {
  if (!paymentLogos.length) return null
  return (
    <ul className="payments">
      {paymentLogos.map((logo) => (
        <li className="payments-item" key={logo.id}>
          <div className="payments-item-box">
            <LazyImage
              className="payments-item-box-logo"
              src={logo.path ? imageUrl(logo.path) : `/themes/epages.base/assets/payments/${logo.filename}`}
              srcSet={
                logo.path
                  ? `${imageUrl(logo.path, { height: 22 })} 1x, ${imageUrl(logo.path, { height: 44 })} 2x`
                  : null
              }
              alt={logo.title || t(`components.bottomBar.footerSettings.paymentLogos.paymentNames.${logo.id}`)}
              title={logo.title || t(`components.bottomBar.footerSettings.paymentLogos.paymentNames.${logo.id}`)}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}

Payments.propTypes = {
  paymentLogos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string,
      title: PropTypes.string,
      filename: PropTypes.string,
    }),
  ),
  t: PropTypes.func.isRequired,
}
Payments.defaultProps = {
  paymentLogos: [],
}

export default Payments
