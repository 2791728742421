import PropTypes from 'prop-types'
import React from 'react'

export default class DelayUpdate extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    promise: PropTypes.any.isRequired,
    instant: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super()
    this.willUnmount = false
    this.state = {
      children: props.children,
    }
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.promise !== this.props.promise) {
      if (!this.props.instant) {
        this.props.promise.then(() => {
          if (!this.willUnmount) {
            this.setState({ children: this.props.children })
          }
          return null
        })
      } else {
        this.setState({ children: this.props.children })
      }
    }
  }

  render() {
    return this.state.children
  }
}
