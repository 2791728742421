import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { submitContactForm } from '../../../../../store/actions'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

export default Object.assign(compose(withI18n('shop'), translate())(ContactFormPlugin), {
  actionBarButtons: { save: false, edit: false },
})

const EditorHint = compose(withI18n('interface'), translate())(EditorHintRaw)

const scoped = (t) => (key, ...args) => t('components.contactFormComponent.' + key, ...args)

const maxInputLength = 320
const maxMessageLength = 2000

function ContactFormPlugin({ t, editorView }) {
  const dispatch = useDispatch()
  const csrfToken = useSelector((state) => state.getIn(['shop', 'csrfToken']))
  const [charCount, setCharCount] = useState(0)
  const [requestSent, setRequestSent] = useState(false)
  const scopedT = scoped(t)

  return (
    <div className="dali-plugin-contactform">
      <form
        className="dali-form"
        name="contact"
        autoComplete="on"
        onSubmit={handleSubmit}
        onInvalid={(e) => e.target.classList.add('invalid')}
        onChange={(event) => {
          event.target.classList.toggle('invalid', !event.target.validity.valid)
          setRequestSent(false)
        }}
        method="post"
        action="/api/v2/report-violation"
      >
        {requestSent && <p className="success-message">{scopedT('successMessage')}</p>}
        <div className="dali-form-group">
          <label>
            {scopedT('nameInputField.label')}*
            <input
              name="name"
              type="text"
              autoComplete="name"
              required
              maxLength={maxInputLength}
              disabled={editorView}
            />
          </label>
          <label>
            {scopedT('emailInputField.label')}*
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              maxLength={maxInputLength}
              disabled={editorView}
            />
          </label>
        </div>
        <label>
          {scopedT('messageInputField.label')}*
          <span className="dali-form-counter">
            {scopedT('characterCounter', { length: charCount, limit: maxMessageLength })}
          </span>
          <textarea
            onChange={(e) => setCharCount(e.target.value.length)}
            name="message"
            required
            maxLength={maxMessageLength}
            disabled={editorView}
          />
        </label>
        <label className="box-of-sweet-nectar">
          {scopedT('honeypotInputField.label')}
          <input name="_tac" defaultValue="" autoComplete="off" tabIndex="999" />
        </label>
        <button>{scopedT('sendButton.label')}</button>
      </form>
      {editorView && <EditorHint />}
    </div>
  )

  async function handleSubmit(event) {
    event.preventDefault()
    event.persist() // thank your for nothing, React

    try {
      const formData = new FormData(event.target)

      // axios can only send multipart form data, and body-parser can only parse urlencoded form data,
      // so let's just use JSON ¯\_(ツ)_/¯
      const payload = {}
      for (const [key, value] of formData) payload[key] = value

      // Don't submit if the honeypot field is filled
      if (payload._fax) return

      await dispatch(submitContactForm(payload, csrfToken))
      event.target.reset()
      setCharCount(0)
      setRequestSent(true)
    } catch {}
  }
}

function EditorHintRaw({ t }) {
  const email = useSelector((state) => state.getIn(['shop', 'email']))
  return <span className="dali-block-hint">{scoped(t)('emailDestinationExplanation', { email })}</span>
}

EditorHintRaw.propTypes = { t: PropTypes.func.isRequired }

ContactFormPlugin.propTypes = {
  editorView: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}
