import { clamp, debounce, memoize, omit, pick } from 'lodash'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import { GotoContext } from '../GotoProvider'
import { generateProductUrl, generateSearchUrl } from '../../urlGenerators'
import { updateSuggestedSearch, updateSuggestedSearchSelection } from '../../store/actions'
import compose from '../../utils/compose'
import createPromiseLinearizer from '../../utils/createPromiseLinearizer'
import delay from '../../utils/delay'
import translate from '../../utils/translate'
import withI18n from '../../components/withI18n'
import withReduxContext from '../../utils/withReduxContext'

const KeyCodes = {
  ESCAPE: 27,
  RETURN: 13,
  UP: 38,
  DOWN: 40,
}

const NamespaceContext = React.createContext()

// https://github.com/oliviertassinari/babel-plugin-transform-react-remove-prop-types/blob/v0.4.15/README.md#is-it-safe
export const SuggestedSearchRawPropTypes = {
  children: PropTypes.node.isRequired,
  namespace: PropTypes.string.isRequired,
  resetSearch: PropTypes.func.isRequired,
  tagName: PropTypes.oneOf(['form', 'div']),
  className: PropTypes.string,
  focusClassName: PropTypes.string,
  blurDelay: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
}

export class SuggestedSearchRaw extends React.Component {
  static propTypes = SuggestedSearchRawPropTypes

  static defaultProps = {
    tagName: 'form',
    focusClassName: 'focused',
  }

  static contextType = GotoContext

  state = {
    isFocused: false,
  }

  reset() {
    const maybeDelaying = this.props.blurDelay ? delay(this.props.blurDelay) : Promise.resolve()

    maybeDelaying.then(() => {
      this.props.resetSearch()
      this.setState({ isFocused: false })
    })
  }

  handleFocus = () => !this.state.isFocused && this.setState({ isFocused: true })

  handleBlur = (event) => {
    // Reset only if blurred out of the container element.
    // (`event.relatedTarget` is the DOM element which received focus.)
    if (!event.relatedTarget || !this.domNode.contains(event.relatedTarget)) {
      this.reset()
    }
  }

  handleClick = (event) => {
    // Reset if clicked on a link of a suggested item.
    if (event.target.closest('a')) {
      this.reset()
    }
  }

  performSearch = (query) => {
    this.reset()
    // open the search with the search query
    this.context.goto(generateSearchUrl(query))
  }

  handleSubmit = (event) => this.props.onSubmit(event, this.performSearch)

  render() {
    const TagName = this.props.tagName
    const htmlAttributes = omit(this.props, Object.keys(SuggestedSearchRawPropTypes))

    // tabIndex="-1" makes the element focusable (but not reachable via keyboard navigation),
    // so that we can figure out if blurred out of the container in the blur event handler.
    return (
      <NamespaceContext.Provider value={this.props.namespace}>
        <TagName
          ref={(node) => (this.domNode = node)}
          tabIndex="-1"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onClick={this.handleClick}
          onSubmit={this.handleSubmit}
          {...htmlAttributes}
          className={cc([
            this.props.className,
            {
              [this.props.focusClassName]: this.state.isFocused,
            },
          ])}
        >
          {this.props.children}
        </TagName>
      </NamespaceContext.Provider>
    )
  }
}

// https://github.com/oliviertassinari/babel-plugin-transform-react-remove-prop-types/blob/v0.4.15/README.md#is-it-safe
export const SearchFieldRawPropTypes = {
  t: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  resetSearch: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
  updateSelection: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  className: PropTypes.string,
  i18n: PropTypes.object,
  maxSuggestedItems: PropTypes.number,
  placeholder: PropTypes.string,
  results: ImmutablePropTypes.list,
  searchTerm: PropTypes.string,
  selected: PropTypes.number,
  tReady: PropTypes.bool,
}

export class SearchFieldRaw extends React.Component {
  static propTypes = SearchFieldRawPropTypes

  static defaultProps = {
    // 10 is the jREST default limit
    maxSuggestedItems: 10,
    searchTerm: '',
  }

  static contextType = GotoContext

  constructor(props) {
    super(props)

    this.state = {
      query: props.searchTerm,
      emptyQueryError: false,
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerm !== this.state.query) {
      this.setState({
        query: nextProps.searchTerm,
      })
    }
  }

  linearizer = createPromiseLinearizer()

  sendSearch = memoize(
    (query) => {
      return this.props.store.api.get('/api/v2/suggestedSearch', {
        params: {
          q: query,
          limit: this.props.maxSuggestedItems,
          locale: this.props.locale,
        },
      })
    },
    (query) => `${this.props.locale}-${query}`,
  )

  search(query) {
    if (query.length > 1) {
      return this.linearizer(() => this.sendSearch(query))
        .then((res) => Immutable.fromJS(res.data))
        .then((products) =>
          products.map((product) => {
            return product.set('href', generateProductUrl(product.toJS())).set('active', false)
          }),
        )
    }

    return Promise.resolve(null)
  }

  getResults(event) {
    const { results, selected, resetSearch } = this.props

    const selectedItem = Number.isInteger(selected) ? results.get(selected) : null

    const query = this.state.query.trim()
    resetSearch()

    if (selectedItem) {
      // blur the input to hide keyboard on mobile devices
      event.target.blur()
      // open the selected product from the suggested search
      this.context.goto(selectedItem.get('href'))
    } else if (query.length > 0) {
      // blur the input to hide keyboard on mobile devices
      event.target.blur()
      // open the search with the search query
      this.context.goto(generateSearchUrl(query))
    } else {
      // the query is empty on submission, so show an error
      this.setState({ emptyQueryError: true })
    }
  }

  moveSelection(delta) {
    const { selected, results, updateSelection } = this.props

    if (Number.isInteger(selected)) {
      updateSelection(clamp(selected + delta, 0, results.count() - 1))
    } else {
      updateSelection(clamp(delta > 0 ? 0 : results.count() - 1, 0, results.count() - 1))
    }
  }

  clearEmptyQueryError() {
    if (this.state.emptyQueryError) {
      this.setState({ emptyQueryError: false })
    }
  }

  updateQuery = debounce((query) => {
    const { updateSearch, updateSelection, selected } = this.props

    this.search(query).then((products) => {
      if (this.inputNode === document.activeElement && query === this.state.query) {
        updateSearch(query, products)
        if (selected) updateSelection(null)
      }
    })
  }, 800)

  handleChange = (event) => {
    const { value } = event.target

    this.setState({ query: value })
    this.updateQuery(value)
  }

  handleKeyDown = (event) => {
    switch (event.keyCode) {
      case KeyCodes.ESCAPE:
        this.props.resetSearch()
        this.clearEmptyQueryError()
        break
      case KeyCodes.RETURN:
        event.preventDefault()
        this.getResults(event)
        break
      case KeyCodes.UP:
        event.preventDefault()
        this.moveSelection(-1)
        break
      case KeyCodes.DOWN:
        event.preventDefault()
        this.moveSelection(+1)
        break
      default:
        this.clearEmptyQueryError()
        break
    }
  }

  handleBlur = () => this.clearEmptyQueryError()

  render() {
    const { className, t } = this.props
    const { emptyQueryError } = this.state

    // extend the className from the template with the error-class if needed
    const classes = cc([
      className,
      {
        'empty-error': emptyQueryError,
      },
    ])

    // use the placeholder the theme developer hands over as long as no error happens
    const placeholder = emptyQueryError
      ? t('components.productSearchComponent.searchInputField.validationMessages.noEmptySubmit')
      : this.props.placeholder

    const htmlAttributes = omit(this.props, Object.keys(SearchFieldRawPropTypes))

    return (
      <input
        {...htmlAttributes}
        ref={(node) => (this.inputNode = node)}
        type="text"
        maxLength={200}
        value={this.state.query}
        className={classes}
        placeholder={placeholder}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      />
    )
  }
}

export class ResultsListRaw extends React.Component {
  static propTypes = {
    renderItems: PropTypes.func.isRequired,
    results: ImmutablePropTypes.list,
    selected: PropTypes.number,
  }

  render() {
    const { results, selected } = this.props
    if (!results || results.count() === 0) return null

    return this.props.renderItems(
      results.toJS().map((item, index) => ({
        ...item,
        active: index === selected,
      })),
    )
  }
}

function mapStateToProps(state, ownProps) {
  const path = ['view', 'suggestedSearch', ownProps.namespace]

  return {
    searchTerm: state.getIn(path.concat('searchTerm')),
    results: state.getIn(path.concat('results')),
    selected: state.getIn(path.concat('selected')),
    locale: state.getIn(['shop', 'locale']),
  }
}

function createMapDispatchToProps(...methodNamesToPick) {
  return (dispatch, ownProps) => {
    const { namespace } = ownProps
    const methods = {
      updateSearch: (searchTerm, results) => dispatch(updateSuggestedSearch(namespace, searchTerm, results)),
      updateSelection: (index) => dispatch(updateSuggestedSearchSelection(namespace, index)),
      resetSearch: () => {
        dispatch(updateSuggestedSearch(namespace, '', null))
        dispatch(updateSuggestedSearchSelection(namespace, null))
      },
    }

    return methodNamesToPick.length ? pick(methods, methodNamesToPick) : methods
  }
}

function withNamespace(WrappedComponent) {
  const Component = (props) => (
    <NamespaceContext.Consumer>
      {(namespace) => <WrappedComponent {...props} namespace={namespace} />}
    </NamespaceContext.Consumer>
  )
  Component.displayName = `${WrappedComponent.displayName}WithNamespace`

  return Component
}

const SuggestedSearch = connect(null, createMapDispatchToProps('resetSearch'))(SuggestedSearchRaw)

export const SearchField = compose(
  withI18n('shop'),
  translate(),
  withNamespace,
  connect(mapStateToProps, createMapDispatchToProps()),
  withReduxContext,
)(SearchFieldRaw)

export const ResultsList = compose(withNamespace, connect(mapStateToProps))(ResultsListRaw)

export default SuggestedSearch
