import { func, oneOf, string } from 'prop-types'
import { useSelector } from 'react-redux'
import React from 'react'

import LazyImage from './LazyImage'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

const widths = ['180', '360', '540', '720', '900', '1080']

export function PlaceholderImageRaw({ t, className, width, namePrefix = 'placeholder_' }) {
  const hashedThemeAssets = useSelector((state) => state.getIn(['view', 'hashedThemeAssets']))

  return (
    <LazyImage
      src={`/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
        'epages.base/assets/images',
        `${namePrefix}${width || '540'}.jpg`,
      ])}`}
      srcSet={
        width
          ? null
          : widths
              .map(
                (w) =>
                  `/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
                    'epages.base/assets/images',
                    `${namePrefix}${w}.jpg`,
                  ])} ${w}w`,
              )
              .join(',')
      }
      sizes={width ? null : 'auto'}
      alt={t('components.productComponent.placeholderImageAltText')}
      className={className}
      width={width}
    />
  )
}

PlaceholderImageRaw.propTypes = {
  t: func.isRequired,
  className: string,
  width: oneOf(widths),
  namePrefix: string,
}

export default compose(withI18n('shop'), translate())(PlaceholderImageRaw)
