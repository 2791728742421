import PropTypes from 'prop-types'
import React from 'react'

const SearchSortingForm = ({ t, searchData }) => {
  return (
    <div className="sorting">
      <button type="button" className="sorting-button">
        {t('components.productListComponent.sortingButton.label')}
      </button>
      <span>{t('components.productListComponent.sortingDropdown.label')}</span>
      <div className="sorting-select">
        <select
          value={searchData.sortings.find((s) => s.active).sorting}
          onChange={(e) => searchData.updateSorting(e.target.value)}
        >
          {searchData.sortings.map((sorting) => (
            <option value={sorting.sorting} key={sorting.label}>
              {t('components.productListComponent.sortingDropdown.options.' + sorting.label)}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

SearchSortingForm.propTypes = {
  t: PropTypes.func.isRequired,
  searchData: PropTypes.shape({
    sortings: PropTypes.array.isRequired,
    updateSorting: PropTypes.func.isRequired,
  }).isRequired,
}

export default SearchSortingForm
