import { bool, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import cc from 'classcat'
import cookies from 'js-cookie' // used on the client only

import { declineCookieConsent } from '../store/actions'
import Portal from './templateComponents/Portal'
import ScrollShadow from './ScrollShadow'
import translate from '../utils/translate'

function CookieConsent({ t, inline = false, isEditor }) {
  const [isOnSmallScreen, setIsOnSmallScreen] = React.useState(false)
  const cookieNoticeSettings = useSelector((state) => state.getIn(['shop', 'userSettings', 'cookieNotice']))
  const cookieConsent = useSelector((state) => state.get('cookieConsent'))
  const showInline = inline && (cookieConsent !== null || isEditor)
  const showInPortal = !inline && !isEditor && cookieConsent === null && typeof window !== 'undefined'
  const [isBodyClosed, setIsBodyClosed] = React.useState(false)
  const [hasAccepted, setHasAccepted] = React.useState(cookieConsent)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)')
    const listener = () => setIsOnSmallScreen(mediaQuery.matches)
    mediaQuery.addListener(listener)
    listener()
    return () => mediaQuery.removeListener(listener)
  }, [])

  if (!cookieNoticeSettings.get('active')) return null

  const handleDecline = () => {
    if (cookieConsent === false) return

    // expires defines expiration of the cookie in days.
    cookies.set('epCookieConsent', '0', { expires: 365 * 2 })

    if (inline) {
      setHasAccepted(false)
      window.location.reload()
    } else {
      dispatch(declineCookieConsent())
    }
  }
  const handleAccept = () => {
    if (cookieConsent === true) return

    // expires defines expiration of the cookie in days.
    cookies.set('epCookieConsent', '1', { expires: 365 * 2 })

    if (inline) setHasAccepted(true)
    window.location.reload()
  }

  const component = (
    <div
      className={cc(['lightbox cookie-consent', showInline && 'lightbox-centered cookie-consent-inline'])}
      style={isOnSmallScreen ? { marginBottom: 0 } : {}}
    >
      {/* Unsetting transform so the button is positioned correctly in the Beyond preview. */}
      <style>{`.body { transform: unset; }`}</style>
      <div className="cookie-consent-header">
        <h1 className="cookie-consent-title">{cookieNoticeSettings.get('title') || ''}</h1>
        {isOnSmallScreen && (
          <span
            className={cc(['cookie-consent-toggle', { open: !isBodyClosed, close: isBodyClosed }])}
            onClick={() => setIsBodyClosed(!isBodyClosed)}
            data-testid="consentMinimizer"
          />
        )}
      </div>
      <ScrollShadow>
        {(ref) => (
          <div
            ref={ref}
            className={cc(['modal-content cookie-consent-content', { close: isBodyClosed && isOnSmallScreen }])}
            dangerouslySetInnerHTML={{ __html: cookieNoticeSettings.get('gdprMessageHtml') || '' }}
          />
        )}
      </ScrollShadow>
      <div className="cookie-consent-buttons">
        <button
          className={cc(['cookie-consent-button-decline', inline && !hasAccepted && 'cookie-consent-button-active'])}
          type="button"
          onClick={handleDecline}
        >
          {t('components.cookieConsentComponent.declineButton.label')}
        </button>
        <button
          className={cc(['cookie-consent-button-accept', inline && hasAccepted && 'cookie-consent-button-active'])}
          type="button"
          onClick={handleAccept}
        >
          {t('components.cookieConsentComponent.acceptButton.label')}
        </button>
      </div>
    </div>
  )

  if (showInline) {
    return component
  } else if (showInPortal) {
    return (
      <Portal className="lightbox-backdrop" style={isOnSmallScreen ? { alignItems: 'flex-end' } : {}}>
        {component}
      </Portal>
    )
  } else {
    return null
  }
}
CookieConsent.propTypes = {
  t: func.isRequired,
  inline: bool,
  isEditor: bool.isRequired,
}

export default translate()(CookieConsent)
