import React, { useEffect, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import PropTypes from 'prop-types'

import { MenuItem, findActiveItem } from './OffCanvas'

export const Navigation = ({ items, className, direction }) => {
  const openClass = 'js-page-menu-open'
  const selectedItem = useRef(findActiveItem(items))

  const closeOffCanvas = () => document.body.classList.remove(openClass)

  const openOffCanvas = () => document.body.classList.add(openClass)

  const toggleOffCanvas = () => {
    if (document.body.classList.contains(openClass)) {
      closeOffCanvas()
    } else {
      openOffCanvas()
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: direction === 'left' ? toggleOffCanvas : null,
    onSwipedRight: direction === 'right' ? toggleOffCanvas : null,
  })

  useEffect(() => {
    closeOffCanvas()
    selectedItem.current = findActiveItem(items)
  }, [items])

  return (
    <div className={className}>
      <div className="toggle-menu">
        <button className="burger-icon" onClick={toggleOffCanvas}>
          <span className="burger-icon-stripes" />
        </button>
      </div>
      <div {...handlers}>
        <div className="main-menu-overlay" onClick={toggleOffCanvas} />
        <div className="main-menu-wrapper">
          <div className="main-menu-header" />
          <ul className="main-menu">
            {items.map((item) => (
              <MenuItem key={item.id} item={item} selectedItem={selectedItem.current} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
}

Navigation.defaultProps = {
  direction: 'left',
}

export default Navigation
