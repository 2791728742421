import PropTypes from 'prop-types'
import React from 'react'

export default function ErrorComponent({ error, t }) {
  return (
    <div className="template-error">
      <div>{error.statusCode}</div>
      {error.requestId && (
        <small>
          {t('views.storefrontView.errorPageSection.idExplanation')} <code>{error.requestId}</code>
        </small>
      )}
    </div>
  )
}

ErrorComponent.propTypes = { error: PropTypes.object.isRequired, t: PropTypes.func.isRequired }
