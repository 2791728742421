import { bool, func, number, string } from 'prop-types'
import { connect } from 'react-redux'
import { formField } from '@epages/react-components'
import { map, mapContains } from 'react-immutable-proptypes'
import Immutable from 'immutable'
import React from 'react'
import cc from 'classcat'
import noop from 'lodash/noop'

import { allowedMimeTypes, isValidMimeType } from '../../daliConfig'
import { setViewBusyState } from '../../../../../store/actions'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

function createPreview(file) {
  return new Promise(function (resolve) {
    const reader = new FileReader()

    reader.onload = (upload) => resolve(upload.target.result)
    reader.readAsDataURL(file)
  })
}

export function getErrorReasonMessage({ response }, t, baseKey) {
  const tBaseKey = baseKey || 'components.imageUploadComponent.imageField.errorMessages.reason'
  if (!response) return t(`${tBaseKey}.unknown`)

  switch (response.status) {
    case 413:
      return t(`${tBaseKey}.requestEntityTooLarge`)
    case 415:
      return t(`${tBaseKey}.unsupportedMediaType`)
    default:
      return t(`${tBaseKey}.unknown`)
  }
}

// Utility function used to prepare "imageData" (`{src,width,height}`) for
// usage with react-components `Form` via the parent component.
export function withImageData(data) {
  return data.set(
    'imageData',
    Immutable.Map({
      src: data.get('src'),
      width: data.get('width'),
      height: data.get('height'),
    }),
  )
}

// Utility function used to merge "imageData" (`{src,width,height}`) into the
// flat image object that is saved to the database via the parent component.
export function withMergedImageData(data) {
  return data.merge(data.get('imageData'))
}

class ImageFieldRaw extends React.Component {
  static propTypes = {
    name: string.isRequired,
    value: mapContains({
      src: string,
      width: number,
      height: number,
    }).isRequired,
    setViewBusyState: func.isRequired,
    onChange: func.isRequired,
    storeFile: func.isRequired,
    className: string,
    t: func.isRequired,
    imageUrl: func,
    previewUrl: func,
    onError: func,
    showDeleteButton: bool,
    renderCropButton: func,
    withImageInfo: bool,
    withMultipleImages: bool,
    recommendedImageDimensions: map.isRequired,
  }

  static defaultProps = {
    onError: noop,
    showDeleteButton: false,
  }

  state = {
    preview: null,
  }

  onUpload = (file) => {
    createPreview(file)
      .then((preview) => {
        this.props.setViewBusyState(true)
        this.setState({ uploading: true, preview })
      })
      .then(() => this.props.storeFile(file))
      .then(
        (response) => {
          this.props.onChange(
            Immutable.Map({
              src: response.absoluteDownloadUrl,
              width: response.width,
              height: response.height,
            }),
          )

          this.setState({ uploading: false, preview: undefined })
          this.props.setViewBusyState(false)
          return null
        },
        (err) => {
          const { onError, value, t } = this.props
          const reason = getErrorReasonMessage(err, t)
          const message = t(
            `components.imageUploadComponent.imageField.errorMessages.${
              value.get('src') ? 'messageWithOldImage' : 'messageWithoutOldImage'
            }`,
            { reason },
          )

          onError(message)
          this.props.setViewBusyState(false)
          this.setState({ uploading: false, preview: undefined })
        },
      )
      .catch((err) => console.warn(err)) // eslint-disable-line no-console
  }

  onDragOver = (event) => {
    const file = event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items[0]
    const isFirefox = event.dataTransfer && event.dataTransfer.items === undefined
    const isPlugin = window.dali.globalDragData

    // Files from outside of the browser are always allowed to be dropped and the browser will open them.
    // To prevent this behavior we prevent the default in all cases and do not allow unsupported files with
    // `dropEffect = 'none'`.
    // For firefox we need to set the dropEffect to copy in order to be able to drop at all.
    // Meaning we cannot check the file type on dragover in FF but we know if s/o's trying to drop one of our plugins.
    event.preventDefault()
    event.dataTransfer.dropEffect = (isFirefox && !isPlugin) || (file && !isValidMimeType(file)) ? 'copy' : 'none'
  }

  onDrop = (event) => {
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0]

    event.preventDefault()
    this.onUpload(file)
  }

  setFileInputRef = (node) => {
    this.fileInput = node
  }

  render() {
    const { onChange, value, showDeleteButton, renderCropButton, t } = this.props
    const { uploading } = this.state

    return (
      <div onDragOver={this.onDragOver} onDrop={this.onDrop}>
        {uploading && this.renderSpinner()}
        {!uploading && !value.get('src') && this.renderEmpty()}
        {!uploading && value.get('src') && (
          <div className="dali-plugin-image-preview">
            {this.renderImage()}
            <div className="dali-plugin-image-button-wrapper">
              {showDeleteButton && (
                <button
                  className="dali-plugin-image-button-delete"
                  title={t('components.imageUploadComponent.deleteImageButton.label')}
                  onClick={() => onChange(Immutable.Map())}
                  type="button"
                />
              )}
              {renderCropButton && renderCropButton()}
              <button
                onClick={() => this.fileInput.click()}
                className="dali-plugin-image-button-change"
                title={t('components.imageUploadComponent.changeImageButton.label')}
                type="button"
              />
            </div>
          </div>
        )}

        <input
          ref={this.setFileInputRef}
          type="file"
          aria-label={t('components.imageUploadComponent.imageField.label')}
          accept={allowedMimeTypes.join(',')}
          onChange={(event) => this.onUpload(event.target.files[0])}
          value=""
        />
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className="dali-plugin-image-busy-indicator">
        <span className="dali-plugin-image-spinner" />
        <img src={this.state.preview} draggable="false" />
      </div>
    )
  }

  renderEmpty() {
    const { t, recommendedImageDimensions, withImageInfo, withMultipleImages, className } = this.props
    const recommendedImageWidth = recommendedImageDimensions.get('contentImageWidth')

    return (
      <div className={cc(['dali-plugin-image-default', className])}>
        <span className="dali-plugin-image-placeholder" />
        <div className="dali-plugin-image-button-wrapper">
          <div className="dali-plugin-image-info">
            {withImageInfo
              ? t('components.imageUploadComponent.imageInfo', { recommendedImageWidth })
              : t(`components.imageUploadComponent.explanation${withMultipleImages ? '_plural' : ''}`)}
          </div>
          <button
            type="button"
            onClick={() => this.fileInput.click()}
            className="dali-button dali-plugin-image-button-upload"
          >
            <span>
              {t(`components.imageUploadComponent.addImageButton.label${withMultipleImages ? '_plural' : ''}`)}
            </span>
          </button>
        </div>
      </div>
    )
  }

  renderImage() {
    const { value, imageUrl, previewUrl } = this.props
    const imgSrc = imageUrl ? imageUrl(value.get('src')) : value.get('src')
    const previewSrc = previewUrl ? previewUrl(imgSrc) : imgSrc

    return <img src={previewSrc} draggable="false" />
  }
}

export default compose(
  withI18n('interface'),
  translate(),
  connect(
    (state) => ({
      recommendedImageDimensions: state.getIn(['themeStyleMeta', 'recommendedImageDimensions']),
    }),
    {
      setViewBusyState,
    },
  ),
  formField(),
)(ImageFieldRaw)
