import React from 'react'
import hoistStatics from 'hoist-non-react-statics'

import { i18nInstancesContext } from './I18nProvider'

const validI18nTypes = ['shop', 'interface']

export const i18nContext = React.createContext()

/**
 * Creates higher-order component that adds "i18n" context.
 *
 * @param {string} i18nType Specifies which i18next instance to use.
 * @returns {function} The higher-order component.
 */
export default function withI18n(i18nType) {
  if (!validI18nTypes.includes(i18nType)) {
    throw new Error('i18nType must be one of: ' + validI18nTypes.map((type) => `"${type}"`).join(', '))
  }

  return function (WrappedComponent) {
    class WithI18n extends React.Component {
      static displayName = WrappedComponent.displayName || 'Component'

      render() {
        return (
          <i18nInstancesContext.Consumer>
            {(i18nInstances) => (
              <i18nContext.Provider value={i18nInstances[`${i18nType}I18n`]}>
                <WrappedComponent {...this.props} />
              </i18nContext.Provider>
            )}
          </i18nInstancesContext.Consumer>
        )
      }
    }

    return hoistStatics(WithI18n, WrappedComponent)
  }
}
