export type ShopActionTypes =
  | SetMboBaseUrlAction
  | SetThemeVariablesCssAction
  | ResolvedApiAction<GetThemes>
  | ResolvedApiAction<GetThemeSettingsSchema>
  | ResolvedApiAction<GetThemeSettings>
  | ResolvedApiAction<GetDefaultThemeSettings>
  | ResolvedApiAction<SetThemeSettings>
  | PreviewThemeSettingsAction
  | PreviewThemeStyleSettingsAction
  | ResolvedApiAction<SetTheme>
  | ResolvedApiAction<GetThemeStyleMeta>
  | ResolvedApiAction<GetScriptTags>
  | ResolvedApiAction<GetResellerName>
  | ResolvedApiAction<GetTax>
  | PreviewFaviconAction
  | ResolvedApiAction<SetFavicon>
  | PreviewUserSettingsAction
  | ResolvedApiAction<SetUserSettings>
  | PreviewShopLogoAction
  | PreviewShopLogoAdjusmentsAction
  | ResolvedApiAction<SetShopLogo>
  | ResolvedApiAction<LoadShopEmail>
  | ResolvedApiAction<LoadProduct>
  | ResolvedApiAction<LoadOrder>

type SetMboBaseUrlAction = {
  type: typeof SET_MBO_BASE_URL
  mboBaseUrl: string
}
export const SET_MBO_BASE_URL = 'SET_MBO_BASE_URL'
export function setMboBaseUrl(mboBaseUrl: string): SetMboBaseUrlAction {
  return {
    type: SET_MBO_BASE_URL,
    mboBaseUrl,
  }
}

type SetThemeVariablesCssAction = {
  type: typeof SET_THEME_VARIABLES_CSS
  variablesCss: string
}
export const SET_THEME_VARIABLES_CSS = 'SET_THEME_VARIABLES_CSS'
export function setThemeVariablesCss(variablesCss: string): SetThemeVariablesCssAction {
  const startOfInlineSourceMap = variablesCss.indexOf('/*# sourceMappingURL=')
  return {
    type: SET_THEME_VARIABLES_CSS,
    variablesCss: startOfInlineSourceMap > -1 ? variablesCss.substr(0, startOfInlineSourceMap) : variablesCss,
  }
}

type GetThemes = {
  type: typeof GET_THEMES
  response: Core.Theme[]
}
export const GET_THEMES = 'GET_THEMES'
export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS'
export const GET_THEMES_FAILURE = 'GET_THEMES_FAILURE'
export function getThemes(): ApiAction<GetThemes> {
  return {
    type: GET_THEMES,
    idempotent: false,
    callApi: (api) => api.get('/api/v2/themes').then((res) => res.data),
  }
}

type GetThemeSettingsSchema = {
  type: typeof GET_THEME_SETTINGS_SCHEMA
  payload: {
    themeStyleId: string
  }
  response: Theme.SettingsSchemaFile[]
}
export const GET_THEME_SETTINGS_SCHEMA = 'GET_THEME_SETTINGS_SCHEMA'
export const GET_THEME_SETTINGS_SCHEMA_SUCCESS = 'GET_THEME_SETTINGS_SCHEMA_SUCCESS'
export const GET_THEME_SETTINGS_SCHEMA_FAILURE = 'GET_THEME_SETTINGS_SCHEMA_FAILURE'
export function getThemeSettingsSchema(themeStyleId: string): ApiAction<GetThemeSettingsSchema> {
  return {
    type: GET_THEME_SETTINGS_SCHEMA,
    idempotent: false,
    payload: { themeStyleId },
    callApi: (api, { themeStyleId }) =>
      api.get(`/api/v2/themeStyles/${themeStyleId}/settingsSchema`).then((res) => res.data),
  }
}

type GetThemeSettings = {
  type: typeof GET_THEME_SETTINGS
  payload: {
    themeStyleId: string
  }
  response: Theme.Settings
}
export const GET_THEME_SETTINGS = 'GET_THEME_SETTINGS'
export const GET_THEME_SETTINGS_SUCCESS = 'GET_THEME_SETTINGS_SUCCESS'
export const GET_THEME_SETTINGS_FAILURE = 'GET_THEME_SETTINGS_FAILURE'
export function getThemeSettings(themeStyleId: string): ApiAction<GetThemeSettings> {
  return {
    type: GET_THEME_SETTINGS,
    idempotent: false,
    payload: { themeStyleId },
    callApi: (api, { themeStyleId }) => api.get(`/api/v2/themeStyles/${themeStyleId}/settings`).then((res) => res.data),
  }
}

type GetDefaultThemeSettings = {
  type: typeof GET_DEFAULT_THEME_SETTINGS
  payload: {
    themeStyleId: string
  }
  response: Theme.Settings
}
export const GET_DEFAULT_THEME_SETTINGS = 'GET_DEFAULT_THEME_SETTINGS'
export const GET_DEFAULT_THEME_SETTINGS_SUCCESS = 'GET_DEFAULT_THEME_SETTINGS_SUCCESS'
export const GET_DEFAULT_THEME_SETTINGS_FAILURE = 'GET_DEFAULT_THEME_SETTINGS_FAILURE'
export function getDefaultThemeSettings(themeStyleId: string): ApiAction<GetDefaultThemeSettings> {
  return {
    type: GET_DEFAULT_THEME_SETTINGS,
    idempotent: false,
    payload: { themeStyleId },
    callApi: (api, { themeStyleId }) =>
      api.get(`/api/v2/themeStyles/${themeStyleId}/defaultSettings`).then((res) => res.data),
  }
}

type SetThemeSettings = {
  type: typeof SET_THEME_SETTINGS
  payload: {
    themeStyleId: string
    themeSettings: ImmutableMap
  }
  response: Theme.Settings
}
export const SET_THEME_SETTINGS = 'SET_THEME_SETTINGS'
export const SET_THEME_SETTINGS_SUCCESS = 'SET_THEME_SETTINGS_SUCCESS'
export const SET_THEME_SETTINGS_FAILURE = 'SET_THEME_SETTINGS_FAILURE'
export function setThemeSettings(themeStyleId: string, themeSettings: ImmutableMap): ApiAction<SetThemeSettings> {
  return {
    type: SET_THEME_SETTINGS,
    idempotent: true,
    payload: {
      themeStyleId,
      themeSettings,
    },
    callApi: (api, { themeStyleId, themeSettings }) =>
      api.patch(`/api/v2/themeStyles/${themeStyleId}/settings`, themeSettings).then((res) => res.data),
  }
}

type PreviewThemeSettingsAction = {
  type: typeof PREVIEW_THEME_SETTINGS
  response: Theme.Settings
}
export const PREVIEW_THEME_SETTINGS = 'PREVIEW_THEME_SETTINGS'
export function previewThemeSettings(themeSettings: ImmutableMap): PreviewThemeSettingsAction {
  return {
    type: PREVIEW_THEME_SETTINGS,
    response: { theme: themeSettings.get('theme'), themeStyle: themeSettings.get('themeStyle') },
  }
}

type PreviewThemeStyleSettingsAction = {
  type: typeof PREVIEW_THEME_STYLE_SETTINGS
  response: ImmutableMap
}
export const PREVIEW_THEME_STYLE_SETTINGS = 'PREVIEW_THEME_STYLE_SETTINGS'
export function previewThemeStyleSettings(themeStyleSettings: ImmutableMap): PreviewThemeStyleSettingsAction {
  return {
    type: PREVIEW_THEME_STYLE_SETTINGS,
    response: themeStyleSettings,
  }
}

type SetTheme = {
  type: typeof SET_THEME
  payload: {
    themeId: string
    themeStyleId: string
  }
  response: Theme.Settings
}
export const SET_THEME = 'SET_THEME'
export const SET_THEME_SUCCESS = 'SET_THEME_SUCCESS'
export const SET_THEME_FAILURE = 'SET_THEME_FAILURE'
export function setTheme(themeId: string, themeStyleId: string): ApiAction<SetTheme> {
  return {
    type: SET_THEME,
    idempotent: true,
    payload: { themeId, themeStyleId },
    callApi: (api, { themeId, themeStyleId }) =>
      api.put('/api/v2/themes/active', { themeId, themeStyleId }).then((res) => res.data),
  }
}

type GetThemeStyleMeta = {
  type: typeof GET_THEME_STYLE_META
  payload: {
    themeStyleId: string
  }
  response: Omit<Theme.ThemeFileStyle, 'default'>
}
export const GET_THEME_STYLE_META = 'GET_THEME_STYLE_META'
export const GET_THEME_STYLE_META_SUCCESS = 'GET_THEME_STYLE_META_SUCCESS'
export const GET_THEME_STYLE_META_FAILURE = 'GET_THEME_STYLE_META_FAILURE'
export function getThemeStyleMeta(themeStyleId: string): ApiAction<GetThemeStyleMeta> {
  return {
    type: GET_THEME_STYLE_META,
    idempotent: false,
    payload: {
      themeStyleId,
    },
    callApi: (api, { themeStyleId }) => api.get(`/api/v2/themeStyles/${themeStyleId}/meta`).then((res) => res.data),
  }
}

type GetScriptTags = {
  type: typeof GET_SCRIPT_TAGS
  response: Core.ScriptTag[]
}
export const GET_SCRIPT_TAGS = 'GET_SCRIPT_TAGS'
export const GET_SCRIPT_TAGS_SUCCESS = 'GET_SCRIPT_TAGS_SUCCESS'
export const GET_SCRIPT_TAGS_FAILURE = 'GET_SCRIPT_TAGS_FAILURE'
export function getScriptTags(): ApiAction<GetScriptTags> {
  return {
    type: GET_SCRIPT_TAGS,
    idempotent: true,
    callApi: (api) => api.get('/api/v2/scriptTags').then((res) => res.data),
  }
}

type GetResellerName = {
  type: typeof GET_RESELLER_NAME
  response: string
}
export const GET_RESELLER_NAME = 'GET_RESELLER_NAME'
export const GET_RESELLER_NAME_SUCCESS = 'GET_RESELLER_NAME_SUCCESS'
export const GET_RESELLER_NAME_FAILURE = 'GET_RESELLER_NAME_FAILURE'
export function getResellerName(): ApiAction<GetResellerName> {
  return {
    type: GET_RESELLER_NAME,
    idempotent: true,
    callApi: (api) => api.get('/api/v2/shop/resellerName').then((res) => res.data),
  }
}

type GetTax = {
  type: typeof GET_TAX
  response: Core.TaxInfo
}
export const GET_TAX = 'GET_TAX'
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS'
export const GET_TAX_FAILURE = 'GET_TAX_FAILURE'
export function getTax(): ApiAction<GetTax> {
  return {
    type: GET_TAX,
    idempotent: true,
    callApi: (api) => api.get('/api/v2/shop/tax').then((res) => res.data),
  }
}

type FaviconSettings = {
  faviconUrl: string
  faviconWidth: number
  faviconHeight: number
}
type PreviewFaviconAction = {
  type: typeof PREVIEW_FAVICON
  response: FaviconSettings
}
export const PREVIEW_FAVICON = 'PREVIEW_FAVICON'
export function previewFavicon(favicon: FaviconSettings): PreviewFaviconAction {
  return {
    type: PREVIEW_FAVICON,
    response: favicon,
  }
}

type SetFavicon = {
  type: typeof SET_FAVICON
  payload: { faviconSettings: ImmutableMap }
  response: Core.FullShop & { faviconWidth: number; faviconHeight: number }
}
export const SET_FAVICON = 'SET_FAVICON'
export const SET_FAVICON_SUCCESS = 'SET_FAVICON_SUCCESS'
export const SET_FAVICON_FAILURE = 'SET_FAVICON_FAILURE'
export function setFavicon(faviconSettings: ImmutableMap, options?: ActionOptions): ApiAction<SetFavicon> {
  return {
    type: SET_FAVICON,
    idempotent: true,
    payload: { faviconSettings },
    callApi: (api, { faviconSettings }, { locale }) =>
      api.patch('/api/v2/shop', faviconSettings, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type PreviewUserSettingsAction = {
  type: typeof PREVIEW_USER_SETTINGS
  response: {
    userSettings: ImmutableMap
  }
}
export const PREVIEW_USER_SETTINGS = 'PREVIEW_USER_SETTINGS'
export function previewUserSettings(userSettings: ImmutableMap): PreviewUserSettingsAction {
  return {
    type: PREVIEW_USER_SETTINGS,
    response: { userSettings },
  }
}

type SetUserSettings = {
  type: typeof SET_USER_SETTINGS
  payload: {
    userSettings: Core.FullShop['userSettings']
  }
  response: Core.FullShop
}
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_USER_SETTINGS_SUCCESS = 'SET_USER_SETTINGS_SUCCESS'
export const SET_USER_SETTINGS_FAILURE = 'SET_USER_SETTINGS_FAILURE'
export function setUserSettings(
  userSettings: Core.FullShop['userSettings'],
  options?: ActionOptions,
): ApiAction<SetUserSettings> {
  return {
    type: SET_USER_SETTINGS,
    idempotent: true,
    payload: {
      userSettings,
    },
    callApi: (api, payload, { locale }) =>
      api.patch('/api/v2/shop', payload, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type PreviewShopLogoAction = {
  type: typeof PREVIEW_SHOP_LOGO
  response: Pick<Core.FullShop, 'logoUrl' | 'logoWidth' | 'logoHeight' | 'shouldHideLogo'>
}
export const PREVIEW_SHOP_LOGO = 'PREVIEW_SHOP_LOGO'
export function previewShopLogo(shopLogo: PreviewShopLogoAction['response']): PreviewShopLogoAction {
  return {
    type: PREVIEW_SHOP_LOGO,
    response: shopLogo,
  }
}

type PreviewShopLogoAdjusmentsAction = {
  type: typeof PREVIEW_THEME_LOGO_ADJUSTMENTS
  response: {
    logoAdjustments: ImmutableMap
  }
}
export const PREVIEW_THEME_LOGO_ADJUSTMENTS = 'PREVIEW_THEME_LOGO_ADJUSTMENTS'
export function previewShopLogoAdjusments(logoAdjustments: ImmutableMap): PreviewShopLogoAdjusmentsAction {
  return {
    type: PREVIEW_THEME_LOGO_ADJUSTMENTS,
    response: { logoAdjustments },
  }
}

type SetShopLogo = {
  type: typeof SET_SHOP_LOGO
  payload: Pick<Core.FullShop, 'logoUrl' | 'logoWidth' | 'logoHeight' | 'shouldHideLogo'>
  response: Core.FullShop
}
export const SET_SHOP_LOGO = 'SET_SHOP_LOGO'
export const SET_SHOP_LOGO_SUCCESS = 'SET_SHOP_LOGO_SUCCESS'
export const SET_SHOP_LOGO_FAILURE = 'SET_SHOP_LOGO_FAILURE'
export function setShopLogo(shopLogo: SetShopLogo['payload'], options?: ActionOptions): ApiAction<SetShopLogo> {
  return {
    type: SET_SHOP_LOGO,
    idempotent: true,
    payload: shopLogo,
    callApi: (api, payload, { locale }) =>
      api.patch('/api/v2/shop', payload, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type LoadShopEmail = {
  type: typeof LOAD_SHOP_EMAIL
  response: string
}
export const LOAD_SHOP_EMAIL = 'LOAD_SHOP_EMAIL'
export const LOAD_SHOP_EMAIL_SUCCESS = 'LOAD_SHOP_EMAIL_SUCCESS'
export const LOAD_SHOP_EMAIL_FAILURE = 'LOAD_SHOP_EMAIL_FAILURE'
export function loadShopEmail(options?: ActionOptions): ApiAction<LoadShopEmail> {
  return {
    type: LOAD_SHOP_EMAIL,
    idempotent: true,
    callApi: (api, _payload, { locale }) =>
      api.get('/api/v2/shop/email', { params: { locale } }).then((res) => res.data),
    options,
  }
}

type LoadProduct = {
  type: typeof LOAD_PRODUCT
  payload: {
    guidAndSlug: {
      guid: string
      slug: string
    }
  }
  response: Core.Product
}
export const LOAD_PRODUCT = 'LOAD_PRODUCT'
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS'
export const LOAD_PRODUCT_FAILURE = 'LOAD_PRODUCT_FAILURE'
export function loadProduct(
  guidAndSlug: { guid: string; slug: string },
  options?: ActionOptions,
): ApiAction<LoadProduct> {
  return {
    type: LOAD_PRODUCT,
    idempotent: true,
    payload: {
      guidAndSlug,
    },
    callApi: (api, { guidAndSlug }, { locale }) =>
      api
        .get(`/api/v2/products/${guidAndSlug.guid}`, {
          params: {
            locale,
            slug: guidAndSlug.slug,
          },
        })
        .then((res) => res.data),
    options,
  }
}

type LoadOrder = {
  type: typeof LOAD_ORDER
  payload: {
    orderId: string
    query?: { viewtkn: string }
  }
  response: Core.Order
}
export const LOAD_ORDER = 'LOAD_ORDER'
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS'
export const LOAD_ORDER_FAILURE = 'LOAD_ORDER_FAILURE'
export function loadOrder(orderId: string, query?: { viewtkn: string }, options?: ActionOptions): ApiAction<LoadOrder> {
  return {
    type: LOAD_ORDER,
    idempotent: true,
    payload: {
      orderId,
      query,
    },
    callApi: (api, { orderId, query }, { locale }) =>
      api
        .get(`/api/v2/orders/${orderId}`, {
          params: {
            locale,
            viewtoken: (query && query.viewtkn) || null,
          },
        })
        .then((res) => res.data),
    options,
  }
}
