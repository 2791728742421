import PropTypes from 'prop-types'
import React from 'react'

export default function SocialMedia({ socialNetworks }) {
  if (!socialNetworks.show) return null
  return (
    <ul className="social-media">
      {socialNetworks.pinterestUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.pinterestUrl || '/'}
            target={socialNetworks.pinterestUrl && '_blank'}
            rel="noopener noreferrer"
          >
            <span className="social-media-pinterest" />
          </a>
        </li>
      )}
      {socialNetworks.instagramUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.instagramUrl || '/'}
            target={socialNetworks.instagramUrl && '_blank'}
            rel="noopener noreferrer"
          >
            <span className="social-media-instagram" />
          </a>
        </li>
      )}
      {socialNetworks.twitterUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.twitterUrl || '/'}
            target={socialNetworks.twitterUrl && '_blank'}
            rel="noopener noreferrer"
          >
            <span className="social-media-twitter" />
          </a>
        </li>
      )}
      {socialNetworks.facebookUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.facebookUrl || '/'}
            target={socialNetworks.facebookUrl && '_blank'}
            rel="noopener noreferrer"
          >
            <span className="social-media-facebook" />
          </a>
        </li>
      )}
      {socialNetworks.youtubeUrlSelected && (
        <li className="social-media-icon">
          <a
            href={socialNetworks.youtubeUrl || '/'}
            target={socialNetworks.youtubeUrl && '_blank'}
            rel="noopener noreferrer"
          >
            <span className="social-media-youtube" />
          </a>
        </li>
      )}
    </ul>
  )
}

SocialMedia.propTypes = {
  socialNetworks: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    pinterestUrlSelected: PropTypes.bool.isRequired,
    instagramUrlSelected: PropTypes.bool.isRequired,
    twitterUrlSelected: PropTypes.bool.isRequired,
    facebookUrlSelected: PropTypes.bool.isRequired,
    youtubeUrlSelected: PropTypes.bool.isRequired,
    pinterestUrl: PropTypes.string,
    instagramUrl: PropTypes.string,
    twitterUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
  }).isRequired,
}
