import { omit } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import { PageOrCategoryContext } from '../../../../views/Category'
import compose from '../../../../utils/compose'
import translate from '../../../../utils/translate'
import withI18n from '../../../../components/withI18n'

class DropZone extends React.Component {
  static propTypes = {
    orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,

    // injected by DropTarget component
    isDragOver: PropTypes.bool.isRequired,
    isDragActive: PropTypes.bool.isRequired,
    isDropRedundant: PropTypes.bool.isRequired,
    isOnEmptyPage: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    t: PropTypes.func.isRequired,
  }

  setRef = (node) => {
    this.containerNode = node
  }

  render() {
    return (
      <PageOrCategoryContext.Consumer>
        {(pageOrCategory) => {
          const pageType = pageOrCategory.get('type')

          const classNames = cc([
            'dali-grid-dropzone',
            'dali-grid-dropzone-' + this.props.orientation,
            {
              'dali-grid-dropzone-empty': this.props.isOnEmptyPage,
              'dali-grid-dropzone-drop-redundant': this.props.isDropRedundant,
              'dali-grid-dropzone-dragactive': this.props.isDragActive,
              'dali-grid-dropzone-dragover': this.props.isDragOver,
            },
          ])
          const { t } = this.props
          const other = omit(this.props, [
            'isDragOver',
            'isDragActive',
            'isDropRedundant',
            'isOnEmptyPage',
            't',
            'tReady',
            'i18n',
            'i18nLoadedAt',
          ])
          return (
            <div ref={this.setRef} {...other} className={classNames}>
              {this.props.isOnEmptyPage && (
                <section className={`dali-grid-dropzone-empty-container-${pageType}`}>
                  <div className="dali-grid-dropzone-empty-image" />

                  <div className="dali-grid-dropzone-empty-info">
                    <h1>{t('components.emptySpaceComponent.title')}</h1>
                    <p>{t('components.emptySpaceComponent.explanation')}</p>
                  </div>
                </section>
              )}
            </div>
          )
        }}
      </PageOrCategoryContext.Consumer>
    )
  }
}

export default compose(withI18n('interface'), translate())(DropZone)
