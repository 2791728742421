import Immutable from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import { PageOrCategoryContext } from '../../views/Category'
import { updateCategoryContent, updateLegalPageContent, updatePageContent } from '../../store/actions'
import Element from './Workspace/Element'
import daliConfig from './Workspace/daliConfig'
import withReduxContext from '../../utils/withReduxContext'

const PluginRaw = ({ _id, type, store }) => {
  const pageOrCategory = React.useContext(PageOrCategoryContext)
  const shopSlug = store.getState().getIn(['shop', 'slug'])
  const editorMode = store.getState().getIn(['view', 'editorMode'])
  const element =
    pageOrCategory
      .get('content')
      .get('elements')
      .filter((e) => e.get('_id') === _id)
      .first() || Immutable.fromJS({ _id: _id, type: type, data: {} })

  return (
    <Element
      config={daliConfig(editorMode, store.api, shopSlug)}
      editorView={editorMode}
      element={element}
      onElementChange={(newElement) => {
        const oldContent = pageOrCategory.get('content')
        const elementIndex = oldContent.get('elements').findIndex((e) => e.get('_id') === _id)
        const newContent =
          elementIndex >= 0
            ? oldContent.setIn(['elements', elementIndex], newElement)
            : oldContent.set('elements', oldContent.get('elements').push(newElement))

        const updateInstructions = pageOrCategory.get('categoryId')
          ? [pageOrCategory.get('categoryId'), newContent]
          : [pageOrCategory.get('slug'), newContent]

        const updateAction = (function () {
          switch (pageOrCategory.get('type')) {
            case 'blank':
              return updatePageContent
            case 'legal':
              return updateLegalPageContent
            case 'category':
              return updateCategoryContent
            default:
              return (_) => _
          }
        })()

        store.dispatch(updateAction.apply(null, updateInstructions))
      }}
    />
  )
}
PluginRaw.propTypes = {
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
}

export default withReduxContext(PluginRaw)
