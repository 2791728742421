import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import cc from 'classcat'

import ProductListItem from '../../../ProductListItem'

const classNames = {
  S: 'grid-col-s',
  M: 'grid-col-m',
  L: 'grid-col-l',
}
const nowClassNames = 'column-grid-2 column-grid-3 column-grid-4'

type Props = {
  products: Core.Product[]
  imageSize?: 'S' | 'M' | 'L'
  trackProductClick: (product: Core.Product, productIndex: number) => void
}
export default function ProductPage({ products, imageSize = 'M', trackProductClick }: Props): ReactElement {
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))

  return (
    <div className={cc(['product-list', isBeyond ? classNames[imageSize] : nowClassNames])}>
      {products.map((product, index) => (
        <ProductListItem
          product={product}
          productIndex={index}
          key={product.productId}
          trackProductClick={trackProductClick}
        />
      ))}
    </div>
  )
}
