import PropTypes from 'prop-types'
import React from 'react'

import Plugin from '../../templateComponents/Plugin'

export default function Legal({ page }) {
  return (
    <div className="template-legal">
      <h1>{page.title}</h1>
      <Plugin type="epages.text" _id="legal-content" />
    </div>
  )
}

Legal.propTypes = { page: PropTypes.object.isRequired }
