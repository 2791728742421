import PropTypes from 'prop-types'
import React from 'react'

import Plugin from '../../templateComponents/Plugin'

export default function Category({ category }) {
  return (
    <div className="template-category">
      <h1>{category.title}</h1>
      <Plugin type="epages.text" _id="category" />
    </div>
  )
}

Category.propTypes = { category: PropTypes.object.isRequired }
