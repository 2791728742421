import React from 'react'

export default function createMultiLanguageLinkTags(shop, pagePath) {
  const shopLanguages = shop
    .get('locales')
    .filter((locale) => locale.get('isVisible'))
    .map((locale) => locale.get('identifier').substr(0, 2))

  if (shopLanguages.count() <= 1) return []

  const baseUrl = shop.getIn(['locationBase', 'origin'])
  const basePageUrl = pagePath ? `${baseUrl}/${pagePath}` : baseUrl
  const defaultLang = shop.get('defaultLocale').substr(0, 2)

  return shopLanguages
    .filter((lang) => lang !== defaultLang)
    .map((lang) => (
      <link
        key={lang}
        rel="alternate"
        hrefLang={lang}
        href={pagePath ? `${baseUrl}/${lang}/${pagePath}` : `${baseUrl}/${lang}`}
      />
    ))
    .concat([
      <link key={defaultLang} rel="alternate" hrefLang={defaultLang} href={basePageUrl} />,
      <link key={'x-default'} rel="alternate" hrefLang={'x-default'} href={basePageUrl} />,
    ])
    .toJS()
}
