import React, { useEffect, useState } from 'react'
import { func, number, string } from 'prop-types'
import cc from 'classcat'

import useScrollValues from '../utils/hooks/useScrollValues'

// Use this component together with CSS to extend an overflowing, scrollable element with a
// scroll shadow effect – so that scrollable content is indicated even when the operating
// system doesn't display scroll bars, or only shows them when scrolling (macOS default).
//
// Example usage:
//
// <ScrollShadow>
//   {ref => <div ref={ref}>Scrollable content</div>}
// </ScrollShadow>
function ScrollShadow({ children, className, size = 14, tagName: TagName = 'div', style = {}, ...other }) {
  const [setRef, scroll] = useScrollValues()
  const [sizes, setSizes] = useState({ top: 0, bottom: 0, left: 0, right: 0 })

  // Reduce the scroll shadow sizes when needed:
  // - Gradually reduce the size when scrolling towards the start or end of the element
  // - Set size to `0` to hide the shadow when the element does not overflow
  useEffect(() => {
    const top = scroll.top > size ? size : scroll.top
    const bottom = scroll.bottom > size ? size : scroll.bottom
    const left = scroll.left > size ? size : scroll.left
    const right = scroll.right > size ? size : scroll.right

    if (top !== sizes.top || bottom !== sizes.bottom || left !== sizes.left || right !== sizes.right) {
      setSizes({ top, bottom, left, right })
    }
  }, [size, scroll.top, scroll.bottom, scroll.left, scroll.right, sizes.top, sizes.bottom, sizes.left, sizes.right])

  if (sizes.top) style['--scroll-shadow-size-top'] = `${sizes.top}px`
  if (sizes.bottom) style['--scroll-shadow-size-bottom'] = `${sizes.bottom}px`
  if (sizes.left) style['--scroll-shadow-size-left'] = `${sizes.left}px`
  if (sizes.right) style['--scroll-shadow-size-right'] = `${sizes.right}px`

  return (
    <TagName className={cc([className, 'ep-scroll-shadow'])} style={style} {...other}>
      {children(setRef)}
      {sizes.top || sizes.bottom ? <div className="ep-scroll-shadow-vertical" /> : null}
      {sizes.left || sizes.right ? <div className="ep-scroll-shadow-horizontal" /> : null}
    </TagName>
  )
}

ScrollShadow.propTypes = {
  children: func.isRequired,
  className: string,
  size: number,
  style: string,
  tagName: string,
}

export default ScrollShadow
