import { node } from 'prop-types'
import React from 'react'

export default function ButtonRow({ children }) {
  return <div className="ep-form-button-row">{children}</div>
}

ButtonRow.propTypes = {
  children: node,
}
