import { List } from 'immutable'

export type ViewActionTypes =
  | SetViewBusyStateAction
  | UpdateSuggestedSearchAction
  | UpdateSuggestedSearchSelectionAction
  | ShowNotificationAction
  | HideNotificationAction
  | UpdateViewAction
  | DeclineCookieConsentAction
  | SetViewErrorAction
  | ClearViewErrorAction
  | SetMboMenuAction
  | SetMboMenuModeAction
  | PreviewThemeAction
  | ActivateEditorSidebarModuleAction
  | SetBuyboxBusyStateAction
  | SetBuyboxWishlistBusyStateAction
  | SetBuyboxProductQuantityAction
  | SetBuyboxNotificationAction
  | SetInterfaceLanguageAction
  | ResolvedApiAction<SubmitContactForm>

let nextNotificationId = 0

type SetViewBusyStateAction = {
  type: typeof SET_VIEW_BUSY_STATE
  busyState: boolean
}
export const SET_VIEW_BUSY_STATE = 'SET_VIEW_BUSY_STATE'
export function setViewBusyState(busyState: boolean): SetViewBusyStateAction {
  return {
    type: SET_VIEW_BUSY_STATE,
    busyState,
  }
}

type UpdateSuggestedSearchAction = {
  type: typeof UPDATE_SUGGESTED_SEARCH
  namespace: string
  searchTerm: string
  results: List<ImmutableMap> | null
}
export const UPDATE_SUGGESTED_SEARCH = 'UPDATE_SUGGESTED_SEARCH'
export function updateSuggestedSearch(
  namespace: string,
  searchTerm: string,
  results: List<ImmutableMap>,
): UpdateSuggestedSearchAction {
  return {
    type: UPDATE_SUGGESTED_SEARCH,
    namespace,
    searchTerm,
    results,
  }
}

type UpdateSuggestedSearchSelectionAction = {
  type: typeof UPDATE_SUGGESTED_SEARCH_SELECTION
  namespace: string
  selected: number
}
export const UPDATE_SUGGESTED_SEARCH_SELECTION = 'UPDATE_SUGGESTED_SEARCH_SELECTION'
export function updateSuggestedSearchSelection(
  namespace: string,
  selected: number,
): UpdateSuggestedSearchSelectionAction {
  return {
    type: UPDATE_SUGGESTED_SEARCH_SELECTION,
    namespace,
    selected,
  }
}

type ShowNotificationAction = {
  type: typeof SHOW_NOTIFICATION
  id: number
  level: string
  message: string
}
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export function showNotification(level: string, message: string): ShowNotificationAction {
  return {
    type: SHOW_NOTIFICATION,
    id: nextNotificationId++, // eslint-disable-line no-plusplus
    level,
    message,
  }
}

type HideNotificationAction = {
  type: typeof HIDE_NOTIFICATION
  id: string | number
}
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export function hideNotification(notificationId: string | number): HideNotificationAction {
  return {
    type: HIDE_NOTIFICATION,
    id: notificationId,
  }
}

type UpdateViewAction = {
  type: typeof UPDATE_VIEW
}
export const UPDATE_VIEW = 'UPDATE_VIEW'
export function updateView(): UpdateViewAction {
  return {
    type: UPDATE_VIEW,
  }
}

type DeclineCookieConsentAction = {
  type: typeof DECLINE_COOKIE_CONSENT
}
export const DECLINE_COOKIE_CONSENT = 'DECLINE_COOKIE_CONSENT'
export function declineCookieConsent(): DeclineCookieConsentAction {
  return {
    type: DECLINE_COOKIE_CONSENT,
  }
}

type SetViewErrorAction = {
  type: typeof SET_VIEW_ERROR
  message: string
  statusCode: number
  requestId: string | null
}
export const SET_VIEW_ERROR = 'SET_VIEW_ERROR'
export function setViewError(message: string, statusCode = 500, requestId = null): SetViewErrorAction {
  return {
    type: SET_VIEW_ERROR,
    message,
    statusCode,
    requestId,
  }
}

type ClearViewErrorAction = {
  type: typeof CLEAR_VIEW_ERROR
}
export const CLEAR_VIEW_ERROR = 'CLEAR_VIEW_ERROR'
export function clearViewError(): ClearViewErrorAction {
  return {
    type: CLEAR_VIEW_ERROR,
  }
}

type SetMboMenuAction = {
  type: typeof SET_MBO_MENU
  mboMenu: any[]
}
export const SET_MBO_MENU = 'SET_MBO_MENU'
export function setMboMenu(mboMenu: any[]): SetMboMenuAction {
  return {
    type: SET_MBO_MENU,
    mboMenu,
  }
}

type SetMboMenuModeAction = {
  type: typeof SET_MBO_MENU_MODE
  mboMenuMode: boolean
}
export const SET_MBO_MENU_MODE = 'SET_MBO_MENU_MODE'
export function setMboMenuMode(mboMenuMode: boolean): SetMboMenuModeAction {
  return {
    type: SET_MBO_MENU_MODE,
    mboMenuMode,
  }
}

type PreviewThemeAction = {
  type: typeof PREVIEW_THEME
  theme: Theme.ThemeFile
  themeStyle: Theme.ThemeFileStyle
}
export const PREVIEW_THEME = 'PREVIEW_THEME'
export function previewTheme(theme: Theme.ThemeFile, themeStyle: Theme.ThemeFileStyle): PreviewThemeAction {
  return {
    type: PREVIEW_THEME,
    theme,
    themeStyle,
  }
}

type ActivateEditorSidebarModuleAction = {
  type: typeof ACTIVATE_EDITOR_SIDEBAR_MODULE
  moduleName: string
}
export const ACTIVATE_EDITOR_SIDEBAR_MODULE = 'ACTIVATE_EDITOR_SIDEBAR_MODULE'
export function activateEditorSidebarModule(moduleName: string): ActivateEditorSidebarModuleAction {
  return {
    type: ACTIVATE_EDITOR_SIDEBAR_MODULE,
    moduleName,
  }
}

type SetBuyboxBusyStateAction = {
  type: typeof SET_BUYBOX_BUSY_STATE
  busyState: boolean
}
export const SET_BUYBOX_BUSY_STATE = 'SET_BUYBOX_BUSY_STATE'
export function setBuyboxBusyState(busyState: boolean): SetBuyboxBusyStateAction {
  return {
    type: SET_BUYBOX_BUSY_STATE,
    busyState,
  }
}

type SetBuyboxWishlistBusyStateAction = {
  type: typeof SET_BUYBOX_WISHLIST_BUSY_STATE
  busyState: boolean
}
export const SET_BUYBOX_WISHLIST_BUSY_STATE = 'SET_BUYBOX_WISHLIST_BUSY_STATE'
export function setBuyboxWishlistBusyState(busyState: boolean): SetBuyboxWishlistBusyStateAction {
  return {
    type: SET_BUYBOX_WISHLIST_BUSY_STATE,
    busyState,
  }
}

type SetBuyboxProductQuantityAction = {
  type: typeof SET_BUYBOX_PRODUCT_QUANTITY
  quantity: number
}
export const SET_BUYBOX_PRODUCT_QUANTITY = 'SET_BUYBOX_PRODUCT_QUANTITY'
export function setBuyboxProductQuantity(quantity: number): SetBuyboxProductQuantityAction {
  return {
    type: SET_BUYBOX_PRODUCT_QUANTITY,
    quantity,
  }
}

type SetBuyboxNotificationAction = {
  type: typeof SET_BUYBOX_NOTIFICATION
  notification: {
    id: string
    message: any
    type: string
  }
}
export const SET_BUYBOX_NOTIFICATION = 'SET_BUYBOX_NOTIFICATION'
export function setBuyboxNotification(
  notification: SetBuyboxNotificationAction['notification'],
): SetBuyboxNotificationAction {
  return {
    type: SET_BUYBOX_NOTIFICATION,
    notification,
  }
}

type SetInterfaceLanguageAction = {
  type: typeof SET_INTERFACE_LANGUAGE
  language: string
}
export const SET_INTERFACE_LANGUAGE = 'SET_INTERFACE_LANGUAGE'
export function setInterfaceLanguage(language: string): SetInterfaceLanguageAction {
  return {
    type: SET_INTERFACE_LANGUAGE,
    language,
  }
}

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
export const SWITCH_LANGUAGE_FAILURE = 'SWITCH_LANGUAGE_FAILURE'
export function switchLanguage(locale: string): GlobalAction {
  return async (dispatch, getState, api) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      locale,
    })

    const currentStoreState = getState()
    const pathname = currentStoreState.getIn(['location', 'pathname'])
    const categories = currentStoreState.get('categories')
    const products = currentStoreState.get('products')

    const [, type, identifier] = pathname.match(/\/(\w)\/(.*)/) || []

    // get the new language's slug for the page we're currently at
    const gettingSlug = (async function () {
      switch (type) {
        case 'c': {
          const categoryId = categories.find((category) => category.get('slug') === identifier).get('categoryId')

          try {
            const { data } = await api.get(`/api/v2/categories/${categoryId}`, { params: { locale } })
            return `c/${data.slug}`
          } catch {
            // E.g. the category doesn't yet exist in our database because the navigation endpoint
            // has not been called for all locales since it was created.
            return `c/${categoryId}`
          }
        }
        case 'p': {
          const productId = products.getIn([identifier, 'productId'])

          const { data } = await api.get(`/api/v2/products/${productId}`, { params: { locale } })
          return `p/${data.slug}`
        }
        case 'l':
          return `l/${identifier}`
        case 'o':
          return `o/${identifier}`
        default: {
          if (pathname.endsWith('/cart')) {
            return 'cart'
          } else if (pathname.endsWith('/search')) {
            return 'search'
          } else {
            return ''
          }
        }
      }
    })()

    try {
      const slug = await gettingSlug
      const isEditor = Boolean(currentStoreState.getIn(['view', 'editorMode']))

      const shopDefaultLocale = currentStoreState.getIn(['shop', 'defaultLocale'])
      const scope = isEditor ? '/editor' : ''
      const languagePathPrefix = locale === shopDefaultLocale ? '/' : `/${locale.substr(0, 2)}/`
      const search = new URLSearchParams(currentStoreState.getIn(['location', 'search']))

      // The editor token is updated approx. every 3 minutes via window.postMessage() (see app.js),
      // but the token isn't updated in the page URL, but only in the api.defaults.headers.Authorization.
      if (isEditor) {
        const tokenMatch = /Bearer (.*)/.exec(api.defaults.headers.Authorization)
        const newToken = tokenMatch && tokenMatch[1]
        if (newToken) search.set('token', newToken)
      }

      window.location.href = scope + languagePathPrefix + slug + (search.toString() ? `?${search.toString()}` : '')
    } catch (error) {
      return dispatch({ type: SWITCH_LANGUAGE_FAILURE, locale, error })
    }
  }
}

type SubmitContactForm = {
  type: typeof SUBMIT_CONTACT_FORM
  payload: {
    name: string
    email: string
    message: string
  }
}
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM'
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS'
export const SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE'
export function submitContactForm(
  payload: SubmitContactForm['payload'],
  csrfToken: string,
  options?: ActionOptions,
): ApiAction<SubmitContactForm> {
  return {
    type: SUBMIT_CONTACT_FORM,
    idempotent: false,
    payload,
    callApi: (api, payload) => {
      const config = {
        headers: {
          'x-csrf-token': csrfToken,
        },
      }

      return api.post('/api/v2/contactform', payload, config)
    },
    options,
  }
}
