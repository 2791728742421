import PropTypes from 'prop-types'
import React from 'react'

import Link from '../../templateComponents/Link'

const HiddenBreadcrumb = ({ breadcrumb }) => (
  <span itemScope itemType="http://schema.org/BreadcrumbList">
    {breadcrumb.map((page, index) => (
      <span itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" key={page.href}>
        <link href={page.href} itemProp="item" />
        <meta itemProp="name" content={page.title} />
        <meta itemProp="position" content={index + 1} />
      </span>
    ))}
  </span>
)

const VisibleBreadcrumb = ({ breadcrumb }) => (
  <ul itemScope itemType="http://schema.org/BreadcrumbList" className="breadcrumb">
    {breadcrumb.map((page, index) => (
      <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" key={page.href}>
        <Link to={page.href} itemProp="item">
          <span itemProp="name">{page.title}</span>
        </Link>
        <meta itemProp="position" content={index + 1} />
      </li>
    ))}
  </ul>
)

const Breadcrumb = ({ breadcrumb, isHidden }) => {
  if (breadcrumb.length <= 1) return null

  return isHidden ? <HiddenBreadcrumb breadcrumb={breadcrumb} /> : <VisibleBreadcrumb breadcrumb={breadcrumb} />
}

HiddenBreadcrumb.propTypes = VisibleBreadcrumb.propTypes = Breadcrumb.propTypes

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isHidden: PropTypes.bool.isRequired,
}

Breadcrumb.defaultProps = {
  breadcrumb: [],
  isHidden: false,
}

export default Breadcrumb
