import { pick } from 'lodash'
import cookies from 'js-cookie'

import { createFunctionWithTimeout } from '../../utils/timer'
import { orderPath } from '../urlGenerators'
import i18n from '../i18next'

let shouldGABeUsed = true
// consider language path for regex (/\w{1,2}/)
const orderRegexp = new RegExp(`(^|^/\\w{1,2})/${orderPath}/`)
const homeRegexp = /(^|^\/\w{1,2})\/?$/
const orderPagePath = '/checkout/confirmation'
const homePagePath = '/home'

export function setGAUsedState(useGA) {
  shouldGABeUsed = useGA
}

export function getLocationStringForGA(path) {
  let regexMatch
  if ((regexMatch = orderRegexp.exec(path)) !== null) {
    // add language to path
    return regexMatch[1] + orderPagePath
  } else if ((regexMatch = homeRegexp.exec(path)) !== null) {
    // add language to path
    return regexMatch[1] + homePagePath
  }

  return path
}

export function getDisableStr(trackingId) {
  return 'ga-disable-' + trackingId
}

export function getSnippet(trackingId) {
  return `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
          ga('create','${encodeURIComponent(trackingId)}','auto');
          ga('set','anonymizeIp',true);`
}

function optOut(trackingId) {
  const disableStr = getDisableStr(trackingId)
  cookies.set(disableStr, true, { expires: 365 * 100 })
  window[disableStr] = true
}

export function enableOptOut(trackingId) {
  if (!trackingId) return

  // Handle GA opt-out inline JavaScript (blocked by our CSP)
  document.addEventListener('click', function (event) {
    if (event.target.href && /^javascript:\s*gaOptout\(\);?$/i.test(event.target.href)) {
      event.preventDefault()
      optOut(trackingId)
      window.alert(i18n.t('base:views.storefrontView.googleAnalyticsOptoutSuccessMessage')) // eslint-disable-line no-alert
    }
  })
}

// Functionality needed for the Google Analytics enhanced ecommerce implementation.

export function initializeGoogleAnalyticsEc() {
  shouldGABeUsed && window.ga('require', 'ec')
  if (window.eComEventTarget) {
    window.eComEventTarget.addEventListener('category', ({ detail: { category, products } }) => {
      addCategoryImpression(products, category)
    })
    window.eComEventTarget.addEventListener('search', ({ detail: { products, query } }) => {
      addSearchImpression(products, query)
    })
    window.eComEventTarget.addEventListener('product:click', ({ detail: { type, details, product, productIndex } }) => {
      trackProductClick([type, details])(product, productIndex)
    })
  }
}

export function setLocation(location) {
  if (shouldGABeUsed) {
    const regexMatch = orderRegexp.exec(location.pathname)
    const gaLocation =
      // hide viewtkn for order path
      regexMatch !== null
        ? // add language to path
          location.origin + regexMatch[1] + orderPagePath
        : location.href

    window.ga('set', 'location', gaLocation)
  }
}

export function setCurrencyCode(currencyCode) {
  shouldGABeUsed && window.ga('set', 'currencyCode', currencyCode)
}

export function addProduct(product, attributes) {
  sendProductData(getProductData(product, attributes))
}

export function addLineItemProduct(product) {
  sendProductData({
    ...getBaseProductProperties(product.toJS()),
    variant: product.get('variationString') || null,
    // product line item in the order uses "quantity.amount", whereas in the cart it is just "quantity"
    quantity: product.getIn(['quantity', 'amount']) || product.get('quantity'),
  })
}

export function addPayment(name, hitCallback) {
  if (shouldGABeUsed) {
    window.ga('ec:setAction', 'checkout', { step: 3, option: name })
    window.ga('send', 'event', {
      eventCategory: 'Outbound Link',
      eventAction: 'click',
      eventLabel: name,
      hitCallback: createFunctionWithTimeout(hitCallback),
    })
  } else {
    hitCallback()
  }
}

export function sendButtonClick(category, label) {
  shouldGABeUsed && window.ga('send', 'event', category, 'click', label)
}

export function addLineItemProductList(products) {
  products.forEach((product) => addLineItemProduct(product))
}

export function addProductList(products, attributes) {
  products.forEach((product) => addProduct(product, attributes))
}

export function addPurchase(order, shopTitle) {
  const couponCampaign = order.get('couponCampaign')
  setAction('purchase', {
    id: order.get('orderNumber'),
    affiliation: shopTitle,
    revenue: order.get('grandTotal'),
    shipping: String(order.getIn(['shippingData', 'price', 'amount'])),
    tax: order.get('totalTax'),
    currency: order.get('currencyId'),
    coupon: couponCampaign ? couponCampaign.get('name') : null,
  })
}

export function sendPageView(url) {
  shouldGABeUsed && window.ga('send', 'pageview', getLocationStringForGA(url))
}

export function setAction(action, data) {
  if (shouldGABeUsed) {
    data ? window.ga('ec:setAction', action, data) : window.ga('ec:setAction', action)
  }
}

export const sendProductSliderImpressions = (pageSlug) => (products, nonInteraction = true) => {
  if (!shouldGABeUsed) {
    return
  }
  const list = `${pageSlug}/product-slider`
  products.forEach((product, index) => {
    window.ga('ec:addImpression', {
      ...getBaseProductProperties(product),
      brand: product.manufacturer,
      list,
      position: index + 1,
    })
  })
  setAction('view', { list })
  window.ga('send', 'event', 'product-slider', 'view', pageSlug, { nonInteraction })
}

export function addCategoryImpression(products, category) {
  if (!shouldGABeUsed) {
    return
  }
  const categoryName = category !== undefined ? category.get('title') : 'null'

  products.forEach((product, index) => {
    window.ga('ec:addImpression', {
      ...getProductData(product, ['id', 'name', 'brand', 'price', 'currency']),

      category: categoryName,
      list: `category/${categoryName}`,
      position: index + 1,
    })
  })
}

export function addSearchImpression(products, { q }) {
  if (!shouldGABeUsed) {
    return
  }
  products.forEach((product, index) => {
    window.ga('ec:addImpression', {
      ...getProductData(product, ['id', 'name', 'brand', 'price', 'currency']),

      list: `search/${q}`,
      position: index + 1,
    })
  })
}

export const sendCrossSellingImpressions = (productName) => (products, nonInteraction = true) => {
  if (!shouldGABeUsed) {
    return
  }
  const list = `cross-selling/${productName}`
  products.forEach((product, index) => {
    window.ga('ec:addImpression', {
      ...getBaseProductProperties(product),
      brand: product.manufacturer,
      list,
      position: index + 1,
    })
  })
  setAction('view', { list })
  window.ga('send', 'event', 'cross-selling', 'view', productName, {
    nonInteraction,
  })
}

function sendProductData(productData) {
  shouldGABeUsed && window.ga('ec:addProduct', productData)
}

function getBaseProductProperties(product) {
  const price = product.singleItemPrice || product.price || {}

  return {
    id: product.sku,
    name: product.name,
    price: String(price.amount || ''),
    currency: price.currency || '',
  }
}

function getProductData(product, attributes) {
  return pick(getAllProductData(product), attributes)
}

function getAllProductData(product) {
  return {
    ...getBaseProductProperties(product.toJS()),
    variant: product.get('productVariationValues') || null,
    brand: product.get('manufacturer'),
  }
}

export function sendCartInteraction(LineItemProduct, quantityΔ) {
  sendProductData({
    ...getBaseProductProperties(LineItemProduct),
    variant: LineItemProduct.variationString,
    quantity: Math.abs(quantityΔ),
  })
  sendCartEvent(quantityΔ > 0 ? 'add' : 'remove')
}

export function sendAddToCart(product, quantity) {
  sendProductData({
    ...getBaseProductProperties(product),
    variant: product.productVariationValues,
    quantity,
  })
  sendCartEvent('add')
}

function sendCartEvent(eventType) {
  if (shouldGABeUsed) {
    window.ga('ec:setAction', eventType)
    window.ga('send', 'event', 'cart', 'click', eventType === 'add' ? 'add to cart' : 'remove from cart')
  }
}

export const trackProductClick = (list) => (product, position) => {
  sendProductData({
    ...getBaseProductProperties(product),
    variant: product.productVariationValues,
    position: position + 1,
  })
  sendProductClick(list)
}
function sendProductClick(list) {
  if (shouldGABeUsed) {
    const [type, detail = ''] = list
    window.ga('ec:setAction', 'click', { list: list.join('/') })
    window.ga('send', 'event', type, 'click', detail)
  }
}

export const trackCartProductClick = (LineItemProduct, position) => {
  sendProductData({
    ...getBaseProductProperties(LineItemProduct),
    variant: LineItemProduct.variationString,
    position: position + 1,
  })
  sendProductClick(['cart'])
}
