import PropTypes, { bool, func, oneOf, string } from 'prop-types'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import React from 'react'
import cc from 'classcat'
import loadable from '@loadable/component'

import {
  getLocationStringForGA,
  sendProductSliderImpressions,
  trackProductClick,
} from '../../../../../utils/googleAnalytics'
import { loadProductsByIds } from '../../../../../store/actions'
import EmptyProductListItem from '../../../EmptyProductListItem'
import ProductListItem from '../../../ProductListItem'
import ProductListSwiper from '../../../../ProductListSwiper'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ProductSliderSettings'))

export class ProductSliderPlugin extends React.Component {
  static propTypes = {
    config: PropTypes.shape({
      thumbnailUrl: PropTypes.func,
    }).isRequired,
    editorView: bool.isRequired,
    editorMode: oneOf(['view', 'edit']).isRequired,
    data: ImmutablePropTypes.map.isRequired,
    onSave: func.isRequired,
    onCancel: func.isRequired,
    onEdit: func.isRequired,
    onDataChange: func.isRequired,
    products: ImmutablePropTypes.list.isRequired,
    t: func.isRequired,
    loadProducts: func.isRequired,
    locationPathname: string.isRequired,
  }

  static defaultProps = { data: Immutable.fromJS({}) }

  static actionBarButtons = { save: false, edit: true }

  state = { isSettingActive: false, error: false }

  ref = React.createRef()

  onPluginActiveStateChange = (isSettingActive) => {
    this.setState({ isSettingActive })
  }

  loadProducts = () => {
    const { data, loadProducts } = this.props

    // catch the error and handle it in the wrapper to prevent the enclosing jsx from being rendered
    // as if the initial load fails, we do not know how many slides to render and without switching slidegroups it is impossible to recover
    return loadProducts(data.get('id'), Array.from(data.get('productIds'))).catch(() => this.setState({ error: true }))
  }

  renderProductItem = (product, index) => {
    const { locationPathname } = this.props
    return (
      <ProductListItem
        product={product}
        trackProductClick={trackProductClick([getLocationStringForGA(locationPathname), 'product-slider'])}
        productIndex={index}
        key={product.productId}
      />
    )
  }

  render() {
    const { editorMode, t, onEdit, editorView, data, products, locationPathname } = this.props
    const { error } = this.state
    const pluginActiveClasses = cc([
      'product-list-swiper',
      {
        'dali-grid-element-hightlighted': this.state.isSettingActive,
      },
    ])

    const visibleProducts = products
      .filter((product) => product.get('isVisible'))
      .toJS()
      .map((product) => ({ ...product, isLoaded: true }))
    const productList = { products: visibleProducts, totalNumberOfProducts: visibleProducts.length }

    if (error || (!editorView && !data.get('productIds').size)) {
      return null
    }

    return (
      <div className={pluginActiveClasses} ref={this.ref}>
        {editorMode === 'edit' && this.renderSettingsLayer()}
        {editorView && !data.get('productIds').size ? (
          <div className="dali-grid-element-placeholder">
            <span className="dali-plugin-productslider-placeholder" />
            <button className="dali-plugin-productslider-placeholder-button-add" onClick={onEdit}>
              {t('components.productSliderComponent.selectProductsButton.label')}
            </button>
          </div>
        ) : (
          <ProductListSwiper
            loadProducts={this.loadProducts}
            productList={visibleProducts.length > 0 ? productList : undefined}
            renderProductItem={this.renderProductItem}
            renderEmptyProductItem={(index) => <EmptyProductListItem key={'emptySlide_' + index} />}
            uniqueId={data.get('id')}
            breakpoints={['480px', '768px', '992px']}
            trackProductList={sendProductSliderImpressions(getLocationStringForGA(locationPathname))}
          />
        )}
      </div>
    )
  }

  renderSettingsLayer() {
    const { data, config, onDataChange, onSave, onCancel, products } = this.props

    return (
      <SettingsLayer
        referenceElement={this.ref.current}
        placement="top"
        onActiveStateChange={this.onPluginActiveStateChange}
        onEscapeKeyDown={(event) => event.target.tagName !== 'INPUT' && onCancel()}
        className="dali-settingslayer-productslider"
        products={products}
      >
        {({ renderLayout }) => (
          <SettingsForm
            {...{ data, config, onDataChange, onCancel, onSave, renderLayout, selectedProducts: products }}
          />
        )}
      </SettingsLayer>
    )
  }
}

export default compose(
  withI18n('interface'),
  translate(),
  connect(
    (state, props) => ({
      products: state.getIn(['productData', props.data.get('id'), 'products'], Immutable.List([])),
      locationPathname: state.getIn(['location', 'pathname']),
    }),
    (dispatch) => ({
      loadProducts: (pluginId, productIds) =>
        dispatch(
          loadProductsByIds(pluginId, productIds, {
            showErrorNotification: false,
          }),
        ),
    }),
  ),
)(ProductSliderPlugin)
