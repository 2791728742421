import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import NoResults from '../epages.base/NoResults'
import ProductListItem from '../../templateComponents/ProductListItem'
import ScrollToButton from '../../ScrollToButton'
import SearchSortingForm from '../epages.base/SearchSortingForm'
import ToolbarTop from '../../templateComponents/ToolbarTop'

export default function Search({
  searchData,
  trackProductClick,
  onScrollIntoView,
  loadMoreProducts,
  showScrollButton,
  isBusy,
  showLoadMoreButton,
  t,
}) {
  return (
    <div className="search">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            {searchData.products.length > 0 && searchData.queryString ? (
              <>
                <ToolbarTop className="product-list-header" onScrollIntoView={onScrollIntoView}>
                  <h1 className="headline">
                    {t('components.productSearchComponent.resultsCard.resultsState.title', {
                      queryString: searchData.queryString,
                    })}
                  </h1>
                  <SearchSortingForm searchData={searchData} t={t} />
                </ToolbarTop>
                <div className="product-list column-grid-2 column-grid-3 column-grid-4">
                  {searchData.products.map((product, productIndex) => (
                    <ProductListItem key={product.productId} {...{ product, productIndex, trackProductClick }} />
                  ))}
                </div>
                {showLoadMoreButton && (
                  <div className="product-list-footer">
                    <button
                      type="button"
                      className={cc(['show-more-button', { pending: isBusy }])}
                      disabled={isBusy}
                      onClick={loadMoreProducts}
                    >
                      {t('components.productSearchComponent.resultsCard.showMoreButton.label')}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <h1>
                  {searchData.queryString
                    ? t('components.productSearchComponent.resultsCard.resultsState.title', {
                        queryString: searchData.queryString,
                      })
                    : t('components.productSearchComponent.resultsCard.noQueryState.title')}
                </h1>
                <NoResults t={t} />
              </>
            )}

            {showScrollButton && <ScrollToButton className="scroll-to-button" t={t} />}
          </div>
        </div>
      </section>
    </div>
  )
}

Search.propTypes = {
  searchData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loadMoreProducts: PropTypes.func.isRequired,
  trackProductClick: PropTypes.func.isRequired,
  onScrollIntoView: PropTypes.func.isRequired,
  showScrollButton: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  showLoadMoreButton: PropTypes.bool.isRequired,
}
