import PropTypes from 'prop-types'
import React from 'react'

import Swiper from '../../templateComponents/Workspace/plugins/contentslider/Swiper'

const SwiperComponent = ({ slides, refSet }) => {
  return (
    <React.Fragment>
      <div className="swiper-container" ref={refSet.swiperContainerRef} key="container">
        <ul className="swiper-wrapper header-usp-list">
          {slides.map((usp, index) => (
            <li className="swiper-slide header-usp-list-item" key={index}>
              <span className="header-usp-list-item-wrapper">
                {usp.icon.show && <span className={`usp-${usp.icon.name}-icon`} />}
                {usp.text}
                <span></span>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="swiper-arrows" key="swiper-arrows">
        <div ref={refSet.nextButtonRef} className="swiper-arrow-right" />
        <div ref={refSet.prevButtonRef} className="swiper-arrow-left swiper-button-disabled" />
      </div>
    </React.Fragment>
  )
}
SwiperComponent.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  refSet: PropTypes.shape({
    nextButtonRef: PropTypes.any,
    prevButtonRef: PropTypes.any,
    swiperContainerRef: PropTypes.any,
  }).isRequired,
}

const HeaderUsp = ({ headerUsps }) => {
  const refSet = {
    swiperContainerRef: React.useRef(),
    nextButtonRef: React.useRef(),
    prevButtonRef: React.useRef(),
  }

  if (!headerUsps.length) return null

  return (
    <div>
      <div className="header-usp mobile-view">
        <Swiper
          slides={headerUsps}
          loop={true}
          autoplay={{ delay: 5000 }}
          renderSwiper={({ slides }) => <SwiperComponent refSet={refSet} slides={slides} />}
          refSet={refSet}
        />
      </div>
      <div className="header-usp desktop-view">
        <ul className="header-usp-list">
          {headerUsps.map((usp, index) => (
            <li className="header-usp-list-item" key={index}>
              <span className="header-usp-list-item-wrapper">
                {usp.icon.show && <span className={`usp-${usp.icon.name}-icon`} />}
                {usp.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

HeaderUsp.propTypes = {
  headerUsps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
      }),
    }),
  ),
}

HeaderUsp.defaultProps = {
  headerUsps: [],
}

export default HeaderUsp
