import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { bool, func, node, string } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'

import { getDefaultThemeSettings } from '../store/actions'
import CookieConsent from '../components/CookieConsent'
import NotificationOverlay from '../components/NotificationOverlay'
import PreviewBar from '../components/PreviewBar'
import XhrLoadingBar from '../components/XhrLoadingBar'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

export class StorefrontRaw extends React.Component {
  static propTypes = {
    viewErrorMessage: string,
    isShopClosed: bool.isRequired,
    isBeyond: bool.isRequired,
    isPreview: bool.isRequired,
    isEditor: bool.isRequired,
    themePreview: map,
    shopTitle: string,
    shopLocale: string,
    shopFaviconUrl: string,
    trustedShopsBadge: map,
    children: node,
    t: func.isRequired,
  }

  static storeUpdate = (_props, state) => [
    !state.get('defaultThemeSettings') ? getDefaultThemeSettings(state.getIn(['shop', 'themeStyleId'])) : null,
  ]

  get htmlHeadElements() {
    const { shopTitle, shopLocale, shopFaviconUrl } = this.props
    const faviconUrl = shopFaviconUrl || 'data:image/x-icon;,'

    return (
      <Helmet defaultTitle={shopTitle}>
        <html lang={shopLocale && shopLocale.substr(0, 2)} />
        <link rel="shortcut icon" href={faviconUrl} type="image/x-icon" />
        <link rel="icon" href={faviconUrl} type="image/x-icon" />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
    )
  }

  render() {
    const { themePreview, isShopClosed, isPreview, isBeyond, isEditor, viewErrorMessage, children } = this.props

    if (
      // see error.ts in fusion-backend-ts
      viewErrorMessage === 'The requested shop is currently closed' ||
      (isBeyond && isShopClosed && !isPreview && !themePreview && !isEditor)
    ) {
      return this.renderShopClosed()
    }

    return (
      <Fragment>
        <div className="ep-storefront-wrapper">
          {this.htmlHeadElements}
          <XhrLoadingBar />
          {isBeyond && isPreview && !isEditor && <PreviewBar />}
          {children}
          <CookieConsent isEditor={isEditor} />
        </div>
        {/* Make sure notification overlay is always on top of storefront */}
        <NotificationOverlay />
      </Fragment>
    )
  }

  renderShopClosed() {
    const { t } = this.props

    return (
      <div className="ep-shop-closed">
        {this.htmlHeadElements}
        <h1 className="ep-shop-closed-headline">{t('views.storefrontClosedView.explanation')}</h1>
        <div className="ep-shop-closed-image" />
      </div>
    )
  }
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state) => ({
    shopTitle: state.getIn(['shop', 'title']),
    shopLocale: state.getIn(['shop', 'locale']),
    shopFaviconUrl: state.getIn(['shop', 'faviconUrl']),
    isShopClosed: state.getIn(['shop', 'isClosed']),
    isBeyond: Boolean(state.getIn(['shop', 'beyond'])),
    isPreview: Boolean(state.getIn(['location', 'query', 'preview'])),
    isEditor: state.getIn(['view', 'editorMode']),
    themePreview: state.getIn(['view', 'themePreview']),
    viewErrorMessage: state.getIn(['view', 'error', 'message']),
  })),
)(StorefrontRaw)
