import * as redux from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import createApiCallMiddleware from './middlewares/apiCallMiddleware'
import defaultOptionsMiddleware from './middlewares/defaultOptionsMiddleware'
import emptyState from './emptyState'
import mainReducer from './reducers'

export function createStore(api, initialState = emptyState) {
  const middlewares = [thunk.withExtraArgument(api), defaultOptionsMiddleware(), createApiCallMiddleware(api)]
  const store = redux.createStore(mainReducer, initialState, composeWithDevTools(redux.applyMiddleware(...middlewares)))

  store.api = api
  return store
}
