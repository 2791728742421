import { bool, func, string } from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'

import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from './withI18n'

export function PreviewBar({ t, isShopClosed, themeTitle, themeStyleTitle }) {
  const isThemePreview = Boolean(themeTitle && themeStyleTitle)

  return (
    <div className="ep-preview-bar mobile-view-sm">
      {isThemePreview && (
        <span className="ep-preview-bar-theme-headline">
          {t('components.previewBarComponent.themeHeadline', { theme: themeTitle, style: themeStyleTitle })}
        </span>
      )}
      {isShopClosed && (
        <span className="ep-preview-bar-closed-text">{t('components.previewBarComponent.shopClosedMessage')}</span>
      )}
      <span className="ep-preview-bar-info">{t('components.previewBarComponent.title')}</span>
    </div>
  )
}

PreviewBar.propTypes = {
  isShopClosed: bool.isRequired,
  t: func.isRequired,
  themeTitle: string,
  themeStyleTitle: string,
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state) => ({
    isShopClosed: state.getIn(['shop', 'isClosed']),
    themeTitle: state.getIn(['location', 'query', 'themeTitle']),
    themeStyleTitle: state.getIn(['location', 'query', 'themeStyleTitle']),
  })),
)(PreviewBar)
