import { List } from 'immutable'

import { generateCategoryBlock } from '../../components/editorSidebarBlockTemplates'
import map from '../../utils/nestedListUtils/map'

export type NavigationActionTypes =
  | ResolvedApiAction<LoadNavigation>
  | HydrateNavigationAction
  | ResolvedApiAction<UpdateNavigation>
  | ResolvedApiAction<CreatePage>
  | ResolvedApiAction<DeletePage>
  | ResolvedApiAction<UpdateLegalSettings>
  | PreviewLegalPagesAction
  | ResolvedApiAction<PreviewFooterPages>
  | ResolvedApiAction<LoadFooterPages>
  | ResolvedApiAction<LoadLegalPages>
  | ResolvedApiAction<UpdateFooterNavigation>

type LoadNavigation = {
  type: typeof LOAD_NAVIGATION
  response: Core.NestedPage[]
}
export const LOAD_NAVIGATION = 'LOAD_NAVIGATION'
export const LOAD_NAVIGATION_SUCCESS = 'LOAD_NAVIGATION_SUCCESS'
export const LOAD_NAVIGATION_FAILURE = 'LOAD_NAVIGATION_FAILURE'
export function loadNavigation(options?: ActionOptions): ApiAction<LoadNavigation> {
  return {
    type: LOAD_NAVIGATION,
    idempotent: true,
    callApi: (api, _payload, { locale }) =>
      api.get('/api/v2/navigation/main', { params: { locale } }).then((res) => res.data),
    options,
  }
}

type HydrateNavigationAction = {
  type: typeof HYDRATE_NAVIGATION
}
export const HYDRATE_NAVIGATION = 'HYDRATE_NAVIGATION'
export const hydrateNavigation = (): HydrateNavigationAction => ({ type: HYDRATE_NAVIGATION })

type UpdateNavigation = {
  type: typeof UPDATE_NAVIGATION
  payload: {
    navigation: List<ImmutableMap>
  }
  response: Core.NestedPage[]
}
export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION'
export const UPDATE_NAVIGATION_SUCCESS = 'UPDATE_NAVIGATION_SUCCESS'
export const UPDATE_NAVIGATION_FAILURE = 'UPDATE_NAVIGATION_FAILURE'
export function updateNavigation(navigation: List<ImmutableMap>, options?: ActionOptions): ApiAction<UpdateNavigation> {
  return {
    type: UPDATE_NAVIGATION,
    idempotent: false,
    payload: {
      navigation,
    },
    memorize: (state) => ({
      navigation: state.get('navigation'),
    }),
    callApi: async (api, { navigation }, { locale }) => {
      const relevantProps = ['slug', 'categoryId', 'isVisible', 'children']
      const onlyRelevantProps = map(navigation, (page: ImmutableMap) =>
        page.filter((_v, k) => Boolean(k && relevantProps.includes(k))),
      )

      return api.put('/api/v2/navigation/main', onlyRelevantProps, { params: { locale } }).then((res) => res.data)
    },
    options,
  }
}

export type CreatePage = {
  type: typeof CREATE_PAGE
  payload: Pick<Core.Page, 'title' | 'navigation' | 'isVisible'> & {
    pageParentSlug?: string
    pageType: Core.Page['type']
    pageSlug: string
    content: Partial<Core.Content> | null
  }
  response: Core.Page
}
export const CREATE_PAGE = 'CREATE_PAGE'
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS'
export const CREATE_PAGE_FAILURE = 'CREATE_PAGE_FAILURE'
export function createPage(
  {
    pageSlug,
    title,
    pageType,
    navigation,
    isVisible,
    collectionId,
    pageParentSlug,
  }: Omit<CreatePage['payload'], 'content'> & { collectionId: string | null },
  options?: ActionOptions,
): ApiAction<CreatePage> {
  const categories = collectionId !== null && generateCategoryBlock(collectionId)
  const content =
    categories && !categories.isEmpty()
      ? {
          themeId: '@all',
          blocks: [categories.toJS()],
          elements: [],
        }
      : null

  return {
    type: CREATE_PAGE,
    idempotent: false,
    payload: {
      pageSlug,
      title,
      pageType,
      navigation,
      isVisible,
      pageParentSlug,
      content,
    },
    callApi: (api, { pageSlug, title, pageType, navigation, isVisible, content }, { locale }) =>
      api
        .post(
          `/api/v2/pages/${pageSlug}`,
          {
            title,
            type: pageType,
            navigation,
            isVisible,
            content,
            pageParentSlug: navigation !== 'footer' && pageParentSlug,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type DeletePage = {
  type: typeof DELETE_PAGE
  payload: {
    pageSlug: string
  }
}
export const DELETE_PAGE = 'DELETE_PAGE'
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS'
export const DELETE_PAGE_FAILURE = 'DELETE_PAGE_FAILURE'
export function deletePage(pageSlug: string, options?: ActionOptions): ApiAction<DeletePage> {
  return {
    type: DELETE_PAGE,
    idempotent: true,
    payload: {
      pageSlug,
    },
    memorize: (state) => ({
      navigation: state.get('navigation'),
    }),
    callApi: (api, { pageSlug }, { locale }) =>
      api.delete(`/api/v2/pages/${pageSlug}`, { params: { locale } }).then(() => undefined),
    options,
  }
}

type UpdateLegalSettings = {
  type: typeof UPDATE_LEGAL_SETTINGS
  payload: {
    slug: string
    isVisible: boolean
  }
  response: Core.Page
}
export const UPDATE_LEGAL_SETTINGS = 'UPDATE_LEGAL_SETTINGS'
export const UPDATE_LEGAL_SETTINGS_SUCCESS = 'UPDATE_LEGAL_SETTINGS_SUCCESS'
export const UPDATE_LEGAL_SETTINGS_FAILURE = 'UPDATE_LEGAL_SETTINGS_FAILURE'
export function updateLegalSettings(
  { slug, isVisible }: UpdateLegalSettings['payload'],
  options?: ActionOptions,
): ApiAction<UpdateLegalSettings> {
  return {
    type: UPDATE_LEGAL_SETTINGS,
    idempotent: false,
    payload: { slug, isVisible },
    memorize: (state) => ({
      legalPages: state.get('legalPages'),
    }),
    callApi: (api, { slug, isVisible }, { locale }) =>
      api
        .patch(
          `/api/v2/legal/${slug}`,
          {
            slug,
            isVisible,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type PreviewLegalPagesAction = {
  type: typeof PREVIEW_LEGAL_PAGES
  response: {
    legalPages: List<ImmutableMap>
  }
}
export const PREVIEW_LEGAL_PAGES = 'PREVIEW_LEGAL_PAGES'
export function previewLegalPages(legalPages: List<ImmutableMap>): PreviewLegalPagesAction {
  return {
    type: PREVIEW_LEGAL_PAGES,
    response: { legalPages },
  }
}

type PreviewFooterPages = {
  type: typeof PREVIEW_FOOTER_PAGES
  response: {
    footerPages: List<ImmutableMap>
  }
}
export const PREVIEW_FOOTER_PAGES = 'PREVIEW_FOOTER_PAGES'
export function previewFooterPages(footerPages: List<ImmutableMap>): PreviewFooterPages {
  return {
    type: PREVIEW_FOOTER_PAGES,
    response: { footerPages },
  }
}

type LoadFooterPages = {
  type: typeof LOAD_FOOTER_PAGES
  response: Core.NestedPage[]
}
export const LOAD_FOOTER_PAGES = 'LOAD_FOOTER_PAGES'
export const LOAD_FOOTER_PAGES_SUCCESS = 'LOAD_FOOTER_PAGES_SUCCESS'
export const LOAD_FOOTER_PAGES_FAILURE = 'LOAD_FOOTER_PAGES_FAILURE'
export function loadFooterPages(options?: ActionOptions): ApiAction<LoadFooterPages> {
  return {
    type: LOAD_FOOTER_PAGES,
    idempotent: true,
    callApi: (api, _payload, { locale }) =>
      api.get('/api/v2/navigation/footer', { params: { locale } }).then((res) => res.data),
    options,
  }
}

type LoadLegalPages = {
  type: typeof LOAD_LEGAL_PAGES
  response: Core.NestedPage[]
}
export const LOAD_LEGAL_PAGES = 'LOAD_LEGAL_PAGES'
export const LOAD_LEGAL_PAGES_SUCCESS = 'LOAD_LEGAL_PAGES_SUCCESS'
export const LOAD_LEGAL_PAGES_FAILURE = 'LOAD_LEGAL_PAGES_FAILURE'
export function loadLegalPages(options?: ActionOptions): ApiAction<LoadLegalPages> {
  return {
    type: LOAD_LEGAL_PAGES,
    idempotent: true,
    callApi: (api, _payload, { locale }) =>
      api.get('/api/v2/navigation/legal', { params: { locale } }).then((res) => res.data),
    options,
  }
}

type UpdateFooterNavigation = {
  type: typeof UPDATE_FOOTER_NAVIGATION
  payload: {
    navigation: List<ImmutableMap>
  }
}
export const UPDATE_FOOTER_NAVIGATION = 'UPDATE_FOOTER_NAVIGATION'
export const UPDATE_FOOTER_NAVIGATION_SUCCESS = 'UPDATE_FOOTER_NAVIGATION_SUCCESS'
export const UPDATE_FOOTER_NAVIGATION_FAILURE = 'UPDATE_FOOTER_NAVIGATION_FAILURE'
export function updateFooterNavigation(
  navigation: List<ImmutableMap>,
  options?: ActionOptions,
): ApiAction<UpdateFooterNavigation> {
  return {
    type: UPDATE_FOOTER_NAVIGATION,
    idempotent: false,
    payload: {
      navigation,
    },
    callApi: (api, { navigation }, { locale }) => {
      const relevantProps = ['slug', 'categoryId', 'isVisible', 'children']
      const onlyRelevantProps = map(navigation, (page: ImmutableMap) =>
        page.filter((v, k) => Boolean(k && relevantProps.includes(k))),
      )

      return api.put('/api/v2/navigation/footer', onlyRelevantProps, { params: { locale } }).then((res) => res.data)
    },
    options,
  }
}
