import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import Link from '../../templateComponents/Link'

function loopNodeList(nodeList, fn) {
  for (let i = 0, l = nodeList.length; i < l; i++) {
    fn(nodeList.item(i))
  }
}

function repositionSubMenus(item, navigationRulerRect, level = 0, flowDirection = 'right') {
  if (item.classList.contains('main-menu') || item.classList.contains('sub-menu')) {
    if (level <= 1) {
      // loopNodeList(item.children, child => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'));
      // handeling the first layer of sub menus
      switch (flowDirection) {
        case 'right': {
          // default position to flow right
          item.style.right = ''
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.right <= navigationRulerRect.right) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          } else {
            item.style.right = '0'
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          }

          break
        }
        case 'left': {
          // default position to flow left
          item.style.right = '0'
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.left >= navigationRulerRect.left) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          } else {
            item.style.right = ''
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          }

          break
        }
        default:
          throw new Error(`Unsupported flow direction ${flowDirection}`)
      }
    } else {
      // handling all other layers of sub menus
      switch (flowDirection) {
        case 'right': {
          // default position to flow right
          item.style.left = ''
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.right <= navigationRulerRect.right) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          } else {
            item.style.left = `${-item.getBoundingClientRect().width}px`
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          }

          break
        }
        case 'left': {
          // default position to flow left
          item.style.left = `${-item.getBoundingClientRect().width}px`
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.left >= navigationRulerRect.left) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          } else {
            item.style.left = ''
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          }

          break
        }
        default:
          throw new Error(`Unsupported flow direction ${flowDirection}`)
      }
    }
  } else {
    loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level, flowDirection))
  }
}

function extendItems(items, active, opened) {
  return items.reduce((acc, item) => {
    return acc.concat([
      Object.assign({}, item, {
        active: active && active.id === item.id,
        opened: Boolean(opened.find((i) => i.id === item.id)),
        children: extendItems(item.children, active, opened),
      }),
    ])
  }, [])
}

function traceOpened(items, active) {
  return items.reduce((trace, item) => {
    if (item.id === active.id) {
      return [item]
    }

    const subTrace = traceOpened(item.children, active)
    if (subTrace) {
      return [item].concat(subTrace)
    }

    return trace || null
  }, null)
}

// NestedMenu uses state that is read/set by a parent component.
/* eslint-disable react/no-unused-state */

const NestedMenuContext = React.createContext()

class NestedMenu extends React.Component {
  static propTypes = {
    desktopMediaQuery: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  state = {
    isDesktop: false,
    active: null,
    opened: [],
    menuOpen: false,
    touch: false,
  }

  currentLocation = null

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.currentLocation = window.location.href
  }

  componentDidUpdate() {
    // close menu on changing the adress
    if (this.currentLocation !== window.location.href) {
      this.currentLocation = window.location.href
      this.resetState()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const mainMenu = this.element && this.element.querySelector('.main-menu')
    const nestedMenuRuler = this.rulerElement

    if (mainMenu && nestedMenuRuler) {
      repositionSubMenus(mainMenu, nestedMenuRuler.getBoundingClientRect())
    }

    this.setState({
      isDesktop: window.matchMedia(this.props.desktopMediaQuery).matches,
    })
  }

  handleMouseLeave = () => {
    if (this.state.isDesktop) {
      this.resetState()
    }
  }

  handleTouchStart = () => {
    this.setState({ touch: true })
  }

  resetState = () => {
    this.setState({
      active: null,
      opened: [],
      menuOpen: false,
      touch: false,
    })
  }

  toggleMobile = () => {
    if (this.state.menuOpen) {
      this.closeMobile()
    } else {
      this.setState({
        active: null,
        opened: [],
        menuOpen: true,
      })
    }
  }

  closeMobile = () => {
    this.setState({
      active: null,
      opened: [],
      menuOpen: false,
    })
  }

  render() {
    return (
      // Passing "state" alongside the component instance so that consumers get re-rendered when updating
      <NestedMenuContext.Provider value={{ instance: this, state: this.state }}>
        <div
          ref={(node) => (this.element = node)}
          className={cc(['nested-menu', this.props.className])}
          onMouseLeave={this.handleMouseLeave}
          onTouchStart={this.handleTouchStart}
        >
          <div ref={(node) => (this.rulerElement = node)} className="nested-menu-ruler" />
          {this.props.children}
        </div>
      </NestedMenuContext.Provider>
    )
  }
}

const MobileToggle = (props) => {
  const nestedMenu = React.useContext(NestedMenuContext)
  return (
    <span
      {...props}
      onClick={() => {
        nestedMenu.instance.toggleMobile()
      }}
    />
  )
}

class Menu extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
  }

  get nestedMenu() {
    return this.context.instance
  }

  handleItemMouseEnter = (event, item) => {
    if (this.nestedMenu.state.isDesktop && !this.nestedMenu.state.touch) {
      this.activateItem(item)
    }
  }

  handleItemMouseLeave = (event, item) => {
    if (this.nestedMenu.state.isDesktop) {
      this.deactivateItem(item)
    }
  }

  handleItemClick = (event, item) => {
    const isActiveItem = this.nestedMenu.state.active && this.nestedMenu.state.active.id === item.id

    if (isActiveItem || !item.children.length) {
      this.nestedMenu.resetState()
    } else {
      event.preventDefault()
      event.stopPropagation()

      this.activateItem(item)
    }
  }

  handleItemToggleClick = (event, item) => {
    event.preventDefault()

    if (!this.nestedMenu.state.opened.find((i) => i.id === item.id)) {
      this.activateItem(item)
    } else {
      this.deactivateItem(item)
    }
  }

  activateItem = (item) => {
    const opened = traceOpened(this.props.items, item) || []

    this.nestedMenu.setState({
      active: item,
      opened,
      menuOpen: true,
    })
  }

  deactivateItem = (item) => {
    const opened = traceOpened(this.props.items, item) || []

    this.nestedMenu.setState({
      active: opened[opened.length - 2] || null,
      opened: opened.slice(0, opened.length - 1),
    })
  }

  render() {
    const { active, opened } = this.nestedMenu.state

    return (
      <div className={cc(['main-menu-wrapper', { show: this.nestedMenu.state.menuOpen }])}>
        <div className="main-menu-overlay" onClick={this.nestedMenu.closeMobile} />
        {this.renderLayer(extendItems(this.props.items, active, opened), 0)}
      </div>
    )
  }

  renderLayer = (items, level) => {
    return (
      <ul className={cc([{ 'main-menu': level === 0, 'sub-menu': level > 0 }])}>
        {items.map((item) => {
          const hasSubMenu = item.children.length > 0

          return (
            <li
              key={item.id}
              className={cc({
                active: item.opened,
                'navigation-active': item.isInBreadcrumb,
              })}
              onMouseEnter={(e) => this.handleItemMouseEnter(e, item)}
              onMouseLeave={(e) => this.handleItemMouseLeave(e, item)}
            >
              <Link to={item.href} onClick={(e) => this.handleItemClick(e, item)}>
                <span>{item.title}</span>
              </Link>
              {!hasSubMenu ? null : (
                <div
                  className={cc(['drop-icon', { opened: item.opened }])}
                  onClick={(e) => this.handleItemToggleClick(e, item)}
                />
              )}
              {hasSubMenu ? this.renderLayer(item.children, level + 1) : null}
            </li>
          )
        })}
      </ul>
    )
  }
}

Menu.contextType = NestedMenuContext

export default Object.assign(NestedMenu, { Menu, MobileToggle })
