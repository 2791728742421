import Immutable from 'immutable'

import * as actions from '../actions'
import { extendOrderWithProductUrls, extendProductWithUrl } from '../../urlGenerators'

// importing the constant would break code splitting
const SWITCH_THEME_SUCCESS = 'SWITCH_THEME_SUCCESS'
type SwitchThemeActionSuccess = { type: typeof SWITCH_THEME_SUCCESS; themeStyleId: string }

export default function shop(state: State, action: actions.AllActionTypes | SwitchThemeActionSuccess): State {
  switch (action.type) {
    case actions.SET_MBO_BASE_URL:
      return state.setIn(['shop', 'mboBaseUrl'], action.mboBaseUrl)
    case actions.GET_THEMES_SUCCESS:
      return state.set('themes', Immutable.fromJS(action.response).sort(sortThemes))
    case actions.SET_THEME_SUCCESS:
      return state
        .setIn(['shop', 'theme'], Immutable.fromJS(action.response.theme))
        .setIn(['shop', 'themeStyle'], Immutable.fromJS(action.response.themeStyle))
        .setIn(['shop', 'themeId'], action.response.theme.id)
        .setIn(['shop', 'themeStyleId'], action.response.themeStyle.id)
    case SWITCH_THEME_SUCCESS:
      return state.setIn(['shop', 'themeSwitchedTo'], action.themeStyleId)
    case actions.LOAD_SHOP_EMAIL_SUCCESS:
      return state.setIn(['shop', 'email'], action.response)
    case actions.PREVIEW_SHOP_LOGO:
    case actions.SET_SHOP_LOGO_SUCCESS:
      return state
        .setIn(['shop', 'logoUrl'], action.response.logoUrl)
        .setIn(['shop', 'logoWidth'], action.response.logoWidth)
        .setIn(['shop', 'logoHeight'], action.response.logoHeight)
        .setIn(['shop', 'shouldHideLogo'], action.response.shouldHideLogo)
    case actions.PREVIEW_THEME_LOGO_ADJUSTMENTS:
      return state.setIn(
        ['shop', 'themeStyle', 'themeStyleSettings', 'settings', 'logoAdjustments'],
        action.response.logoAdjustments,
      )

    case actions.PREVIEW_USER_SETTINGS:
    case actions.SET_USER_SETTINGS_SUCCESS:
      return state.setIn(['shop', 'userSettings'], Immutable.fromJS(action.response.userSettings))
    case actions.PREVIEW_FAVICON:
    case actions.SET_FAVICON_SUCCESS:
      return state
        .setIn(['shop', 'faviconUrl'], action.response.faviconUrl)
        .setIn(['shop', 'faviconWidth'], action.response.faviconWidth)
        .setIn(['shop', 'faviconHeight'], action.response.faviconHeight)
    case actions.GET_THEME_SETTINGS_SCHEMA_SUCCESS:
      return state.set('themeSettingsSchema', Immutable.fromJS(action.response))
    case actions.GET_DEFAULT_THEME_SETTINGS_SUCCESS:
      return state.set('defaultThemeSettings', Immutable.fromJS(action.response))

    case actions.PREVIEW_THEME_STYLE_SETTINGS:
      return state.setIn(['shop', 'themeStyle', 'themeStyleSettings', 'settings'], action.response)

    case actions.PREVIEW_THEME_SETTINGS:
    case actions.GET_THEME_SETTINGS_SUCCESS:
    case actions.SET_THEME_SETTINGS_SUCCESS:
      return state
        .setIn(['shop', 'theme', 'themeSettings', 'settings'], Immutable.fromJS(action.response.theme))
        .setIn(['shop', 'themeStyle', 'themeStyleSettings', 'settings'], Immutable.fromJS(action.response.themeStyle))
    case actions.GET_THEME_STYLE_META_SUCCESS:
      return state.set('themeStyleMeta', Immutable.fromJS(action.response))
    case actions.SET_THEME_VARIABLES_CSS:
      return state.set('themeVariablesCss', action.variablesCss)
    case actions.GET_SCRIPT_TAGS_SUCCESS:
      return state.set('scriptTags', Immutable.fromJS(action.response))
    case actions.GET_RESELLER_NAME_SUCCESS:
      return state.set('resellerName', action.response)
    case actions.GET_TAX_SUCCESS:
      return state.set('tax', action.response)
    case actions.LOAD_CATEGORY_SUCCESS:
      return state.setIn(['shop', 'csrfToken'], action.response.csrfToken)
    case actions.LOAD_PRODUCT_SUCCESS: {
      const slugWithGuidFallback = action.guidAndSlug.slug || action.guidAndSlug.guid
      return state.setIn(['products', slugWithGuidFallback], extendProductWithUrl(Immutable.fromJS(action.response)))
    }
    case actions.LOAD_ORDER_SUCCESS:
      return state.setIn(['orders', action.orderId], extendOrderWithProductUrls(Immutable.fromJS(action.response)))
    default:
      return state
  }
}

// Specify the theme order for rendering the list.
// All themes not included here are rendered below.
const themeOrder = [
  'epages.vision',
  'epages.neutral',
  'epages.essence',
  'epages.limelight',
  'epages.editorial',
  'epages.uptown',
  'epages.structure',
]
function sortThemes(a, b) {
  const indexA = themeOrder.indexOf(a.get('name'))
  const indexB = themeOrder.indexOf(b.get('name'))

  if (indexA === -1) return 1
  if (indexB === -1) return -1
  return indexA - indexB
}
