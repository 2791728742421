import PropTypes from 'prop-types'
import React from 'react'

import Content from '../../templateComponents/Workspace'

export default function Page({ page }) {
  return (
    <div className="template-page">
      <h1>{page.title}</h1>
      <Content />
    </div>
  )
}

Page.propTypes = { page: PropTypes.object.isRequired }
