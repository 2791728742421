const events = []

/**
 * Initialize the tracking and add the event target to the window.
 *
 */
export function init() {
  try {
    window.eComEventTarget = new EventTarget()
  } catch (err) {
    // in Edge and Safari EventTarget is no constructor
    window.eComEventTarget = document.createElement('aside')
  }
}

/**
 * trigger an event on the event target
 *
 * @param {string} name the events name
 * @param {any} detail additional event data
 */
export function track(name, detail) {
  if (typeof window !== 'object' || !window.eComEventTarget) return

  events.push({ name, detail })
  if (document.readyState === 'complete') {
    dispatchTrackEvent(name, detail)
  } else {
    window.addEventListener('load', () => dispatchTrackEvent(name, detail))
  }
}

function dispatchTrackEvent(name, detail) {
  try {
    window.eComEventTarget.dispatchEvent(new CustomEvent(name, { detail }))
  } catch (err) {
    // in IE 11 CustomeEvent cannot be called as a constructor (https://caniuse.com/#feat=customevent)
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent(name, true, true, detail)
    window.eComEventTarget.dispatchEvent(event)
  }
}
