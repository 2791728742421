import PropTypes from 'prop-types'
import React from 'react'

import Link from '../../templateComponents/Link'

export default function Layout({ shop, currentView, navigation, footerPages, legalPages, breadcrumb, content }) {
  return (
    <div className={`template-layout view-${currentView.toLowerCase()}`}>
      <div>{shop.slug}</div>
      <nav>
        <ul>
          {navigation.map((page, index) => (
            <li key={index}>
              <Link to={page.href}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
      {breadcrumb.length > 1 && (
        <ul className="breadcrumb">
          {breadcrumb.map((page, index) => (
            <li key={index}>
              <Link to={page.href}>{page.title}</Link>
            </li>
          ))}
        </ul>
      )}
      <main>{content}</main>
      <footer>
        <ul>
          {legalPages.map((legalPage, index) => (
            <li key={index}>
              <Link to={legalPage.href}>{legalPage.title}</Link>
            </li>
          ))}
        </ul>
        <ul className="footer-pages">
          {footerPages.map((footerPage, index) => (
            <li key={index}>
              <Link to={footerPage.href}>{footerPage.title}</Link>
            </li>
          ))}
        </ul>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  shop: PropTypes.object.isRequired,
  currentView: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  footerPages: PropTypes.array.isRequired,
  navigation: PropTypes.array.isRequired,
  breadcrumb: PropTypes.array.isRequired,
  legalPages: PropTypes.array.isRequired,
}
