import { v4 as generateUUID } from 'uuid'
import Immutable from 'immutable'
import React from 'react'

import storage from '../utils/localStorage'

const showImageGallery = Boolean(storage.getItem('imageGallery'))

const svgStyle = {
  width: '100%',
  height: 'auto',
}
const pathStyle = {
  fill: 'rgb(209, 209, 209)',
}

function CollectionIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0v64h192V0H0z M191,63H1V1h190V63z M120.9,38.8c-0.2-0.7-0.3-0.8-0.3-0.8s0-1-0.3-1.6c-0.3-0.6-0.3-0.8-0.3-0.8 s0-3.1-0.1-3.8c-0.1-0.7-0.1-0.8-0.1-0.8s0.2-1.3,0.1-1.6c0,0,0-0.1,0-0.1l0.2-1.3c0-0.2-0.1-0.3-0.2-0.4l-0.6,0 c-0.1,0-0.1,0-0.2,0.1l-0.2,1.3c-1.5-0.1-5-0.2-5.4-0.2c-0.5,0-1,0.2-1.2,1.4c-0.2,1.1-0.1,3.4-1.2,5.3c-1,1.9-2.5,3-3.4,3.6 c-0.8,0.5-1.3,1-3,1.6c-1.2,0.4-3,0.7-4.1,1.1c-0.9,0.3-0.7,2.2-0.6,2.3c0.1,0.1,2.2,0.6,4.6,0.6c2.8,0.1,4,0.3,6.7-0.3 c2.7-0.6,3.7-0.6,3.7-0.6l0,0.9c0,0,4.7,0,5.2-0.1c0.5-0.1,0.7-0.3,0.7-0.3v-2.2c0,0,0-0.4,0.1-0.9C121,40.7,121.1,39.6,120.9,38.8z M78.9,32.6l1.3-3l-0.7-0.3l-1.3,3c-0.5,0-0.8,0.4-0.8,0.9c0,0.5,0.4,0.9,0.9,0.9c0.4,0,0.7-0.3,0.8-0.6l1.7-0.3l-0.1-0.8L78.9,32.6 z M81.7,27.7v-4.5c0-0.9-0.7-1.6-1.6-1.6h-4.1c-0.9,0-1.6,0.7-1.6,1.6v4.5c-1.9,1.2-3.1,3.3-3.1,5.6c0,2.4,1.2,4.5,3.1,5.6v4.5 c0,0.9,0.7,1.6,1.6,1.6h4.1c0.9,0,1.6-0.7,1.6-1.6v-4.5c1.9-1.2,3.1-3.3,3.1-5.6C84.8,30.9,83.6,28.9,81.7,27.7z M78.1,39 c-3.1,0-5.7-2.6-5.7-5.7c0-3.1,2.6-5.7,5.7-5.7s5.7,2.6,5.7,5.7C83.8,36.4,81.2,39,78.1,39z M102,39.7c0.7-0.2,1.4-0.3,2-0.5 c1.2-0.4,1.6-0.7,2.2-1.1c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,1.1-0.8,1.9-1.6l-3-9.9c-0.3-0.9-1.1-1.6-2.1-1.6h-2.9 c-0.4-4.1-2.6-7.2-5.3-7.2c-2.6,0-4.8,3.1-5.3,7.2h-2.7c-1,0-1.9,0.7-2.1,1.7L84.7,28c1.2,1.5,1.9,3.3,1.9,5.3 c0,2.5-1.1,4.8-2.9,6.3v3.1l14.5-0.2c0-0.2,0-0.3,0-0.3c0.3-1.4,1.2-1.9,1.6-2C100.5,40.1,101.3,39.9,102,39.7z M91.2,24.7 c0.5-3.5,2.1-6.1,4.1-6.1c1.9,0,3.6,2.6,4.1,6.1H91.2z"
        style={pathStyle}
      />
    </svg>
  )
}

function TextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M78.8,18.5c0,0.9,0,1.8,0,2.7h23.7c0-0.9,0-1.6,0-2.7H78.8z M78.8,42.9c0,0.9,0,1.8,0,2.7h23.7c0-0.9,0-1.6,0-2.7H78.8z M78.8,34.9c0,0.9,0,1.8,0,2.7H113c0-0.9,0-1.6,0-2.7H78.8z M78.8,26.9c0,0.9,0,1.8,0,2.7H113c0-0.9,0-1.6,0-2.7H78.8z M192,0v64H0V0H192z M191,1H1v62h190V1z"
        style={pathStyle}
      />
    </svg>
  )
}

function ImageIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M87,29.8c2,0,3.4-1.6,3.4-3.4S89,23,87,23s-3.4,1.5-3.4,3.4S85.1,29.8,87,29.8z M108.4,33.1L101,26l-9,9l-2.8-2.8l-5.6,5.6V41h24.8L108.4,33.1L108.4,33.1z M82,45.6h28c1.6,0,3-1.3,3-2.8V21.3c0-1.5-1.4-2.8-3-2.8H82c-1.6,0-3,1.3-3,2.8v21.4C79,44.2,80.4,45.6,82,45.6L82,45.6z M81.4,21.4c0-0.3,0.2-0.5,0.5-0.5h28c0.4,0,0.6,0.2,0.6,0.5v21.4c0,0.3-0.2,0.5-0.5,0.5H82c-0.4,0-0.6-0.2-0.6-0.5V21.4L81.4,21.4z M0,0v64h192V0H0z M191,63H1V1h190V63z"
        style={pathStyle}
      />
    </svg>
  )
}

function VideoIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M96,48.4c9.7,0,17.6-7.3,17.6-16.4S105.7,15.6,96,15.6S78.4,22.9,78.4,32S86.3,48.4,96,48.4z M104.8,30.8c0.5,0.2,0.7,0.7,0.7,1.2c0,0.5-0.3,0.9-0.7,1.2L92.3,40c-0.4,0.3-1,0.3-1.5,0c-0.5-0.3-0.7-0.7-0.7-1.2V25.2c0-0.5,0.3-0.9,0.7-1.2c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2L104.8,30.8L104.8,30.8z M0,0v64h192V0H0z M191,63H1V1h190V63z"
        style={pathStyle}
      />
    </svg>
  )
}

function TextVideoIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,64h91V0H0V64z M1,1h89v62H1V1z M101,0v64h91V0H101z M191,63h-89V1h89V63z M29.5,42.8c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H29.5z M29.5,18.5c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H29.5z M29.5,26.4c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H29.5z M29.5,34.3c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H29.5z M147.3,43.5c6.5,0,11.9-5.1,11.9-11.5s-5.3-11.5-11.9-11.5s-11.9,5.1-11.9,11.5S140.7,43.5,147.3,43.5z M153.2,31.2c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.7-0.5,0.8l-8.4,4.8c-0.3,0.2-0.7,0.2-1,0c-0.3-0.2-0.5-0.5-0.5-0.8v-9.6c0-0.3,0.2-0.7,0.5-0.8c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1L153.2,31.2L153.2,31.2z"
        style={pathStyle}
      />
    </svg>
  )
}

function VideoTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,64h91V0H0V64z M1,1h89v62H1V1z M101,0v64h91V0H101z M191,63h-89V1h89V63z M129.4,42.8c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H129.4z M129.4,18.5c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H129.4z M129.4,26.4c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H129.4z M129.4,34.3c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H129.4z M45.3,43.5c6.5,0,11.9-5.1,11.9-11.5s-5.3-11.5-11.9-11.5S33.4,25.7,33.4,32S38.7,43.5,45.3,43.5z M51.2,31.2c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.7-0.5,0.8l-8.4,4.8c-0.3,0.2-0.7,0.2-1,0c-0.3-0.2-0.5-0.5-0.5-0.8v-9.6c0-0.3,0.2-0.7,0.5-0.8c0.2-0.1,0.3-0.1,0.5-0.1s0.3,0,0.5,0.1L51.2,31.2L51.2,31.2z"
        style={pathStyle}
      />
    </svg>
  )
}

function ThreeVideosWithTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M67.7,64h56.6V0H67.7V64z M68.7,1h54.6v62H68.7V1z M0,64h57.7V0H0V64z M1,1h55.7v62H1V1z M134.3,0v64H192V0H134.3z M191,63h-55.7V1H191V63z M12.8,40.7c0,0.7,0,1.3,0,2h32.1c0-0.7,0-1.2,0-2H12.8z M12.8,52.8c0,0.7,0,1.3,0,2h22c0-0.7,0-1.2,0-2H12.8z M12.8,46.9c0,0.7,0,1.3,0,2h32.4c0-0.7,0-1.2,0-2H12.8z M79.9,40.5c0,0.7,0,1.3,0,2h31.8c0-0.7,0-1.2,0-2H79.9z M79.9,52.6c0,0.7,0,1.3,0,2h21.8c0-0.7,0-1.2,0-2H79.9z M79.9,46.7c0,0.7,0,1.3,0,2H112c0-0.7,0-1.2,0-2H79.9z M147.1,40.5c0,0.7,0,1.3,0,2h30.7c0-0.7,0-1.2,0-2H147.1z M147.1,52.6c0,0.7,0,1.3,0,2h21c0-0.7,0-1.2,0-2H147.1z M147.1,46.7c0,0.7,0,1.3,0,2h31c0-0.7,0-1.2,0-2H147.1z M95.8,30.1c4.6,0,8.3-3.6,8.3-8.1s-3.7-8.1-8.3-8.1c-4.6,0-8.3,3.6-8.3,8.1S91.2,30.1,95.8,30.1z M100,21.4c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6l-5.9,3.4c-0.2,0.1-0.5,0.1-0.7,0c-0.2-0.1-0.3-0.3-0.3-0.6v-6.7c0-0.2,0.1-0.5,0.3-0.6c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1L100,21.4L100,21.4z M28.8,30.1c4.6,0,8.3-3.6,8.3-8.1s-3.7-8.1-8.3-8.1s-8.3,3.6-8.3,8.1S24.2,30.1,28.8,30.1z M33,21.4c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6l-5.9,3.4c-0.2,0.1-0.5,0.1-0.7,0c-0.2-0.1-0.3-0.3-0.3-0.6v-6.7c0-0.2,0.1-0.5,0.3-0.6c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1L33,21.4L33,21.4z M162.2,30.1c4.6,0,8.3-3.6,8.3-8.1s-3.7-8.1-8.3-8.1s-8.3,3.6-8.3,8.1S157.6,30.1,162.2,30.1z M166.4,21.4c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6l-5.9,3.4c-0.2,0.1-0.5,0.1-0.7,0c-0.2-0.1-0.3-0.3-0.3-0.6v-6.7c0-0.2,0.1-0.5,0.3-0.6c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1L166.4,21.4L166.4,21.4z"
        style={pathStyle}
      />
    </svg>
  )
}

function GmapsIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0 0v64h192V0H0zm191 63H1V1h190v62zm-77.6-22.6V19.7c0-.7-.7-1.1-1.3-.9l-8.3 3.8v23l8.4-3.4c.7-.3 1.2-1 1.2-1.8zm-33.2 5.1l8.3-3.8v-23l-8.4 3.4c-.7.3-1.2 1-1.2 1.8v20.7c0 .7.7 1.1 1.3.9zm21.7-22.9l-11.5-3.8v23l11.5 3.8v-23z"
        style={pathStyle}
      />
    </svg>
  )
}

function ContentSliderIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0v64h192V0H0z M191,63H1V1h190V63z M108.4,33l-7.4-7l-9,9l-2.8-2.8l-5.6,5.6V41h24.8C108.4,41,108.4,33,108.4,33z M87,29.8c2,0,3.4-1.5,3.4-3.3S89,23,87,23s-3.4,1.5-3.4,3.4S85.1,29.8,87,29.8z M82,45.6h28c1.6,0,3-1.3,3-2.8V21.3c0-1.5-1.4-2.8-3-2.8H82c-1.6,0-3,1.3-3,2.8v21.4c0,1.5,1.4,2.8,3,2.8V45.6z M81.4,21.4c0-0.3,0.2-0.5,0.5-0.5h28c0.4,0,0.6,0.2,0.6,0.5v21.4c0,0.3-0.2,0.5-0.5,0.5H82c-0.4,0-0.6-0.2-0.6-0.5V21.4L81.4,21.4z M26.5,37.3L21.3,32l5.2-5.2l-1-1L19.3,32l6.2,6.2L26.5,37.3L26.5,37.3z M166.5,38.3l6.2-6.2l-6.2-6.2l-1,1l5.2,5.2l-5.2,5.2L166.5,38.3z"
        style={pathStyle}
      />
    </svg>
  )
}

function ProductSliderIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0v64h192V0H0z M191,63H1V1h190V63z M26.5,37.3L21.3,32l5.2-5.2l-1-1L19.3,32l6.2,6.2L26.5,37.3z M166.5,38.3l6.2-6.2 l-6.2-6.2l-1,1l5.2,5.2l-5.2,5.2L166.5,38.3z M109.1,27.4c-0.5-0.2-4.1-1.6-4.1-1.6l-4,3.3l-4-3.3c0,0-3.6,1.3-4.1,1.6 c-0.5,0.2-3.8,10.5-3.8,10.5s-1.7,8.2-1.3,9.3c0.4,1,2.6,0,2.6,0l4-13.1l0.6,0.1l-0.7,14.1c0,0,0.8,1,6.6,1c5.7,0,7-1,7-1l-1.2-14 l0.7-0.1l4.1,13c0,0,2.2,0.8,2.6-0.3c0.4-1-1.2-8.6-1.2-8.6S109.6,27.6,109.1,27.4z M75.6,27.3c-0.5-0.2-4.1-1.6-4.1-1.6s-0.8,2-4,2 s-4-2-4-2s-3.6,1.3-4.1,1.6c-0.5,0.2-4,4.2-4,4.2s0,0.4,0.4,1.5c0.4,1,2.1,3.5,2.1,3.5l3-2.4l-0.5,14.1c0,0,1.3,1,7,1s7-1,7-1L74,34 l3,2.4c0,0,1.7-2.5,2.1-3.5c0.4-1,0.4-1.5,0.4-1.5S76.1,27.5,75.6,27.3z M137.2,41l-1.1-8.4c0,0,2.6-5.9,1.6-6.2 c-1.1-0.4-2.1-0.7-2.1-0.7s-0.8,5.6-4.2,5.6c-3.3,0-4.2-5.6-4.2-5.6s-1,0.4-2,0.7c-1,0.4,1.1,6.3,1.1,6.3l-1.7,8.8l-0.6,6.6 c0,0,1.4,1,7.4,1c6,0,7.4-1,7.4-1L137.2,41z M137.8,11.3H56.2v2h81.7V11.3z"
        style={pathStyle}
      />
    </svg>
  )
}
function ContactFormIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0V64H192V0ZM191,63H1V1H191ZM136.85,11.3H55.15v2h81.7Zm-39.09,28L114.5,26.27h-37L94.24,39.34A2.85,2.85,0,0,0,97.76,39.34ZM77.5,29.29V49.38h0L88.69,38Zm37,20.09h0V29.29L103.31,38ZM99.23,41.22a5.25,5.25,0,0,1-6.46,0l-2.19-1.71-9.73,9.87h30.3l-9.73-9.87Z"
        transform="translate(0 0)"
        style={pathStyle}
      />
    </svg>
  )
}

function TextTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,64h91V0H0V64z M1,1h89v62H1V1z M101,0v64h91V0H101z M191,63h-89V1h89V63z M28.5,42.9c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H28.5z M28.5,18.7c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H28.5z M28.5,26.6c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H28.5z M28.5,34.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H28.5z M129.4,42.8c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H129.4z M129.4,18.6c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H129.4z M129.4,26.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H129.4z M129.4,34.4c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H129.4z"
        style={pathStyle}
      />
    </svg>
  )
}

function TextTextTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,64h57.7V0H0V64z M1,1h55.7v62H1V1z M134.3,0v64H192V0H134.3z M191,63h-55.7V1H191V63z M67.7,64h56.6V0H67.7V64z M68.7,1h54.6v62H68.7V1z M11.8,42.8c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H11.8z M11.8,18.6c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H11.8z M11.8,26.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H11.8z M11.8,34.4c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H11.8z M78.8,42.8c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H78.8z M78.8,18.6c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H78.8z M78.8,26.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H78.8z M78.8,34.4c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H78.8z M146.2,42.8c0,0.9,0,1.8,0,2.7H168c0-0.9,0-1.6,0-2.7H146.2z M146.2,18.6c0,0.9,0,1.8,0,2.7H168c0-0.9,0-1.6,0-2.7H146.2z M146.2,26.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H146.2z M146.2,34.4c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H146.2z"
        style={pathStyle}
      />
    </svg>
  )
}

function ImageImageIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,64h91V0H0V64z M1,1h89v62H1V1z M101,0v64h91V0H101z M191,63h-89V1h89V63z M60,18.5H32c-1.6,0-3,1.3-3,2.8v21.4c0,1.5,1.4,2.8,3,2.8h28c1.6,0,3-1.3,3-2.8V21.3C63,19.8,61.6,18.5,60,18.5z M60.6,42.7c0,0.3-0.2,0.5-0.5,0.5H32c-0.4,0-0.6-0.2-0.6-0.5V21.3c0-0.3,0.2-0.5,0.5-0.5h28c0.4,0,0.6,0.2,0.6,0.5L60.6,42.7L60.6,42.7z M37,29.7c2,0,3.4-1.5,3.4-3.3S39,23,37,23s-3.4,1.5-3.4,3.4S35.1,29.8,37,29.7L37,29.7z M42,34.7L39.3,32l-5.6,5.6V41h24.8v-8L51,26l-9,9V34.7z M132,45.5h28c1.6,0,3-1.3,3-2.8V21.3c0-1.5-1.4-2.8-3-2.8h-28c-1.6,0-3,1.3-3,2.8v21.4C129,44.2,130.4,45.5,132,45.5z M131.4,21.3c0-0.3,0.2-0.5,0.5-0.5h28c0.4,0,0.6,0.2,0.6,0.5v21.4c0,0.3-0.2,0.5-0.5,0.5h-28c-0.4,0-0.6-0.2-0.6-0.5V21.3z M137,29.7c2,0,3.4-1.5,3.4-3.3S139,23,137,23s-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4V29.7z M158.4,33.1L151,26l-9,9l-2.8-2.8l-5.6,5.6V41h24.8V33.1L158.4,33.1z"
        style={pathStyle}
      />
    </svg>
  )
}

function ImageImageImageIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M68.7,64h54.6V0H68.7V64z M69.7,1h52.6v62H69.7V1z M136.3,0v64H192V0H136.3L136.3,0z M191,63h-53.7V1H191V63zM0,64h55.7V0H0V64z M1,1h53.7v62H1V1z M40.7,19.7H15.2c-1.6,0-2.7,1.2-2.7,2.6v19.5c0,1.4,1.2,2.6,2.7,2.6h25.5c1.4,0,2.6-1.2,2.6-2.6V22.2C43.3,20.9,42.1,19.7,40.7,19.7z M41.3,41.8c0,0.3-0.3,0.5-0.5,0.5H15.2c-0.4,0-0.6-0.2-0.6-0.5V22.2c0-0.3,0.3-0.5,0.5-0.5h25.5c0.4,0,0.6,0.2,0.6,0.5C41.3,22.2,41.3,41.8,41.3,41.8z M19.6,29.9c1.8,0,3.1-1.4,3.1-3s-1.4-3.1-3.1-3.1s-3,1.4-3,3.1C16.6,28.6,18,30,19.6,29.9L19.6,29.9z M24.2,34.5L21.8,32l-5.2,5.1v3.1h22.5v-7.3l-6.7-6.4l-8.2,8.2L24.2,34.5z M149.8,44.3h25.5c1.6,0,2.7-1.2,2.7-2.6V22.2c0-1.4-1.2-2.6-2.7-2.6h-25.5c-1.4,0-2.6,1.2-2.6,2.6v19.5C147.3,43.1,148.5,44.3,149.8,44.3z M149.3,22.2c0-0.3,0.3-0.5,0.5-0.5h25.5c0.4,0,0.6,0.2,0.6,0.5v19.5c0,0.3-0.3,0.5-0.5,0.5h-25.5c-0.4,0-0.6-0.2-0.6-0.5V22.2z M173.9,32.9l-6.7-6.6l-8.2,8.2l-2.5-2.6l-5.2,5.1v3.1h22.5V32.9z M154.5,29.9c1.6,0,3-1.4,3-3s-1.4-3.1-3-3.1c-1.6,0-3.1,1.4-3.1,3.1C151.4,28.6,152.8,30,154.5,29.9L154.5,29.9z M87.8,29.9c1.8,0,3.1-1.4,3.1-3s-1.3-3.1-3.1-3.1c-1.8,0-3.1,1.4-3.1,3.1S86.1,30,87.8,29.9L87.8,29.9z M108.7,19.8H83.3c-1.5,0-2.7,1.2-2.7,2.5v19.4c0,1.4,1.3,2.5,2.7,2.5h25.4c1.5,0,2.7-1.2,2.7-2.5V22.3C111.4,20.9,110.1,19.8,108.7,19.8z M109.2,41.7c0,0.3-0.2,0.5-0.5,0.5H83.3c-0.4,0-0.5-0.2-0.5-0.5V22.3c0-0.3,0.2-0.5,0.5-0.5h25.4c0.4,0,0.5,0.2,0.5,0.5L109.2,41.7L109.2,41.7z M92.5,34.4L89.9,32l-5.1,5.1v3.1h22.5v-7.3l-6.8-6.3l-8.2,8.2L92.5,34.4z"
        style={pathStyle}
      />
    </svg>
  )
}

function ImageTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M31.5,45.4h28.1c1.6,0,3-1.3,3-2.8V21.3c0-1.5-1.4-2.8-3-2.8H31.5c-1.6,0-3,1.3-3,2.8v21.4C28.5,44.2,29.9,45.4,31.5,45.4L31.5,45.4z M30.9,21.2c0-0.3,0.2-0.6,0.5-0.6h28.1c0.4,0,0.6,0.3,0.6,0.6v21.4c0,0.3-0.2,0.5-0.5,0.5H31.5c-0.4,0-0.6-0.2-0.6-0.5V21.2L30.9,21.2z M101,0v64h91V0H101z M191,63h-89V1h89V63z M57.9,33l-7.4-7l-9,9l-2.7-2.8l-5.7,5.6V41h24.8L57.9,33L57.9,33z M0,64h91V0H0V64z M1,1h89v62H1V1z M36.5,29.7c2,0,3.4-1.5,3.4-3.3S38.5,23,36.5,23s-3.4,1.5-3.4,3.4S34.6,29.7,36.5,29.7z M129.4,42.9c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H129.4z M129.4,18.7c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H129.4z M129.4,26.6c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H129.4z M129.4,34.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H129.4z"
        style={pathStyle}
      />
    </svg>
  )
}

function TextImageIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M132.5,45.4h28.1c1.6,0,3-1.3,3-2.8V21.3c0-1.5-1.4-2.8-3-2.8h-28.1c-1.6,0-3,1.3-3,2.8v21.4C129.5,44.2,130.9,45.4,132.5,45.4L132.5,45.4z M131.9,21.2c0-0.3,0.2-0.6,0.5-0.6h28.1c0.4,0,0.6,0.3,0.6,0.6v21.4c0,0.3-0.2,0.5-0.5,0.5h-28.1c-0.4,0-0.6-0.2-0.6-0.5V21.2L131.9,21.2z M101,0v64h91V0H101z M191,63h-89V1h89V63z M158.9,33l-7.4-7l-9,9l-2.7-2.8l-5.7,5.6V41h24.8V33L158.9,33z M0,64h91V0H0V64z M1,1h89v62H1V1z M137.5,29.7c2,0,3.4-1.5,3.4-3.3s-1.4-3.4-3.4-3.4s-3.4,1.5-3.4,3.4S135.6,29.7,137.5,29.7z M28.5,42.9c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H28.5z M28.5,18.7c0,0.9,0,1.8,0,2.7h21.8c0-0.9,0-1.6,0-2.7H28.5z M28.5,26.6c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H28.5z M28.5,34.5c0,0.9,0,1.8,0,2.7h34.1c0-0.9,0-1.6,0-2.7H28.5z"
        style={pathStyle}
      />
    </svg>
  )
}

function ThreeImagesWithTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M67.7,64h56.6V0H67.7V64z M68.7,1h54.6v62H68.7V1z M0,64h57.7V0H0V64z M1,1h55.7v62H1V1z M134.3,0v64H192V0H134.3z M191,63h-55.7V1H191V63z M25.4,24.6l-2.6-2.5l-5.1,5v3.2h22.5V23l-6.7-6.4l-8.2,8.2V24.6z M41.9,9.7H16.3c-1.6,0-2.7,1.2-2.7,2.7v19.3c0,1.5,1.2,2.6,2.7,2.6h25.5c1.4,0,2.6-1.1,2.6-2.6V12.2C44.4,10.9,43.2,9.7,41.9,9.7z M42.4,31.8c0,0.3-0.3,0.5-0.5,0.5H16.3c-0.4,0-0.6-0.3-0.6-0.5V12.2c0-0.3,0.3-0.5,0.5-0.5h25.5c0.4,0,0.6,0.3,0.6,0.5C42.4,12.3,42.4,31.8,42.4,31.8z M20.8,19.9c1.8,0,3.1-1.3,3.1-3c0-1.7-1.4-3-3.1-3s-3,1.2-3,2.9S19.1,19.9,20.8,19.9z M12.8,40.7c0,0.7,0,1.3,0,2h32.1c0-0.7,0-1.2,0-2H12.8z M12.8,52.8c0,0.7,0,1.3,0,2h22c0-0.7,0-1.2,0-2H12.8z M12.8,46.9c0,0.7,0,1.3,0,2h32.4c0-0.7,0-1.2,0-2H12.8z M92.4,24.6l-2.6-2.5l-5.1,5v3.2h22.5V23l-6.7-6.4l-8.2,8.2C92.4,24.8,92.4,24.6,92.4,24.6z M108.9,9.7H83.3c-1.6,0-2.7,1.2-2.7,2.7v19.3c0,1.5,1.2,2.6,2.7,2.6h25.5c1.4,0,2.6-1.1,2.6-2.6V12.2C111.4,10.9,110.2,9.7,108.9,9.7z M109.4,31.8c0,0.3-0.3,0.5-0.5,0.5H83.3c-0.4,0-0.6-0.3-0.6-0.5V12.2c0-0.3,0.3-0.5,0.5-0.5h25.5c0.4,0,0.6,0.3,0.6,0.5C109.4,12.3,109.4,31.8,109.4,31.8z M87.8,19.9c1.8,0,3.1-1.3,3.1-3c0-1.7-1.4-3-3.1-3c-1.7,0-3,1.2-3,2.9S86.1,19.9,87.8,19.9zM79.9,40.5c0,0.7,0,1.3,0,2h31.8c0-0.7,0-1.2,0-2H79.9z M79.9,52.6c0,0.7,0,1.3,0,2h21.8c0-0.7,0-1.2,0-2H79.9z M79.9,46.7c0,0.7,0,1.3,0,2H112c0-0.7,0-1.2,0-2H79.9z M159.1,24.6l-2.6-2.5l-5.1,5v3.2H174V23l-6.7-6.4l-8.2,8.2L159.1,24.6L159.1,24.6zM175.5,9.7H150c-1.6,0-2.7,1.2-2.7,2.7v19.3c0,1.5,1.2,2.6,2.7,2.6h25.5c1.4,0,2.6-1.1,2.6-2.6V12.2C178.1,10.9,176.9,9.7,175.5,9.7z M176.1,31.8c0,0.3-0.3,0.5-0.5,0.5H150c-0.4,0-0.6-0.3-0.6-0.5V12.2c0-0.3,0.3-0.5,0.5-0.5h25.5c0.4,0,0.6,0.3,0.6,0.5C176.1,12.3,176.1,31.8,176.1,31.8z M154.5,19.9c1.8,0,3.1-1.3,3.1-3c0-1.7-1.4-3-3.1-3s-3,1.2-3,2.9S152.8,19.9,154.5,19.9z M147.1,40.5c0,0.7,0,1.3,0,2h30.7c0-0.7,0-1.2,0-2H147.1z M147.1,52.6c0,0.7,0,1.3,0,2h21c0-0.7,0-1.2,0-2H147.1z M147.1,46.7c0,0.7,0,1.3,0,2h31c0-0.7,0-1.2,0-2H147.1z"
        style={pathStyle}
      />
    </svg>
  )
}

function GmapsTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M151.2 18.7h-21.8v2.7h21.8v-2.7zM0 64h91V0H0v64zM1 1h89v62H1V1zm162.5 25.6h-34.1v2.7h34.1v-2.7zm-12.3 16.3h-21.8v2.7h21.8v-2.7zM101 0v64h91V0h-91zm90 63h-89V1h89v62zm-27.5-28.5h-34.1v2.7h34.1v-2.7zM39.9 41.6l11.5 3.8v-23l-11.5-3.8v23zM28.4 23.7v20.7c0 .7.7 1.1 1.3.9l8.3-3.8v-23L29.6 22c-.7.3-1.2 1-1.2 1.7zm33.2-5l-8.3 3.8v23l8.4-3.4c.7-.3 1.2-1 1.2-1.8V19.6c0-.7-.7-1.2-1.3-.9z"
        style={pathStyle}
      />
    </svg>
  )
}

function TextGmapsIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M28.5 29.3h34.1v-2.7H28.5v2.7zm0 16.3h21.8v-2.7H28.5v2.7zm0-8.4h34.1v-2.7H28.5v2.7zm21.8-18.5H28.5v2.7h21.8v-2.7zM0 64h91V0H0v64zM1 1h89v62H1V1zm100-1v64h91V0h-91zm90 63h-89V1h89v62zm-60.4-17.6l8.3-3.8v-23l-8.4 3.4c-.7.3-1.2 1-1.2 1.8v20.7c0 .7.6 1.2 1.3.9zm21.6-22.9l-11.5-3.8v23l11.5 3.8v-23zm11.5 17.9V19.6c0-.7-.7-1.1-1.3-.9l-8.3 3.8v23l8.4-3.4c.8-.2 1.2-.9 1.2-1.7z"
        style={pathStyle}
      />
    </svg>
  )
}

function gmapsContactFormIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M39.9,41.6l11.5,3.8v-23l-11.5-3.8V41.6z M101,0v64h91V0H101z M191,63h-89V1h89V63z M0,64h91V0H0V64z M1,1h89
        v62H1V1z M61.6,18.7l-8.3,3.8v23l8.4-3.4c0.7-0.3,1.2-1,1.2-1.8V19.6C62.9,18.9,62.2,18.4,61.6,18.7z M28.4,23.7v20.7
        c0,0.7,0.7,1.1,1.3,0.9l8.3-3.8v-23L29.6,22C28.9,22.3,28.4,23,28.4,23.7z M149.2,39c-1.6,1.2-3.8,1.2-5.3,0l-1.8-1.4l-8,8.1H159
        l-8-8.1L149.2,39z M131.3,29.2v16.5l9.2-9.4L131.3,29.2z M145.1,37.4c0.8,0.7,2,0.7,2.9,0l13.8-10.7h-30.4L145.1,37.4L145.1,37.4z
        M161.7,45.7V29.2l-9.2,7.2L161.7,45.7z M161.7,18.8h-30.4v2h30.4V18.8z"
        style={pathStyle}
      />
    </svg>
  )
}

function contactFormTextIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M163.5,34.5h-34.1v2.7h34.1V34.5z M0,64h91V0H0V64z M1,1h89v62H1V1z M151.2,42.9h-21.8v2.7h21.8V42.9z M101,0
        v64h91V0H101z M191,63h-89V1h89V63z M163.5,26.6h-34.1v2.7h34.1V26.6z M151.2,18.7h-21.8v2.7h21.8V18.7z M30.3,29.2v16.5l9.2-9.4
        L30.3,29.2z M30.3,20.8h30.4v-2H30.3V20.8z M44.1,37.4L44.1,37.4c0.8,0.7,2,0.7,2.9,0l13.8-10.7H30.3L44.1,37.4z M48.2,39
        c-1.6,1.2-3.8,1.2-5.3,0L41,37.6l-8,8.1H58l-8-8.1L48.2,39z M60.7,45.7V29.2l-9.2,7.2L60.7,45.7z"
        style={pathStyle}
      />
    </svg>
  )
}

function FreeHtmlIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0v64h192V0H0z M191,63H1V1h190V63z M101.2,41.7l1,1.1c0.2,0.2,0.5,0.2,0.7,0l10-9.3c0.1-0.1,0.2-0.2,0.2-0.4
	c0-0.1,0-0.3-0.1-0.4l-9.3-10c-0.2-0.2-0.5-0.2-0.7,0l-1.1,1c-0.2,0.2-0.2,0.5,0,0.7l8,8.6l-8.6,8C101.1,41.2,101,41.5,101.2,41.7z
	 M88.3,45.5l1.4,0.5c0.3,0.1,0.5-0.1,0.6-0.3l8.4-25.7c0.1-0.3-0.1-0.5-0.3-0.6l-1.4-0.5c-0.3-0.1-0.5,0.1-0.6,0.3L88,44.9
	C87.9,45.1,88.1,45.4,88.3,45.5z M83.5,42.1c0.2,0.2,0.5,0.2,0.7,0l1.1-1c0.2-0.2,0.2-0.5,0-0.7l-8-8.6l8.6-8c0.2-0.2,0.2-0.5,0-0.7
	l-1-1.1c-0.2-0.2-0.5-0.2-0.7,0l-10,9.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.3,0.1,0.4L83.5,42.1z"
        style={pathStyle}
      />
    </svg>
  )
}

function DividerIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0v64h192V0H0z M191,63H1V1h190V63z M79.4,30.6c0,0.9,0,1.8,0,2.7h34.2c0-0.9,0-1.6,0-2.7H79.4z M100.2,25.5
        c0,0.2-0.2,0.4-0.4,0.4h-6.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.2,0.1-0.3l3.1-3.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
        l3.1,3.1C100.2,25.3,100.2,25.4,100.2,25.5z M93.1,38.5c0-0.2,0.2-0.4,0.4-0.4h6.2c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3
        l-3.1,3.1C96.8,42,96.7,42,96.6,42c-0.1,0-0.2,0-0.3-0.1l-3-3.1C93.2,38.7,93.1,38.6,93.1,38.5z"
        style={pathStyle}
      />
    </svg>
  )
}

function ImageGalleryIcon(): React.ReactElement {
  return (
    <svg width="192" height="64" viewBox="0 0 192 64" style={svgStyle}>
      <path
        d="M0,0v64h192V0H0z M191,63H1V1h190V63z M68,21.1c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-0.9-0.5-1.3
s-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.3S67.5,21.1,68,21.1z M65.3,29.5h14.9c0.4,0,0.8-0.1,1.1-0.4
c0.3-0.3,0.4-0.6,0.4-1.1V16.7c0-0.4-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4H65.3c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1V28
c0,0.4,0.1,0.8,0.4,1.1C64.6,29.3,64.9,29.5,65.3,29.5z M65,16.7c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h14.9
c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2V28c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H65.3c-0.1,0-0.2,0-0.2-0.1
C65.1,28.1,65,28.1,65,28V16.7z M79.3,22.9l-3.9-3.9l-4.8,4.8l-1.5-1.5l-3,3v1.8h13.1V22.9z M88.8,29.5h14.9c0.4,0,0.8-0.1,1.1-0.4
c0.3-0.3,0.4-0.6,0.4-1.1V16.7c0-0.4-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4H88.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1V28
c0,0.4,0.1,0.8,0.4,1.1C88.1,29.3,88.4,29.5,88.8,29.5z M88.5,16.7c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h14.9
c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2V28c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H88.8c-0.1,0-0.2,0-0.2-0.1
c-0.1-0.1-0.1-0.1-0.1-0.2V16.7z M91.5,21.1c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5
s-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.3S91,21.1,91.5,21.1z M102.8,22.9L99,19.1l-4.8,4.8l-1.5-1.5l-3,3v1.8h13.1V22.9z
 M115,21.1c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3
s0.2,0.9,0.5,1.3S114.5,21.1,115,21.1z M112.4,29.5h14.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1V16.7c0-0.4-0.1-0.8-0.4-1.1
s-0.6-0.4-1.1-0.4h-14.9c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1V28c0,0.4,0.1,0.8,0.4,1.1S112,29.5,112.4,29.5z
 M112.1,16.7c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h14.9c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2V28
c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-14.9c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2V16.7z M126.4,22.9l-3.9-3.9
l-4.8,4.8l-1.5-1.5l-3,3v1.8h13.1V22.9z M65.3,48.8h14.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1V36c0-0.4-0.1-0.8-0.4-1.1
s-0.6-0.4-1.1-0.4H65.3c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1v11.3c0,0.4,0.1,0.8,0.4,1.1C64.6,48.7,64.9,48.8,65.3,48.8
z M65,36c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h14.9c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v11.3c0,0.1,0,0.2-0.1,0.2
c-0.1,0.1-0.1,0.1-0.2,0.1H65.3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2V36z M68,40.5c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3
s-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.3S67.5,40.5,68,40.5z M79.3,42.3l-3.9-3.9
l-4.8,4.8l-1.5-1.5l-3,3v1.8h13.1V42.3z M88.8,48.8h14.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1V36c0-0.4-0.1-0.8-0.4-1.1
s-0.6-0.4-1.1-0.4H88.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1v11.3c0,0.4,0.1,0.8,0.4,1.1C88.1,48.7,88.4,48.8,88.8,48.8
z M88.5,36c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h14.9c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v11.3
c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H88.8c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2V36z M91.5,40.5
c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.3
S91,40.5,91.5,40.5z M102.8,42.3L99,38.4l-4.8,4.8l-1.5-1.5l-3,3v1.8h13.1V42.3z M115,40.5c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3
s-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,0.9,0.5,1.3S114.5,40.5,115,40.5z M112.4,48.8h14.9
c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1V36c0-0.4-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4h-14.9c-0.4,0-0.8,0.1-1.1,0.4
c-0.3,0.3-0.4,0.6-0.4,1.1v11.3c0,0.4,0.1,0.8,0.4,1.1S112,48.8,112.4,48.8z M112.1,36c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1
h14.9c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v11.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-14.9c-0.1,0-0.2,0-0.2-0.1
c-0.1-0.1-0.1-0.1-0.1-0.2V36z M126.4,42.3l-3.9-3.9l-4.8,4.8l-1.5-1.5l-3,3v1.8h13.1V42.3z"
        style={pathStyle}
      />
    </svg>
  )
}

type BlockElement = {
  type: string
  data: {
    [key: string]: any
  }
}
const emptyText: BlockElement = {
  type: 'epages.text',
  data: {
    content: '',
  },
}
const emptyImage: BlockElement = {
  type: 'epages.image',
  data: {
    src: '',
    alt: '',
    link: '',
    opentab: false,
    text: '',
    buttontext: '',
    headline: '',
    buttonenabled: false,
  },
}
const emptyVideo: BlockElement = {
  type: 'epages.video',
  data: {
    src: '',
  },
}
const emptyContentSlider: BlockElement = {
  type: 'epages.contentslider',
  data: {
    images: [],
    autoPlay: true,
    showNav: false,
    showThumbnails: false,
    slideInterval: 5000,
    showBullets: true,
  },
}

const emptyGoogleMaps: BlockElement = {
  type: 'epages.gmaps',
  data: {
    iframe: '',
    src: '',
  },
}

const emptyContactForm: BlockElement = {
  type: 'epages.contactform',
  data: {},
}

const emptyFreeHtml: BlockElement = {
  type: 'epages.html',
  data: {
    html: '',
  },
}

const emptyDivider: BlockElement = {
  type: 'epages.divider',
  data: {
    dividerType: 'solid',
  },
}

const emptyImageGallery: BlockElement = {
  type: 'epages.imagegallery',
  data: {
    src: '',
    alt: '',
  },
}

export const generateCategoryBlock = (categoryId = ''): ImmutableMap =>
  Immutable.fromJS({
    _id: generateUUID(),
    type: 'multi-column',
    data: {
      columns: [
        {
          type: 'ng.category',
          data: {
            categoryId: categoryId,
            pageSize: 12,
          },
        },
      ],
    },
  })

export type BlockCategories =
  | 'text'
  | 'image'
  | 'product'
  | 'html'
  | 'video'
  | 'maps'
  | 'gallery'
  | 'contact'
  | 'divider'

export type BlockTemplateType = {
  label: string
  icon: React.FunctionComponent
  hideFor: ('unity' | 'beyond')[]
  categories: BlockCategories[]
  generate: () => ImmutableMap
  canBeAdded?: (blocks: Immutable.List<ImmutableMap>[]) => boolean
}
const blockTemplates: BlockTemplateType[] = [
  {
    label: 'ng.category',
    icon: CollectionIcon,
    hideFor: ['unity'],
    categories: ['product'],
    generate: () => generateCategoryBlock(),
    // the block can not be added when there is another ng.category already available somewhere in the blocks
    canBeAdded: (blocks = []) =>
      !blocks.find((block) =>
        block.getIn(['data', 'columns'], []).find((column: ImmutableMap) => column.get('type') === 'ng.category'),
      ),
  },
  {
    label: 'productSlider',
    hideFor: [],
    categories: ['product'],
    icon: ProductSliderIcon,
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'headline-block',
        data: {
          headline: emptyText,
          columns: [
            {
              type: 'epages.productslider',
              data: {
                productIds: [],
                id: generateUUID(),
              },
            },
          ],
        },
      }),
  },
  {
    label: 'singleColumnText',
    hideFor: [],
    categories: ['text'],
    icon: TextIcon,
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyText] },
      }),
  },
  {
    label: 'singleColumnImage',
    icon: ImageIcon,
    hideFor: [],
    categories: ['image'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyImage] },
      }),
  },
  {
    label: 'video',
    icon: VideoIcon,
    hideFor: [],
    categories: ['video'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyVideo] },
      }),
  },
  {
    label: 'textAndVideo',
    icon: TextVideoIcon,
    hideFor: [],
    categories: ['video'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyText, emptyVideo] },
      }),
  },
  {
    label: 'videoAndText',
    icon: VideoTextIcon,
    hideFor: [],
    categories: ['video'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyVideo, emptyText] },
      }),
  },
  {
    label: 'threeColumnVideoWithText',
    icon: ThreeVideosWithTextIcon,
    hideFor: [],
    categories: ['video', 'text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'video-with-text',
        data: {
          columns: [
            { video: emptyVideo, text: emptyText },
            { video: emptyVideo, text: emptyText },
            { video: emptyVideo, text: emptyText },
          ],
        },
      }),
  },
  {
    label: 'gmaps',
    icon: GmapsIcon,
    hideFor: [],
    categories: ['maps'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyGoogleMaps] },
      }),
  },
  {
    label: 'contactForm',
    hideFor: [],
    categories: ['contact'],
    icon: ContactFormIcon,
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'headline-block',
        data: {
          headline: emptyText,
          columns: [emptyContactForm],
        },
      }),
  },
  {
    label: 'twoColumnText',
    icon: TextTextIcon,
    categories: ['text'],
    hideFor: [],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyText, emptyText] },
      }),
  },
  {
    label: 'threeColumnText',
    icon: TextTextTextIcon,
    hideFor: [],
    categories: ['text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyText, emptyText, emptyText] },
      }),
  },
  {
    label: 'twoColumnImage',
    icon: ImageImageIcon,
    hideFor: [],
    categories: ['image'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyImage, emptyImage] },
      }),
  },
  {
    label: 'threeColumnImage',
    icon: ImageImageImageIcon,
    hideFor: [],
    categories: ['image'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyImage, emptyImage, emptyImage] },
      }),
  },
  {
    label: 'imageAndText',
    icon: ImageTextIcon,
    hideFor: [],
    categories: ['image', 'text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyImage, emptyText] },
      }),
  },
  {
    label: 'textAndImage',
    icon: TextImageIcon,
    hideFor: [],
    categories: ['image', 'text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyText, emptyImage] },
      }),
  },
  {
    label: 'threeColumnImageWithText',
    icon: ThreeImagesWithTextIcon,
    hideFor: [],
    categories: ['image', 'text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'image-with-text',
        data: {
          columns: [
            { image: emptyImage, text: emptyText },
            { image: emptyImage, text: emptyText },
            { image: emptyImage, text: emptyText },
          ],
        },
      }),
  },
  {
    label: 'gmapsAndText',
    icon: GmapsTextIcon,
    hideFor: [],
    categories: ['maps', 'text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyGoogleMaps, emptyText] },
      }),
  },
  {
    label: 'textAndGmaps',
    icon: TextGmapsIcon,
    hideFor: [],
    categories: ['maps', 'text'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyText, emptyGoogleMaps] },
      }),
  },
  {
    label: 'gmapsAndContactForm',
    icon: gmapsContactFormIcon,
    hideFor: [],
    categories: ['maps', 'contact'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: {
          columns: [
            emptyGoogleMaps,
            {
              type: 'nested-headline-block',
              data: {
                headline: emptyText,
                columns: [emptyContactForm],
              },
            },
          ],
        },
      }),
  },
  {
    label: 'contactFormAndText',
    icon: contactFormTextIcon,
    hideFor: [],
    categories: ['text', 'contact'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: {
          columns: [
            {
              type: 'nested-headline-block',
              data: {
                headline: emptyText,
                columns: [emptyContactForm],
              },
            },
            emptyText,
          ],
        },
      }),
  },
  {
    label: 'freeHtmlContent',
    icon: FreeHtmlIcon,
    hideFor: [],
    categories: ['html'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyFreeHtml] },
      }),
  },
  {
    label: 'divider',
    icon: DividerIcon,
    hideFor: [],
    categories: ['divider'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyDivider] },
      }),
  },
  {
    label: 'imageSlider',
    icon: ContentSliderIcon,
    hideFor: [],
    categories: ['image', 'gallery'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyContentSlider] },
      }),
  },
  {
    label: 'imageGallery',
    icon: ImageGalleryIcon,
    hideFor: showImageGallery ? [] : ['unity', 'beyond'],
    categories: ['image', 'gallery'],
    generate: () =>
      Immutable.fromJS({
        _id: generateUUID(),
        type: 'multi-column',
        data: { columns: [emptyImageGallery] },
      }),
  },
] as BlockTemplateType[]

export default blockTemplates
