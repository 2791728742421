import { debounce, noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import Swiper from './templateComponents/Workspace/plugins/contentslider/Swiper'

/**
 * check if this is an initial page request (page = 1)
 * @return {boolean} true, if initial page load
 */
export const isInitialPageLoad = (pagerOptions) => !pagerOptions || !pagerOptions.page || pagerOptions.page === 1

/**
 * get all products for a swiper group
 * @param {array} productList the product objects list
 * @param {number} swiperLength the number of products to display per group
 * @param {number} groupNumber the page that needs to be checked
 * @return {array} return array of products for a swiper group
 */
export const getProductsForSwiperGroup = (productList, swiperLength, groupNumber) => {
  // check if product list object exists
  if (!productList) {
    return []
  }

  // check if the groupNumber is out of bound
  const lastElementOfPreviousGroupIndex = (groupNumber - 1) * swiperLength
  if (lastElementOfPreviousGroupIndex >= productList.totalNumberOfProducts) {
    return []
  }

  const lastProductIndex = groupNumber * swiperLength - 1
  const firstProductIndex = (groupNumber - 1) * swiperLength
  const realLastProductIndex =
    lastProductIndex < productList.totalNumberOfProducts ? lastProductIndex : productList.totalNumberOfProducts - 1

  const swiperGroupProducts = productList.products.slice(firstProductIndex, realLastProductIndex + 1)

  return swiperGroupProducts
}

/**
 * check if all products for a certain swiper group are already loaded
 * @param {array} productList the product objects list
 * @param {number} swiperLength the number of products to display per group
 * @param {number} groupNumber the page that needs to be checked
 * @return {boolean} return true if the products are already loaded
 */
export const areProductsLoadedForSwiperGroup = (productList, swiperLength, groupNumber) => {
  // check if product list object exists
  if (!productList) {
    return false
  }

  // check if the groupNumber is out of bound
  const lastElementOfPreviousGroupIndex = (groupNumber - 1) * swiperLength
  if (lastElementOfPreviousGroupIndex >= productList.totalNumberOfProducts) {
    return true
  }
  const swiperGroupProducts = getProductsForSwiperGroup(productList, swiperLength, groupNumber)
  return swiperGroupProducts.every((product) => product.isLoaded)
}

export default class ProductListSwiper extends React.Component {
  static propTypes = {
    renderProductItem: PropTypes.func.isRequired,
    renderEmptyProductItem: PropTypes.func.isRequired,
    productList: PropTypes.object,
    loadProducts: PropTypes.func.isRequired,
    uniqueId: PropTypes.string.isRequired,
    breakpoints: PropTypes.array,
    trackProductList: PropTypes.func,
  }

  static defaultProps = { trackProductList: noop }

  state = {
    swiperLength: 2,
    error: false,
  }

  nextButtonRef = React.createRef()

  prevButtonRef = React.createRef()

  swiperContainerRef = React.createRef()

  /**
   * get slide count from screen size
   * @return {number} count of slides to render
   */
  calculateSlideCount() {
    const breakpoints = this.props.breakpoints || ['480px', '768px', '992px']

    // get first matching query
    const firstMatchIndex = breakpoints.findIndex(
      (breakpoint) => window.matchMedia(`(max-width: ${breakpoint})`).matches,
    )

    // we want at least 2 images, so up to first match  display 2  (2 + 0), afterwards position + 2
    // if nothing matches fallback to one more image than last breakpoint to avoid uncovered extremly large resolutions
    return firstMatchIndex !== -1 ? firstMatchIndex + 2 : breakpoints.length + 2
  }

  /**
   * check if the products for a certain swipter group are already loaded
   * @param {number} groupNumber the page that needs to be checked
   * @return {boolean} true if the products are already loaded
   */

  areProductsLoadedForSwiperGroup(groupNumber) {
    const { productList } = this.props
    const { swiperLength } = this.state

    return areProductsLoadedForSwiperGroup(productList, swiperLength, groupNumber)
  }

  componentDidMount() {
    const { loadProducts, trackProductList, productList } = this.props

    this.setState({ swiperLength: this.calculateSlideCount() }, () => {
      const { swiperLength } = this.state
      // if products are not yet loaded, load them
      if (!this.areProductsLoadedForSwiperGroup(1)) {
        // catch error and hide for initial load, as we do not know how many slides we should render
        // and without rendering a second slide it is impossible to recover from the error with handleNextSlide()
        loadProducts({ size: swiperLength * 2 })
          // we need to use this.props.trackProductList and this.props.productList in order to get the updated versions
          // from after the rerender due to the loaded products
          .then(() => this.props.trackProductList(getProductsForSwiperGroup(this.props.productList, swiperLength, 1)))
          .catch(() => this.setState({ error: true }))
      } else {
        trackProductList(getProductsForSwiperGroup(productList, swiperLength, 1))
      }
    })

    this.handleResize = this.handleResize.bind(this)

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadProducts, productList, uniqueId, trackProductList } = this.props
    const { swiperLength } = this.state
    // trigger load products during page navigation if not preloaded
    if (uniqueId !== nextProps.uniqueId) {
      if (!areProductsLoadedForSwiperGroup(nextProps.productList, swiperLength, 1)) {
        // catch error and hide for initial load, as we do not know how many slides we should render
        // and without rendering a second slide it is impossible to recover from the error with handleNextSlide()
        loadProducts({ size: swiperLength * 2 })
          // we need to use this.props.trackProductList and this.props.productList in order to get the updated versions
          // from after the rerender due to the loaded products
          .then(() => this.props.trackProductList(getProductsForSwiperGroup(this.props.productList, swiperLength, 1)))
          .catch(() => this.setState({ error: true }))
      } else {
        trackProductList(getProductsForSwiperGroup(productList, swiperLength, 1))
      }
    }
  }

  handleResize = debounce(() => this.setState({ swiperLength: this.calculateSlideCount() }), 300, {
    leading: true,
    trailing: true,
  })

  handleNextSlide() {
    let currentSlidesGroup = 1
    const { trackProductList, productList, loadProducts } = this.props
    const { swiperLength } = this.state

    return (activeSlideIndex) => {
      // calculate the slides group for this event
      const newSlidesGroup = Math.ceil((activeSlideIndex + 1) / swiperLength)

      // if the slides group changes, check if all images are loaded
      if (currentSlidesGroup !== newSlidesGroup) {
        // always check current slides group, on mobile its possible to skip pages, so they might be missing
        if (!this.areProductsLoadedForSwiperGroup(newSlidesGroup)) {
          const oldSlidesGroup = newSlidesGroup
          loadProducts({ size: swiperLength, page: newSlidesGroup })
            .then(() => {
              // check if slide changed while loading the products
              // compare variable current slide to the stored old slide
              if (oldSlidesGroup === currentSlidesGroup) {
                // we need to use this.props.trackProductList and this.props.productList in order to get the updated versions
                // from after the rerender due to the loaded products
                this.props.trackProductList(
                  getProductsForSwiperGroup(this.props.productList, swiperLength, newSlidesGroup),
                  false,
                )
              }
            })
            .catch(noop)
        } else {
          // send tracking data immediately in case product data are already loaded
          trackProductList(getProductsForSwiperGroup(productList, swiperLength, newSlidesGroup), false)
        }

        // preload next slides group
        if (!this.areProductsLoadedForSwiperGroup(newSlidesGroup + 1)) {
          loadProducts({ size: swiperLength, page: newSlidesGroup + 1 }).catch(noop)
        }

        // set the changed slides group after a change was handled ( we only want to trigger the checks above if the group actually changes)
        currentSlidesGroup = newSlidesGroup
      }
    }
  }

  renderSwiper() {
    const { renderProductItem, renderEmptyProductItem } = this.props

    return ({ slides }) => (
      <div className="swiper-container product-item-swiper-container" ref={this.swiperContainerRef} key="container">
        <div className="swiper-wrapper product-item-list column-grid-2 column-grid-3 column-grid-4">
          {slides.map((product, index) =>
            product.isLoaded ? (
              product.isEmpty ? (
                <div
                  key={'emptyProd_' + index}
                  className="swiper-slide product-item-list-swiper-slide product-item-list-items empty-product"
                />
              ) : (
                <div key={product.productId} className="swiper-slide product-item-list-swiper-slide">
                  {renderProductItem(product, index)}
                </div>
              )
            ) : (
              <div key={index} className="swiper-slide product-item-list-swiper-slide product-item-list-swiper-loading">
                {renderEmptyProductItem(index)}
              </div>
            ),
          )}
        </div>

        <div className="swiper-arrows" key="swiper-arrows">
          <div ref={this.nextButtonRef} className="swiper-arrow-right" />
          <div ref={this.prevButtonRef} className="swiper-arrow-left swiper-button-disabled" />
        </div>
      </div>
    )
  }

  render() {
    const { productList } = this.props
    const { swiperLength, error } = this.state
    const { swiperContainerRef, nextButtonRef, prevButtonRef } = this

    // usually the number of products
    // before the first response is back we dont know the  number of products, so we just display one complete set of placeholders
    const slidesCount = productList ? productList.totalNumberOfProducts : swiperLength

    // bailout if product list was loaded, but is empty (usually if the first call to epages 6 failed).
    // if we dont have the first response yet (productList is undefined), we want to display placeholders, so we cannot bail out
    if ((productList && productList.totalNumberOfProducts === 0) || error) {
      return null
    }

    // create array with products at the correct indices
    // there might be empty products in between and it is neccessary to retain the correct positions as we need to render placeholders for the empty products
    // also before the first call we dont have a product list and we need to fill the array with placeholders
    const mappedProductList = Array(slidesCount)
      .fill()
      .map((val, index) => (productList ? productList.products[index] : { isLoaded: false }))

    // if a group is not completly full, add empty slides to fill the group
    // this is neccessary to prevent unpredictable swiper behaviour (slides jumping around)
    const numberOfEmptyGroupSlides = mappedProductList.length % swiperLength
    if (numberOfEmptyGroupSlides > 0) {
      for (let x = 0; x < swiperLength - numberOfEmptyGroupSlides; x++) {
        mappedProductList.push({ isEmpty: true, isLoaded: true })
      }
    }

    return (
      <Swiper
        slides={mappedProductList}
        slidesPerGroup={swiperLength}
        slidesPerView={swiperLength}
        refSet={{ swiperContainerRef, nextButtonRef, prevButtonRef }}
        centeredSlides={false}
        grabCursor={true}
        renderSwiper={this.renderSwiper()}
        spaceBetween={30}
        onActiveIndexChange={this.handleNextSlide()}
        slideToClickedSlide={false}
      />
    )
  }
}
