import Immutable, { List } from 'immutable'

import * as actions from '../actions'
import { extendNavigationWithUrls } from '../../urlGenerators'
import { filterDeep, findDeep, mapDeep, reduceDeep } from '../../utils/immutableTreeHelpers'

function parentsRecursion(nodes: List<ImmutableMap>, parents = Immutable.List()) {
  return nodes
    .map((n: ImmutableMap) => n.set('parents', parents))
    .map((n: ImmutableMap) =>
      n.update('children', (c) => parentsRecursion(c, parents.push(n.delete('children').delete('parents')))),
    )
}

function setNavigation(state: State, nav): State {
  const isOnServer = typeof window === 'undefined'

  // create parents trace
  const withParents = parentsRecursion(Immutable.fromJS(nav))

  const withStartpageLink = extendNavigationWithUrls(withParents).setIn([0, 'href'], '/')

  // add empty children if missing
  const withCorrectFormat = withStartpageLink.setIn(
    [0, 'children'],
    withStartpageLink.getIn([0, 'children']) || Immutable.List(),
  )

  // don't write an editor navigation in storefront mode to save bandwidth and memory
  const forEditor = !state.getIn(['view', 'editorMode'])
    ? null
    : // make the editor navigation appear shallow to speed up server side rendering
    // the storefront part is made shallow after breadcrumb calculation (see breadcrumb reducer)
    isOnServer
    ? hideChildren(withCorrectFormat)
    : withCorrectFormat

  const forStorefront = reduceDeep(withCorrectFormat, (newNav, node) => {
    // filter invisible nodes
    if (!node.get('isVisible')) return newNav

    return newNav.push(
      isOnServer
        ? // remove adminUrl to save bandwidth and memory
          node.delete('adminUrl').update('parents', (parents) => parents.map((parent) => parent.delete('adminUrl')))
        : node,
    )
  })

  return state
    .set('navigation', state.get('navigation') || Immutable.Map())
    .setIn(['navigation', 'editor'], forEditor)
    .setIn(['navigation', 'storefront'], forStorefront)
}

function revealChildren(navigation) {
  return navigation.map((entry) =>
    entry.set('children', entry.get('_children') || entry.get('children')).delete('_children'),
  )
}
export function hideChildren(navigation: List<ImmutableMap>): List<ImmutableMap> {
  return navigation.map((entry: ImmutableMap) =>
    // check for _children since we might run into this twice
    entry.set('_children', entry.get('_children') || entry.get('children')).set('children', Immutable.List()),
  ) as List<ImmutableMap>
}

export default function navigation(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.HYDRATE_NAVIGATION:
      return setNavigation(
        state,
        revealChildren(state.getIn(['navigation', 'editor']) || state.getIn(['navigation', 'storefront']) || []),
      )

    case actions.LOAD_NAVIGATION_SUCCESS:
      return setNavigation(state, action.response)

    case actions.UPDATE_NAVIGATION:
      return setNavigation(state, action.navigation)

    case actions.CREATE_PAGE_SUCCESS:
      if (action.response.navigation === 'main') {
        return setNavigation(
          state,
          state
            .get('navigation')
            .get('editor')
            .push(Immutable.fromJS(action.response).set('children', Immutable.List())),
        )
      }
      return state

    case actions.DELETE_PAGE:
      return setNavigation(
        state.set(
          'footerPages',
          state.get('footerPages').filter((node) => node.get('slug') !== action.pageSlug),
        ),
        reduceDeep(state.get('navigation').get('editor'), (newNav, node) =>
          node.get('slug') === action.pageSlug
            ? // if a page to be deleted has children, re-insert them into the hole left by it
              newNav.concat(node.get('children').map((child) => child.set('parents', Immutable.List())))
            : newNav.push(node),
        ),
      )

    case actions.DELETE_PAGE_SUCCESS:
      return state.deleteIn(['pages', action.pageSlug])

    case actions.UPDATE_PAGE_SETTINGS:
      // eslint-disable-next-line no-case-declarations
      const respectiveMainNode = findDeep(
        state.get('navigation').get('editor'),
        (node) => node.get('id') === action.pageId,
      )

      // eslint-disable-next-line no-case-declarations
      const respectiveFooterNode = findDeep(state.get('footerPages'), (node) => node.get('id') === action.pageId)

      if (respectiveMainNode && action.navigation === 'main') {
        return setNavigation(
          state,
          mapDeep(state.get('navigation').get('editor'), (node) =>
            node.get('id') === action.pageId
              ? node
                  .set('title', action.title)
                  .set('titleTag', action.titleTag)
                  .set('metaDescription', action.metaDescription)
                  .set('slug', action.newPageSlug)
                  .set('isVisible', action.isVisible)
                  .set('navigation', action.navigation)
                  .set('href', `/i/${action.newPageSlug}`)
              : node,
          ),
        )
      }

      if (respectiveMainNode && action.navigation === 'footer') {
        const shallowCopy = respectiveMainNode
          .set('title', action.title)
          .set('titleTag', action.titleTag)
          .set('metaDescription', action.metaDescription)
          .set('slug', action.newPageSlug)
          .set('isVisible', action.isVisible)
          .set('navigation', action.navigation)
          .set('href', `/i/${action.newPageSlug}`)
          .delete('isPreview')
        return setNavigation(
          state,
          filterDeep(state.get('navigation').get('editor'), (node) => node.get('id') !== action.pageId),
        ).set('footerPages', state.get('footerPages').push(shallowCopy))
      }

      if (respectiveFooterNode && action.navigation === 'footer') {
        return state.set(
          'footerPages',
          state
            .get('footerPages')
            .map((node) =>
              node.get('id') === action.pageId
                ? node
                    .set('title', action.title)
                    .set('titleTag', action.titleTag)
                    .set('metaDescription', action.metaDescription)
                    .set('slug', action.newPageSlug)
                    .set('isVisible', action.isVisible)
                    .set('navigation', action.navigation)
                    .set('href', `/i/${action.newPageSlug}`)
                    .delete('isPreview')
                : node,
            ),
        )
      }

      if (respectiveFooterNode && action.navigation === 'main') {
        const shallowCopy = respectiveFooterNode
          .set('title', action.title)
          .set('titleTag', action.titleTag)
          .set('metaDescription', action.metaDescription)
          .set('slug', action.newPageSlug)
          .set('isVisible', action.isVisible)
          .set('navigation', action.navigation)
          .set('href', `/i/${action.newPageSlug}`)

        return setNavigation(
          state.set(
            'footerPages',
            state.get('footerPages').filter((node) => node.get('id') !== action.pageId),
          ),
          state.getIn(['navigation', 'editor']).push(shallowCopy),
        )
      }
      return state

    case actions.UPDATE_CATEGORY_SETTINGS:
      return setNavigation(
        state,
        mapDeep(state.get('navigation').get('editor'), (node) =>
          node.get('categoryId') === action.categoryId
            ? node
                .set('title', action.title)
                .set('titleTag', action.titleTag)
                .set('metaDescription', action.metaDescription)
                .set('slug', action.slug)
                .set('isVisible', action.isVisible)
            : node,
        ),
      )

    case actions.UPDATE_LEGAL_SETTINGS:
      return state.set(
        'legalPages',
        state
          .get('legalPages')
          .map((node) => (node.get('slug') === action.slug ? node.set('isVisible', action.isVisible) : node)),
      )

    case actions.DELETE_PAGE_FAILURE:
    case actions.UPDATE_NAVIGATION_FAILURE:
    case actions.UPDATE_PAGE_SETTINGS_FAILURE:
    case actions.UPDATE_CATEGORY_SETTINGS_FAILURE:
      return state.set('navigation', action.memorized?.navigation)
    case actions.UPDATE_LEGAL_SETTINGS_FAILURE:
      return state.set('legalPages', action.memorized?.legalPages)

    case actions.PREVIEW_FOOTER_PAGES:
      return state.set('footerPages', action.response.footerPages)

    case actions.PREVIEW_LEGAL_PAGES:
      return state.set('legalPages', action.response.legalPages)

    case actions.LOAD_FOOTER_PAGES_SUCCESS:
      return state.set('footerPages', extendNavigationWithUrls(Immutable.fromJS(action.response)))
    case actions.LOAD_LEGAL_PAGES_SUCCESS:
      return state.set('legalPages', extendNavigationWithUrls(Immutable.fromJS(action.response)))

    case actions.UPDATE_FOOTER_NAVIGATION_SUCCESS:
      return state.set('footerPages', extendNavigationWithUrls(Immutable.fromJS(action.response)))

    default:
      return state
  }
}
