import React from 'react'

import Content from '../../templateComponents/Workspace'

export default function Page() {
  return (
    <div className="editorial">
      <div className="section-dali">
        <Content />
      </div>
    </div>
  )
}
