import { AnyAction, Store } from 'redux'
import { RouteComponentProps, browserHistory as history } from 'react-router'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { isEmpty, isEqual } from 'lodash'
import React from 'react'
import buildURL from 'axios/lib/helpers/buildURL'

import {
  NAME_ASC,
  NAME_DESC,
  PRICE_ASC,
  PRICE_DESC,
  RELEVANCE,
  createSortings,
  getDefaultSorting,
  pagingDefaults,
  rangeFacetDelimiter,
} from '../utils/pageAndSort'
import { PageOrCategoryContext } from './Category'
import { SearchDataType } from '../store/reducers/products'
import { generateSearchUrl, withQueryAndScope } from '../urlGenerators'
import { getPlain } from '../store/utils'
import { loadProductsForSearchAsync } from '../store/actions'
import { track } from '../utils/tracking'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'
import withReduxContext from '../utils/withReduxContext'

export const getFilters = (facets?: Core.Facets): { [key: string]: string[] } => {
  const filters = {}

  if (facets) {
    Object.values(facets).forEach((facet) => {
      switch (facet.type) {
        case 'selection':
          Object.values(facet.values).forEach((value) => {
            if (value.selected) {
              if (!Object.keys(filters).includes(facet.id)) {
                filters[facet.id] = [value.value]
              } else {
                filters[facet.id].push(value.value)
              }
            }
          })
          break
        case 'range':
          if (facet.values?.selected) {
            if (!Object.keys(filters).includes(facet.id)) {
              filters[facet.id] = [facet.values.selection.min + rangeFacetDelimiter + facet.values.selection.max]
            } else {
              filters[facet.id].push(facet.values.selection.min + rangeFacetDelimiter + facet.values.selection.max)
            }
          }
          break
      }
    })
  }
  return filters
}

type SearchOptions = {
  q?: string
  sort?: string
  page?: number
}

export const changeSearchQuery = (store: Store<State>, options?: SearchOptions): void => {
  const state = store.getState()
  const location = state.get('location')
  const isBeyond = Boolean(state.getIn(['shop', 'beyond']))
  const defaultSorting = isBeyond ? 'price-asc' : 'relevance'

  const currentOptions: Required<SearchOptions> = {
    q: location.toJS().query.q || '',
    page: 1,
    sort: defaultSorting,
    ...options,
  }

  const facets = getPlain<Core.Facets | undefined>(state.getIn(['searchData', 'facets'], undefined))

  const searchParams = new URLSearchParams()
  searchParams.append('q', currentOptions.q)
  searchParams.append('sort', currentOptions.sort)
  searchParams.append('page', currentOptions.page.toString())
  const searchParamsObj = getFilters(facets)
  Object.entries(searchParamsObj).forEach(([key, value]) => {
    value.forEach((element) => searchParams.append(key, element))
  })

  // replace URL in browser history and load it
  history.replace(buildURL(withQueryAndScope(generateSearchUrl(), location), searchParams))
}

type Props = {
  viewError?: any
  searchData?: State
  category?: State
  t: TFunction
  isBeyond: boolean
  isBusy: boolean
  store: Store<State>
}
type ReactState = {
  isToolbarTopInView: boolean
}
type Query = {
  q: string
  page: string
  sort: string
  // Any facetted search filter
  [key: string]: string
}

type ComponentRouteProps = RouteComponentProps<unknown, unknown, Props, Query>

export class SearchRaw extends React.Component<Props & ComponentRouteProps, ReactState> {
  state = {
    isToolbarTopInView: true,
  }

  lastSearchData: SearchDataType | null = null

  static storeUpdate = (props: ComponentRouteProps, state: State): (AnyAction | GlobalAction)[] => {
    const { searchData } = mapStateToProps(state, props)
    const isForwardNavigation = props.location.action === 'PUSH'
    const componentDidUpdateWillTakeCare = props.location.action === 'REPLACE'

    const updates: (AnyAction | GlobalAction)[] = []
    if ((isForwardNavigation || !searchData) && !componentDidUpdateWillTakeCare) {
      updates.push(loadProductsForSearchAsync())
    }
    return updates
  }

  componentDidMount(): void {
    const { searchData, location } = this.props

    if (searchData) {
      const data: SearchDataType = getPlain<SearchDataType>(searchData)
      this.lastSearchData = data
      track('search', { products: fromJS(data.products), query: location.query })
    }
  }

  componentDidUpdate(prevProps: Props & ComponentRouteProps): void {
    const { searchData, location } = this.props
    const data: SearchDataType | undefined = getPlain<SearchDataType | undefined>(searchData)
    const prevData: SearchDataType | undefined = getPlain<SearchDataType | undefined>(prevProps.searchData)

    // Check for changes in the location query.
    // If something relevant has changed we are not going to append products but load them new.
    // We have to combine all query parameters from current and previews location because
    // some parameters could have been removed, like search facets.
    const didSomethingChange = [...Object.keys(location.query), ...Object.keys(prevProps.location.query)]
      // Changes to `page` mean we do actually want to append products, so we ignore them.
      .filter((key) => key !== 'page')
      .some((key) => !isEqual(location.query[key], prevProps.location.query[key]))

    if (data && !isEqual(data, prevData)) {
      track('search', { products: fromJS(data.products), query: location.query })
    }
    if (location && !isEqual(prevProps.location, location)) {
      const dispatch: any = this.props.store.dispatch
      dispatch(loadProductsForSearchAsync(!didSomethingChange && location.query.page !== prevProps.location.query.page))
    }

    if (prevProps.searchData) {
      this.lastSearchData = getPlain<SearchDataType | undefined>(prevProps.searchData) || null
    }
  }

  static contentCreationDisabled = (): boolean => true

  render(): React.ReactElement {
    const { viewError, t, isBeyond, location, isBusy, store } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="page" />

    const propsSearchData = getPlain<SearchDataType | undefined>(this.props.searchData)

    // Ensure smooth transition: use the last truthy searchData as fallback
    const searchData: SearchDataType =
      propsSearchData ||
      this.lastSearchData ||
      ({
        products: [],
        totalNumberOfProducts: 0,
        query: '',
        sort: isBeyond ? 'price-asc' : 'relevance',
      } as SearchDataType)

    const { q } = location.query
    const queryPage = parseInt(location.query.page)
    const sortingOptions = isBeyond
      ? { ...PRICE_ASC, ...PRICE_DESC }
      : { ...RELEVANCE, ...NAME_ASC, ...NAME_DESC, ...PRICE_ASC, ...PRICE_DESC }
    const sort = searchData.sort

    const sortings = createSortings(sort, sortingOptions)

    const title = q
      ? t('components.productSearchComponent.resultsCard.resultsState.title', { queryString: q })
      : t('components.productSearchComponent.submitButton.label')

    const metaTags = [<meta key="robots" name="robots" content="noindex, follow" />]

    const showLoadMoreButton = searchData.products.length < searchData.totalNumberOfProducts
    const templateName = q && !isEmpty(searchData.facets) ? 'FacetedSearch' : 'Search'

    const trackProductClick = (product: Core.Product, productIndex: number) => {
      track('product:click', { type: 'search', detail: q, product, productIndex })
    }

    return (
      <PageOrCategoryContext.Provider value={this.props.category}>
        <Theme withLayout currentView="search" title={title} titleShouldIncludeShop metaTags={metaTags}>
          {(renderView: any, props: any) =>
            renderView(templateName, {
              ...props,
              searchData: {
                queryString: q,
                ...searchData,
                products: searchData.products.slice(0, (queryPage || 1) * pagingDefaults.resultsPerPage),
                sortings,
                updateSorting: (sorting: string) => {
                  changeSearchQuery(store, { sort: sorting, page: 1, q })
                },
              },
              loadMoreProducts: () => {
                const page = Number(location.query.page) || 1
                changeSearchQuery(store, { sort, page: page + 1, q })
              },
              showLoadMoreButton,
              isBusy,
              showScrollButton: !this.state.isToolbarTopInView,
              onScrollIntoView: (isToolbarTopInView: boolean) => this.setState({ isToolbarTopInView }),
              trackProductClick: trackProductClick,
            })
          }
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

function mapStateToProps(state: State, props: ComponentRouteProps) {
  const { q, sort } = props.location.query
  const isBeyond = Boolean(state.getIn(['shop', 'beyond']))
  const serializedQuery = `${q}-${sort || getDefaultSorting(isBeyond)}`

  return {
    isBeyond,
    viewError: state.getIn(['view', 'error']),
    searchData: state.getIn(['searchData', serializedQuery], undefined),
    isBusy: state.getIn(['view', 'busy']),
  }
}

export default compose(withI18n('shop'), translate(), connect(mapStateToProps), withReduxContext)(SearchRaw)
