import PropTypes from 'prop-types'
import React from 'react'

export default function Order({ order }) {
  return (
    <div className="template-order">
      <div>
        <div>
          {order.billingAddress.firstName} {order.billingAddress.lastName}
        </div>
        <div>{order.billingAddress.street}</div>
        <div>
          {order.billingAddress.zipCode} {order.billingAddress.city}
        </div>
        <div>{order.billingAddress.country}</div>
        <div>{order.billingAddress.emailAddress}</div>
      </div>
      <div>
        {order.lineItemContainer.productLineItems.map((lineItem) => (
          <div key={lineItem.lineItemId}>
            {lineItem.image && (
              <div>
                <img src={lineItem.image.url} />
              </div>
            )}
            <div>{lineItem.name}</div>
            <div>{lineItem.essentialFeatures}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

Order.propTypes = { order: PropTypes.object.isRequired }
