import { bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import React from 'react'

import { PageOrCategoryContext } from './Category'
import { loadLegalPage } from '../store/actions'
import CookieConsent from '../components/CookieConsent'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import createMultiLanguageLinkTags from './utils/createMultiLanguageLinkTags'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

class LegalPage extends React.Component {
  static propTypes = {
    isBeyondShop: bool.isRequired,
    isEditor: bool.isRequired,
    t: func.isRequired,
    viewError: map,
    shop: map,
    page: map,
  }

  static storeUpdate = (props, state) => {
    const isForwardNavigation = props.location.action === 'PUSH'
    const updates = []
    if (isForwardNavigation || !state.getIn(['legalPagesContents', props.params.legalPageSlug])) {
      updates.push(loadLegalPage(props.params.legalPageSlug))
    }
    return updates
  }

  static contentCreationDisabled = () => true

  render() {
    const { isBeyondShop, t, shop, page, viewError, isEditor } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="legal" />
    if (!page) return null

    const translatedTitle = isBeyondShop ? t(page.get('title')) : page.get('title')
    const linkTags = createMultiLanguageLinkTags(shop, `l/${page.get('slug')}`)
    const metaTags = [<meta key="robots" name="robots" content="noindex, follow" />]
    const cookieNoticeSettings = shop.getIn(['userSettings', 'cookieNotice'])
    const showCookieConsentPreview = Boolean(
      cookieNoticeSettings && cookieNoticeSettings.get('active') && page.get('slug') === 'cookiepolicy',
    )

    return (
      <PageOrCategoryContext.Provider value={this.props.page}>
        <Theme
          withLayout
          currentView="legal"
          title={translatedTitle}
          titleShouldIncludeShop
          metaTags={metaTags}
          linkTags={linkTags}
        >
          {(renderView, props) => (
            <>
              {renderView('Legal', {
                ...props,
                page: page.toJS(),
              })}
              {showCookieConsentPreview && (
                <section className="section">
                  <CookieConsent inline isEditor={isEditor} />
                </section>
              )}
            </>
          )}
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state, props) => ({
    shop: state.get('shop'),
    viewError: state.getIn(['view', 'error']),
    navigation: state.get('navigation'),
    breadcrumb: state.get('breadcrumb'),
    page: state.get('legalPagesContents').get(props.params.legalPageSlug),
    isBeyondShop: Boolean(state.getIn(['shop', 'beyond'])),
    isEditor: Boolean(state.getIn(['view', 'editorMode'])),
  })),
)(LegalPage)
