import { useCallback, useLayoutEffect, useRef, useState } from 'react'

import useResizeObserver from './useResizeObserver'

export default function useScrollValues() {
  const [element, setElement] = useState(null)
  const [scrollValues, setScrollValues] = useState({ top: 0, right: 0, bottom: 0, left: 0 })

  const rafId = useRef(null)
  const scheduleUpdate = useCallback(() => {
    if (rafId.current) return
    rafId.current = window.requestAnimationFrame(() => {
      if (element) setScrollValues(getScrollValues(element))
      rafId.current = null
    })
  }, [element])

  const setResizeRef = useResizeObserver(scheduleUpdate)
  const setRef = useCallback(
    (node) => {
      setElement(node)
      setResizeRef(node)
    },
    [setResizeRef],
  )

  useLayoutEffect(() => {
    if (element) {
      element.addEventListener('scroll', scheduleUpdate)
      scheduleUpdate()
      return () => {
        element.removeEventListener('scroll', scheduleUpdate)
        if (rafId.current) window.cancelAnimationFrame(rafId.current)
      }
    }
  }, [element, scheduleUpdate])

  return [setRef, scrollValues]
}

function getScrollValues(element) {
  // `scrollTop`/`scrollLeft` might return a decimal value when display scaling is used.
  // In this case, round the value to avoid a visual update loop in Chrome.
  const top = Math.ceil(element.scrollTop)
  const bottom = Math.max(element.scrollHeight - element.offsetHeight - top, 0)
  const left = Math.ceil(element.scrollLeft)
  const right = Math.max(element.scrollWidth - element.offsetWidth - left, 0)

  return { top, right, bottom, left }
}
