import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import SuggestedSearch, { ResultsList, SearchField } from '../../templateComponents/SuggestedSearch'
import { imageUrl } from '../../ThemeView'
import Link from '../../templateComponents/Link'
import translate from '../../../utils/translate'

function SearchForm({
  blurDelay,
  debug,
  i18n: _i18n,
  maxSuggestedItems,
  namespace,
  t,
  tReady: _tReady,
  ...htmlAttributes
}) {
  const hashedThemeAssets = useSelector((state) => state.getIn(['view', 'hashedThemeAssets']))
  const submitButtonRef = React.createRef()

  const handleSubmit = (event, performSearch) => {
    const form = event.target
    const searchField = form.q

    event.preventDefault()

    if (searchField.value) {
      performSearch(searchField.value)
      submitButtonRef.current.blur()
    } else {
      searchField.focus()
    }
  }

  return (
    <SuggestedSearch
      {...htmlAttributes}
      namespace={namespace}
      autoComplete="off"
      action="/search"
      onSubmit={handleSubmit}
      blurDelay={blurDelay || (debug ? 600000 : null)}
    >
      <button type="submit" className="search-form-icon search-form-submit" ref={submitButtonRef}>
        <span>{t('components.productSearchComponent.submitButton.label')}</span>
      </button>
      <SearchField
        maxSuggestedItems={maxSuggestedItems}
        className="search-form-field"
        type="text"
        name="q"
        placeholder={t('components.productSearchComponent.searchInputField.placeholder')}
      />
      <ResultsList renderItems={renderSuggestedSearchItems} />
    </SuggestedSearch>
  )

  function renderSuggestedSearchItems(items) {
    return (
      <div className="search-form-suggestions">
        <ul className="search-form-results">
          {items.map((item) => (
            <li
              className={cc([
                'search-form-results-item',
                {
                  active: item.active,
                },
              ])}
              key={item.productId}
            >
              <Link to={item.href}>
                <div
                  className="search-form-results-item-image"
                  style={{
                    backgroundImage: item.image
                      ? `url("${imageUrl(item.image.url, 64, 64)}")`
                      : `url('/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
                          'epages.base/assets/images',
                          'placeholder_180.jpg',
                        ])}`,
                  }}
                />
                <span className="search-form-results-item-name">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

SearchForm.propTypes = {
  namespace: PropTypes.string.isRequired,
  maxSuggestedItems: PropTypes.number,
  blurDelay: PropTypes.number,
  debug: PropTypes.bool,
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  tReady: PropTypes.bool,
}

SearchForm.defaultProps = {
  maxSuggestedItems: null,
  blurDelay: null,
  debug: false,
}

export default translate()(SearchForm)
