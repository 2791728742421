import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import Link from '../../templateComponents/Link'

class MegaMenuItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  state = {
    isOpen: false,
    openedLevel4: null,
    offsetHeight: null,
  }

  level4Elements = {}

  closeMegaMenu = (event) => {
    if (!this.domNode.contains(event.target) && this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  componentDidMount() {
    this.setState({
      offsetHeight: this.canvasElement.offsetHeight,
    })

    // The click event handler is not bound on `document.body` because Safari on iOS
    // only allows mouse events to bubble up in specific situations. More information:
    //   http://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html#link2
    this.pageContainerElement = document.querySelector('.page-canvas')
    this.pageContainerElement.addEventListener('click', this.closeMegaMenu)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      isOpen: false,
      offsetHeight: this.canvasElement.offsetHeight,
    })
  }

  componentWillUnmount() {
    this.pageContainerElement.removeEventListener('click', this.closeMegaMenu)
  }

  toggleMegaMenu = (event) => {
    // if the click event just opened it prevent the click from triggering a navigation
    if (!this.state.isOpen) event.preventDefault()

    this.setState({ isOpen: !this.state.isOpen })
  }

  slideItem(itemId) {
    // setting scroll to 0 in case if level4-menu is about to be open
    if (itemId) {
      this.megaMenuLastScrollPosition = this.megaMenuNode.scrollTop
      this.megaMenuNode.scrollTop = 0
    } else {
      this.megaMenuNode.scrollTop = this.megaMenuLastScrollPosition
    }

    this.setState({
      openedLevel4: itemId,
      offsetHeight: itemId
        ? Math.max(this.state.offsetHeight, this.level4Elements[itemId].offsetHeight)
        : this.canvasElement.offsetHeight,
    })
  }

  renderLevel4Container = (item, parentItem) => {
    const { openedLevel4 } = this.state
    const hasChildren = item.children.length > 0

    return (
      <div
        key="level4-container"
        ref={(node) => (this.level4Elements[item.id] = node)}
        className={cc([
          'mega-menu-level4-container',
          {
            'mega-menu-level4-container-open': openedLevel4 === item.id,
          },
        ])}
        style={openedLevel4 ? { height: this.megaMenuNode.clientHeight } : {}}
      >
        <div className="mega-menu-level4-container-breadcrumb">
          <Link to={parentItem.href}>{parentItem.title}</Link>
          <Link to={item.href}>{item.title}</Link>
          <span className="mega-menu-level4-close" onClick={() => this.slideItem(null)} />
        </div>
        {hasChildren && this.renderList(item.children, 4, item)}
      </div>
    )
  }

  renderList(items, level = 2, parentItem) {
    const nextLevel = level + 1

    return (
      <ul className={`mega-menu-level${level}`}>
        {items.map((item) => {
          const hasChildren = item.children.length > 0
          const dropIcon = <span key="expand-icon" className="drop-icon" onClick={() => this.slideItem(item.id)} />

          return (
            <li key={item.id}>
              <Link to={item.href}>{item.title}</Link>

              {hasChildren && level < 3 && this.renderList(item.children, nextLevel, item)}
              {hasChildren && level === 3 && [dropIcon, this.renderLevel4Container(item, parentItem)]}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    const { isInBreadcrumb, children, href, title } = this.props.item
    const { isOpen, offsetHeight, openedLevel4 } = this.state

    const menuClass = cc(['mega-menu', { 'mega-menu-open': isOpen, 'mega-menu-level4-active': openedLevel4 }])
    const canvasClass = cc(['mega-menu-canvas', { 'mega-menu-canvas-up': openedLevel4 }])
    const hasChildren = children.length > 0

    return (
      <li
        ref={(node) => (this.domNode = node)}
        className={cc({
          active: isInBreadcrumb,
          'has-sub-menu': hasChildren,
          open: isOpen,
        })}
      >
        <Link to={href} onClick={(event) => hasChildren && this.toggleMegaMenu(event)}>
          <span>{title}</span>
          <span className="down-icon" />
        </Link>

        <div
          ref={(node) => (this.megaMenuNode = node)}
          className={menuClass}
          style={{ height: offsetHeight }}
          tabIndex="-1"
        >
          <div ref={(node) => (this.canvasElement = node)} className={canvasClass}>
            {this.renderList(children)}
          </div>
        </div>
      </li>
    )
  }
}

export default class MegaMenu extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    className: PropTypes.string,
  }

  render() {
    const { items } = this.props

    return (
      <div className={this.props.className}>
        <div className="main-menu-wrapper">
          <ul className="main-menu">
            {items.map((item) => (
              <MegaMenuItem key={item.id} item={item} />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}
