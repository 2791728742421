import { Link as ReactRouterLink } from 'react-router'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import { withQueryAndScope } from '../../urlGenerators'

export default function Link({ to, ...other }) {
  const location = useSelector((state) => state.get('location'))
  return to !== null ? <ReactRouterLink to={withQueryAndScope(to, location)} {...other} /> : <a {...other} />
}
Link.propTypes = {
  to: PropTypes.string,
}
