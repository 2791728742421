import { Map } from 'immutable'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import React from 'react'

import { setViewError } from '../store/actions'
import Theme from '../components/Theme'

class UnknownRoute extends React.Component {
  static propTypes = {
    setNotFoundError: func.isRequired,
  }

  render() {
    const notFoundError = Map({ statusCode: 404 })

    this.props.setNotFoundError()

    return <Theme withLayout error={notFoundError} currentView="notFound" titleShouldIncludeShop />
  }
}

export default connect(null, (dispatch) => ({
  setNotFoundError: () => dispatch(setViewError('Not found', 404, null)),
}))(UnknownRoute)
