import { any, func } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import React from 'react'

import { addLineItemProductList, addPurchase, setCurrencyCode } from '../utils/googleAnalytics'
import { loadCart, loadOrder } from '../store/actions'
import { track } from '../utils/tracking'
import Link from '../components/templateComponents/Link'
import NewsletterForm from '../components/templateComponents/NewsletterForm'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

export class OrderRaw extends React.Component {
  static propTypes = {
    viewError: map,
    t: func.isRequired,
    shop: any.isRequired,
    order: any,
  }

  static storeUpdate = (props, state) => {
    const isForwardNavigation = props.location.action === 'PUSH'
    const updates = []

    // loading the (ordered) cart will remove it from the session in case we came here
    // from a non-fullpage reload (aka beyond checkout)
    if (state.getIn(['shop', 'beyond'])) updates.push(/* re */ loadCart())
    else if (isForwardNavigation || !state.getIn(['orders', props.params.orderId])) {
      updates.push(loadOrder(props.params.orderId, props.location.query))
    }

    return updates
  }

  static contentCreationDisabled = () => true

  render() {
    const { viewError, shop, order, t } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="order" />

    const trustedShopsBadge = shop.getIn(['userSettings', 'trustedShopsBadge'])
    const title = t('views.storefrontView.orderConfirmationPageSection.title')
    const metaTags = [
      <meta key="robots" name="robots" content="noindex, follow" />,
      // Strip the order id from the referrer
      <meta key="referrer" name="referrer" content="origin" />,
    ]

    return (
      <div>
        <Theme withLayout currentView="order" title={title} titleShouldIncludeShop metaTags={metaTags}>
          {(renderView, props) =>
            order ? (
              renderView('Order', {
                ...props,
                order: order.toJS(),
              })
            ) : (
              // "just" display a thank-you-for-ordering message
              <div className="order beyond">
                <div className="section">
                  <div className="wrapper">
                    <div className="content">
                      <h1>{t('views.storefrontView.orderConfirmationPageSection.title')}</h1>
                      <header className="order-confirmation">
                        <div className="order-confirmation-status">
                          <div className="status-order">
                            <h2>
                              {t('views.storefrontView.orderConfirmationPageSection.successStatus.orderReceived')}
                            </h2>
                          </div>
                          <NewsletterForm className="status-newsletter-form">
                            <div className="status-newsletter">
                              <h2>{t('components.newsletterSubscriptionComponent.title')}</h2>
                              <div className="status-newsletter-form-register">
                                <input
                                  className="status-newsletter-form-field"
                                  name="email"
                                  type="email"
                                  required
                                  placeholder={t(
                                    'components.newsletterSubscriptionComponent.emailAddressInputField.placeholder',
                                  )}
                                />
                                <button className="status-newsletter-form-button" type="submit">
                                  <span>{t('components.newsletterSubscriptionComponent.submitButton.label')}</span>
                                </button>
                              </div>
                            </div>
                          </NewsletterForm>
                          <div className="status-home">
                            <h2>{t('views.storefrontView.orderConfirmationPageSection.successStatus.whatNext')}</h2>
                            <Link className="status-home-link" to="/">
                              {t('views.storefrontView.returnToStartpageLink.label')}
                            </Link>
                          </div>
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </Theme>
        {/** Trusted Shops order confirmation badge, altered by an injected script **/}
        {trustedShopsBadge && (
          <div id="trustedShopsCheckout" style={{ display: 'none' }}>
            <span id="tsCheckoutOrderNr">{order.get('orderNumber')}</span>
            <span id="tsCheckoutBuyerEmail">{order.getIn(['billingAddress', 'emailAddress'])}</span>
            <span id="tsCheckoutOrderAmount">{order.get('grandTotal')}</span>
            <span id="tsCheckoutOrderCurrency">{order.get('currencyId')}</span>
            <span id="tsCheckoutOrderPaymentType">{order.getIn(['paymentData', 'paymentMethod', 'name'])}</span>
          </div>
        )}
      </div>
    )
  }

  componentDidMount() {
    // order is always undefined in Beyond
    const { order } = this.props

    track('order:completed', order && order.toJS())

    if (order) {
      setCurrencyCode(order.get('currencyId'))
      addLineItemProductList(order.getIn(['lineItemContainer', 'productLineItems']))
      addPurchase(order, this.props.shop.get('title'))
    }
  }
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state, props) => ({
    viewError: state.getIn(['view', 'error']),
    shop: state.get('shop'),
    order: state.getIn(['orders', props.params.orderId]),
  })),
)(OrderRaw)
