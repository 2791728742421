import PropTypes from 'prop-types'
import React from 'react'

import CategoryContent from '../../templateComponents/CategoryContent'
import Content from '../../templateComponents/Workspace'
import Plugin from '../../templateComponents/Plugin'
import ScrollToButton from '../../ScrollToButton'
import ToolbarTop from '../../templateComponents/ToolbarTop'

export default function Category({ t, category, productData, sortingOptions, showScrollButton, onScrollIntoView }) {
  return (
    <div className="category">
      <section className="section-dali">
        <Content />
      </section>
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <ToolbarTop onScrollIntoView={onScrollIntoView} />
            <div className="product-list-header">
              <h1 className="headline">{category.title}</h1>
            </div>
            <CategoryContent
              categoryId={category.categoryId}
              productData={productData}
              pageSize={12}
              t={t}
              sortingOptions={sortingOptions}
              categoryData={category}
              showTopbar={false}
            />
            {showScrollButton && <ScrollToButton className="scroll-to-button" t={t} />}
            <div className="seo-text">
              <Plugin type="epages.text" _id="category-seo-text" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Category.propTypes = {
  t: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  productData: PropTypes.object.isRequired,
  sortingOptions: PropTypes.object.isRequired,
  onScrollIntoView: PropTypes.func.isRequired,
  showScrollButton: PropTypes.bool.isRequired,
}
