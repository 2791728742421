import BlockWithHeadline, { NestedBlockWithHeadline } from './BlockWithHeadline'
import ImageWithText from './ImageWithText'
import MultiColumnBlock from './MultiColumnBlock'
import VideoWithText from './VideoWithText'

const blocks = {
  'headline-block': BlockWithHeadline,
  'nested-headline-block': NestedBlockWithHeadline,
  'multi-column': MultiColumnBlock,
  'image-with-text': ImageWithText,
  'video-with-text': VideoWithText,
}

export default blocks
