import PropTypes from 'prop-types'
import React from 'react'

import CategoryContent from '../../templateComponents/CategoryContent'
import Content from '../../templateComponents/Workspace'

export default function Home({ t, productData, category, sortingOptions }) {
  return (
    <div className="home">
      <section className="section-dali">
        <Content />
      </section>
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <CategoryContent
              productData={productData}
              pageSize={12}
              t={t}
              sortingOptions={sortingOptions}
              categoryData={category}
              hideTopbar
            />
          </div>
        </div>
      </section>
    </div>
  )
}

Home.propTypes = {
  t: PropTypes.func.isRequired,
  productData: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  sortingOptions: PropTypes.object.isRequired,
}
