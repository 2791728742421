import { List, Map, fromJS } from 'immutable'
import { identity } from 'lodash'
import { useSelector } from 'react-redux'

type ColorOption = Map<'value' | 'name', string>
type FormatColorFunction = (color: string) => string

const constantColors: List<ColorOption> = fromJS([
  { value: '#000000', name: 'blackColor' },
  { value: '#ffffff', name: 'whiteColor' },
])

export function useColorOptions(
  formatConstantColorName: FormatColorFunction = identity,
  formatThemeColorName: FormatColorFunction = identity,
): List<ColorOption> {
  const themeColors = useSelector<State, Map<string, string>>((state) =>
    state.getIn(['shop', 'themeStyle', 'themeStyleSettings', 'settings', 'colors'], Map()),
  )

  const themeColorOptions = themeColors.map<ColorOption>(
    (value: string, name: string) => Map({ value, name: formatThemeColorName(name) }) as ColorOption,
  )

  const constantColorOptions = constantColors.map<ColorOption>(
    (color: ColorOption) =>
      Map({ value: color.get('value'), name: formatConstantColorName(color.get('name')) }) as ColorOption,
  )

  return constantColorOptions.concat(themeColorOptions).toList()
}

export const imageColorTextSettingsDefaults = Map({
  textColorIndex: constantColors.findIndex((color) => color?.get('name') === 'whiteColor'),
  buttonColorIndex: constantColors.findIndex((color) => color?.get('name') === 'whiteColor'),
  backgroundColorIndex: constantColors.findIndex((color) => color?.get('name') === 'blackColor'),
  overlayOpacity: 40,
})

type ImageColorSettings = {
  textColor: ColorOption
  buttonColor: ColorOption
  backgroundColor: ColorOption
  overlayOpacity: number
}

export function useImageColorSettings(image: Map<string, any>): ImageColorSettings {
  const colorOptions = useColorOptions()

  const defaultTextColorIndex = imageColorTextSettingsDefaults.get('textColorIndex')
  const defaultButtonColorIndex = imageColorTextSettingsDefaults.get('buttonColorIndex')
  const defaultBackgroundColorIndex = imageColorTextSettingsDefaults.get('backgroundColorIndex')

  const textColorIndex = image.get('textColorIndex')
  const buttonColorIndex = image.get('buttonColorIndex')
  const backgroundColorIndex = image.get('backgroundColorIndex')

  const textColor = colorOptions.get(textColorIndex, colorOptions.get(defaultTextColorIndex))
  const buttonColor = colorOptions.get(buttonColorIndex, colorOptions.get(defaultButtonColorIndex))
  const backgroundColor = colorOptions.get(backgroundColorIndex, colorOptions.get(defaultBackgroundColorIndex))
  const overlayOpacity = image.get('overlayOpacity', imageColorTextSettingsDefaults.get('overlayOpacity'))

  return {
    textColor,
    buttonColor,
    backgroundColor,
    overlayOpacity,
  }
}
