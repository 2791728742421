import { any, string } from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'

import i18n from '../i18next'

export const i18nInstancesContext = React.createContext()

class I18nProvider extends React.PureComponent {
  static propTypes = {
    Component: any.isRequired,
    shopLocale: string.isRequired,
    interfaceLanguage: string,
  }

  constructor(props) {
    super(props)

    this.interfaceI18n = i18n.cloneInstance()
    this.shopI18n = i18n.cloneInstance()

    // set correct shop locale (language)
    this.shopI18n.changeLanguage(props.shopLocale)
    this.interfaceI18n.changeLanguage(props.interfaceLanguage)
  }

  render() {
    const { Component, shopLocale, interfaceLanguage, ...other } = this.props

    return (
      <i18nInstancesContext.Provider
        value={{
          interfaceI18n: this.interfaceI18n,
          shopI18n: this.shopI18n,
        }}
      >
        <Component {...other} />
      </i18nInstancesContext.Provider>
    )
  }
}

export default connect((state) => ({
  interfaceLanguage: state.getIn(['view', 'interfaceLanguage']),
  shopLocale: state.getIn(['shop', 'locale']).replace('_', '-'),
}))(I18nProvider)
