import axios from 'axios'

import {
  customFacetIdPrefix,
  defaultFacetIds,
  pagingDefaults,
  rangeFacetDelimiter,
  rangeFacetIds,
  sortingDefaults,
} from '../../utils/pageAndSort'
import { setViewBusyState } from '../actions'

export type ProductActionTypes =
  | SetProductsForCategoryAction
  | AddProductsForCategoryAction
  | SetProductsForSearchAction
  | AddProductsForSearchAction
  | ResolvedApiAction<LoadProductsByIds>
  | ResolvedApiAction<LoadProductsForCrossSelling>

export type Query = {
  page?: number
  resultsPerPage?: number
  sort?: string
}

export const fetchVimeoThumbnail = async (vimeoUrl: string): Promise<string> => {
  const result = vimeoUrl.match(/\/video\/(\d*)/)
  return axios
    .get(`https://vimeo.com/api/v2/video/${result?.[1]}.json`)
    .then((response) => response.data[0].thumbnail_medium)
    .catch(() => '')
}

export const SET_PRODUCTS_FOR_CATEGORY = 'SET_PRODUCTS_FOR_CATEGORY'
export type SetProductsForCategoryAction = {
  type: typeof SET_PRODUCTS_FOR_CATEGORY
  payload: { categoryId: string; productResult: Core.PageableProductResult; sort: string }
}
export function setProductsForCategory(
  categoryId: string,
  productResult: Core.PageableProductResult,
  sort: string,
): SetProductsForCategoryAction {
  return {
    type: SET_PRODUCTS_FOR_CATEGORY,
    payload: { categoryId, productResult, sort },
  }
}

export const setProductsForCategoryAsync = (categoryId: string, query?: Query): GlobalAction => async (
  dispatch,
  getState,
  api,
) => {
  const locale = getState().getIn(['shop', 'locale'])
  const sort = query?.sort || sortingDefaults.sort
  await api
    .get<Core.PageableProductResult>('/api/v2/products', {
      params: {
        ...sortingDefaults,
        ...pagingDefaults,
        ...query,
        categoryId,
        locale,
      },
    })
    .then((res) => res.data)
    .then((data) => dispatch(setProductsForCategory(categoryId, data, sort)))
}

export const ADD_PRODUCTS_FOR_CATEGORY = 'ADD_PRODUCTS_FOR_CATEGORY'
export type AddProductsForCategoryAction = {
  type: typeof ADD_PRODUCTS_FOR_CATEGORY
  payload: SetProductsForCategoryAction['payload']
}
export function addProductsForCategory(
  categoryId: string,
  productResult: Core.PageableProductResult,
  sort: string,
): AddProductsForCategoryAction {
  return {
    type: ADD_PRODUCTS_FOR_CATEGORY,
    payload: { categoryId, productResult, sort },
  }
}

export const addProductsForCategoryAsync = (categoryId: string, query?: Query): GlobalAction => async (
  dispatch,
  getState,
  api,
) => {
  const locale = getState().getIn(['shop', 'locale'])
  const sort = query?.sort || sortingDefaults.sort
  await api
    .get<Core.PageableProductResult>('/api/v2/products', {
      params: {
        ...sortingDefaults,
        ...pagingDefaults,
        ...query,
        categoryId,
        locale,
      },
    })
    .then((res) => res.data)
    .then((data) => dispatch(addProductsForCategory(categoryId, data, sort)))
}

export const SET_PRODUCTS_FOR_SEARCH = 'SET_PRODUCTS_FOR_SEARCH'
export type SetProductsForSearchAction = {
  type: typeof SET_PRODUCTS_FOR_SEARCH
  payload: { searchResult: Core.PageableSearchResult; sort: string; query: string; filters: SearchFacet[] }
}
export function setProductsForSearch(
  searchResult: Core.PageableSearchResult,
  sort: string,
  query: string,
  filters: SearchFacet[],
): SetProductsForSearchAction {
  return {
    type: SET_PRODUCTS_FOR_SEARCH,
    payload: { searchResult, sort, query, filters },
  }
}
export const ADD_PRODUCTS_FOR_SEARCH = 'ADD_PRODUCTS_FOR_SEARCH'
export type AddProductsForSearchAction = {
  type: typeof ADD_PRODUCTS_FOR_SEARCH
  payload: SetProductsForSearchAction['payload']
}
export function addProductsForSearch(
  searchResult: Core.PageableSearchResult,
  sort: string,
  query: string,
  filters: SearchFacet[],
): AddProductsForSearchAction {
  return {
    type: ADD_PRODUCTS_FOR_SEARCH,
    payload: { searchResult, sort, query, filters },
  }
}

type SearchFacet = Core.SelectionFilter | Core.RangeFilter
const buildFilterArray = (query: { [key: string]: string | string[] }): SearchFacet[] => {
  const filters: SearchFacet[] = []
  Object.entries(query).forEach(([key, value]) => {
    // each filter should be one of 'CategoryID', 'Manufacturer, 'ListPrice' or start with 'PreDefString_'
    if (defaultFacetIds.includes(key) || key.startsWith(customFacetIdPrefix)) {
      if (rangeFacetIds.includes(key)) {
        const [min, max] = (value as string).split(rangeFacetDelimiter)
        filters.push({ id: key, range: { min: parseInt(min), max: parseInt(max) } })
      } else if (Array.isArray(value)) {
        value.forEach((singleValue) => filters.push({ id: key, value: singleValue }))
      } else {
        filters.push({ id: key, value })
      }
    }
  })
  return filters
}

export function loadProductsForSearchAsync(appendProductData?: boolean): GlobalAction {
  return async (dispatch, getState, api) => {
    dispatch(setViewBusyState(true))

    const state = getState()
    const location = state.get('location')
    const locale = state.getIn(['shop', 'locale'])

    // default beyond search order to 'price-asc', cause it has no faceted search
    // and does not support relevance sorting
    const defaultSort = state.getIn(['shop', 'beyond']) ? 'price-asc' : 'relevance'
    const sort = location.getIn(['query', 'sort'], defaultSort)

    const query = location.get('query').toJS()
    const filters = buildFilterArray(query)

    const loadProducts = async (paging: Core.Pagination) =>
      await api
        .post<Core.PageableSearchResult>(
          '/api/v2/search',
          { filters, query: query.q, sort },
          { params: { ...paging, locale } },
        )
        .then((res) => res.data)

    if (appendProductData) {
      const page = parseInt(location.getIn(['query', 'page'], pagingDefaults.page))

      const productResult = await loadProducts({
        ...pagingDefaults,
        page,
      })
      dispatch(addProductsForSearch(productResult, sort, query.q, filters))
    } else {
      const queryPage = parseInt(location.getIn(['query', 'page'], pagingDefaults.page))
      let page = 1
      let resultsToFetch = queryPage * pagingDefaults.resultsPerPage
      const resultsPerPage = Math.min(resultsToFetch, 100)
      while (resultsToFetch > 0) {
        const productResult = await loadProducts({ ...pagingDefaults, resultsPerPage, page })
        if (page === 1) dispatch(setProductsForSearch(productResult, sort, query.q, filters))
        else dispatch(addProductsForSearch(productResult, sort, query.q, filters))
        resultsToFetch -= 100
        page++
      }
    }
    dispatch(setViewBusyState(false))
  }
}

type LoadProductsByIds = {
  type: typeof LOAD_PRODUCTS_BY_IDS
  payload: {
    productIds: string[]
    pluginId: string
  }
  response: Core.PageableProductResult
}
export const LOAD_PRODUCTS_BY_IDS = 'LOAD_PRODUCTS_BY_IDS'
export const LOAD_PRODUCTS_BY_IDS_SUCCESS = 'LOAD_PRODUCTS_BY_IDS_SUCCESS'
export const LOAD_PRODUCTS_BY_IDS_FAILURE = 'LOAD_PRODUCTS_BY_IDS_FAILURE'
export function loadProductsByIds(
  pluginId: string,
  productIds: string[],
  options?: ActionOptions,
): ApiAction<LoadProductsByIds> | ResolvedApiAction<LoadProductsByIds> {
  return productIds.length
    ? {
        type: LOAD_PRODUCTS_BY_IDS,
        idempotent: true,
        payload: { productIds, pluginId },
        callApi: (api, { productIds }, { locale }) =>
          api.get('/api/v2/products', { params: { productIds, locale } }).then((res) => res.data),
        options,
      }
    : {
        type: LOAD_PRODUCTS_BY_IDS_SUCCESS,
        productIds,
        pluginId,
        response: { products: [], totalNumberOfProducts: 0 },
      }
}

type LoadProductsForCrossSelling = {
  type: typeof LOAD_PRODUCTS_FOR_CROSSSELLING
  payload: {
    productId: string
    query: Core.Pagination
  }
  response: Core.CrossSellingResult
}
export const LOAD_PRODUCTS_FOR_CROSSSELLING = 'LOAD_PRODUCTS_FOR_CROSSSELLING'
export const LOAD_PRODUCTS_FOR_CROSSSELLING_SUCCESS = 'LOAD_PRODUCTS_FOR_CROSSSELLING_SUCCESS'
export const LOAD_PRODUCTS_FOR_CROSSSELLING_FAILURE = 'LOAD_PRODUCTS_FOR_CROSSSELLING_FAILURE'
export function loadProductsForCrossSelling(
  productId: string,
  { page = 1, size = 4 }: { page?: number; size?: number },
  options?: ActionOptions,
): ApiAction<LoadProductsForCrossSelling> {
  return {
    type: LOAD_PRODUCTS_FOR_CROSSSELLING,
    idempotent: true,
    payload: {
      productId,
      query: {
        ...sortingDefaults,
        resultsPerPage: size,
        page,
      },
    },
    callApi: (api, { productId, query }, { locale }) =>
      api.get(`/api/v2/products/${productId}/crossselling`, { params: { ...query, locale } }).then((res) => res.data),
    options,
  }
}
