import React, { useRef, useState } from 'react'
import { List } from 'immutable'
import cc from 'classcat'

import { DragSource } from './utils/DragAndDrop'
import BlockColorField from './BlockColorField'
import blockTypes from './blocks/index'
import compose from '../../../utils/compose'
import translate from '../../../utils/translate'
import withI18n from '../../withI18n'

type Props = {
  block: ImmutableMap
  config: any
  editorView: boolean
  onChange: (block: ImmutableMap) => void
  onDelete: () => void
} & TranslateProps
function Block({ block, config, editorView, onChange, onDelete, t }: Props): React.ReactElement {
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const [color, setColor] = useState<string>(block.get('backgroundColor', 'transparent'))
  const handleColorChange = (color: string) => setColor(color)
  const saveColor = () => onChange(block.set('backgroundColor', color))

  const actionBarRef = useRef<HTMLDivElement>(null)

  const tScoped = (subKey: string, ...args: any) => t(`components.elementContextBarComponent.${subKey}`, ...args)

  // Build a string representation of the block structure. Examples:
  // epages.image
  // image+text-image+text-image+text
  const blockType: string = (block.getIn(['data', 'columns']) || List()).reduce(
    (blockType: string, column: ImmutableMap) =>
      (blockType && blockType + '-') + (column.has('type') ? column.get('type') : column.keySeq().toJS().join('+')),
    '',
  )

  const BlockComponent = blockTypes[block.get('type')]
  if (!BlockComponent) return <div>{'Unknown block type "' + block.get('type') + '"'}</div>

  const handleDataChange = (data: unknown) => onChange(block.set('data', data))

  return (
    <div
      id={`block-${block.get('_id')}`}
      data-testid={`block-${block.get('_id')}`}
      className={cc(['dali-block', { 'block-background': color !== 'transparent' }])}
      data-block-type={blockType}
      style={{ backgroundColor: color }}
    >
      {editorView && (
        <div ref={actionBarRef} className="dali-block-actionbar">
          {!confirmingDelete && (
            <DragSource
              type="dali-add"
              className="dali-block-actionbar-button dali-block-actionbar-button-move"
              title={tScoped('moveButton.label')}
              onDrag={() => block}
              createPreviewNode={() => {
                const { width = 10 } = actionBarRef.current?.getBoundingClientRect() || {}

                const div = document.createElement('div')
                div.className = 'dali-block-actionbar'
                div.style.width = `${width}px`
                div.style.visibility = 'visible'
                div.style.opacity = '1'

                const div2 = document.createElement('div')
                div2.className = 'dali-block-actionbar-button dali-block-actionbar-button-move'
                div.append(div2)

                return div
              }}
              previewNodeOffset={() => {
                const { width = 10, height = 10 } = actionBarRef.current?.getBoundingClientRect() || {}

                return { x: width / 2, y: height / 2 }
              }}
            />
          )}
          {!confirmingDelete && (
            <div
              className="dali-block-actionbar-button dali-block-actionbar-button-delete"
              title={tScoped('deleteButton.label')}
              onClick={() => setConfirmingDelete(true)}
            />
          )}
          {confirmingDelete && <span>{tScoped('deleteButton.warningMessage')}</span>}
          {confirmingDelete && (
            <div className="dali-block-actionbar-button dali-block-actionbar-button-delete-confirm" onClick={onDelete}>
              <span>{tScoped('deleteConfirmButton.label')}</span>
            </div>
          )}
          {confirmingDelete && (
            <div
              className="dali-block-actionbar-button dali-block-actionbar-button-delete-cancel"
              onClick={() => setConfirmingDelete(false)}
            >
              <span>{tScoped('deleteDismissButton.label')}</span>
            </div>
          )}
          <BlockColorField
            color={color}
            initialColor={block.get('backgroundColor', 'transparent')}
            handleColorChange={handleColorChange}
            saveColorChange={saveColor}
            t={t}
          />
        </div>
      )}
      <BlockComponent
        config={config}
        editorView={editorView}
        data={block.get('data')}
        onDataChange={handleDataChange}
        settings={block.get('settings')}
      />
    </div>
  )
}

export default compose(withI18n('interface'), translate())(Block)
