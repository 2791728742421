import React, { ReactElement } from 'react'
import cc from 'classcat'

import Account from '../../epages.base/Account'
import HeaderLogo from '../../epages.base/HeaderLogo'
import MegaMenu from '../../epages.base/MegaMenu'
import MiniCart from '../../epages.base/MiniCart'
import OffCanvas from '../../epages.base/Navigation'
import SearchForm from '../../epages.base/SearchForm'

type Props = any
export default function Neutral(props: Props): ReactElement {
  const { shop, navigation, isPreview } = props
  return (
    <header className="header">
      <div className={cc(['header-logo', shop.logoUrl && !shop.shouldHideLogo && 'header-logo-adjustments'])}>
        <HeaderLogo shop={shop} />
      </div>

      <nav className="navigation" role="navigation">
        <OffCanvas className="navigation-mobile" items={navigation.slice(1)} direction="right" />
        <MegaMenu className="navigation-desktop" items={navigation.slice(1)} />
      </nav>

      <div className="block">
        <div className="header-search">
          <SearchForm className="search-form" namespace="header" maxSuggestedItems={5} />
        </div>
        <div className="header-account">
          <Account accountUrl={shop.accountUrl} isPreview={isPreview} />
        </div>
        <div className="header-minicart">
          <MiniCart cartUrl={shop.cartUrl} />
        </div>
      </div>
    </header>
  )
}
