import PropTypes from 'prop-types'
import React from 'react'

import { imageUrl } from '../../ThemeView'
import Link from '../../templateComponents/Link'

// https://github.com/ePages-de/epages-storage/blob/v0.10.7/src/universal/conf/application.conf#L61
const maxStorageDimension = 2560

const HeaderLogo = ({ shop }) => {
  return (
    <Link to={shop.href} title={shop.title} className="header-logo-link">
      {shop.logoUrl && !shop.shouldHideLogo ? (
        <img
          src={imageUrl(shop.logoUrl, { shop: shop.slug })}
          srcSet={`${imageUrl(shop.logoUrl, {
            height: 200,
            width: maxStorageDimension,
            shop: shop.slug,
          })} 1x, ${imageUrl(shop.logoUrl, {
            height: 400,
            width: maxStorageDimension,
            shop: shop.slug,
          })} 2x`}
          alt={shop.title}
        />
      ) : (
        <h1 className="header-title">{shop.title}</h1>
      )}
    </Link>
  )
}

HeaderLogo.propTypes = {
  shop: PropTypes.shape({
    href: PropTypes.string.isRequired,
    title: PropTypes.string,
    logoUrl: PropTypes.string,
    shouldHideLogo: PropTypes.bool,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default HeaderLogo
