import Immutable, { List } from 'immutable'

import * as actions from '../actions'
import { extendCategoryWithUrl } from '../../urlGenerators'
import { mapDeep } from '../../utils/immutableTreeHelpers'

function setCategorySlugsMap(state: State, navigation: List<ImmutableMap>) {
  const flattened = {}
  mapDeep(navigation, (node: ImmutableMap) => {
    if (node.get('type') === 'category') {
      flattened[node.get('slug')] = node.get('categoryId')
    }
    return node
  })

  return state.set('categorySlugsToGUIDs', Immutable.fromJS(flattened))
}

export default function categories(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.LOAD_NAVIGATION_SUCCESS:
      return setCategorySlugsMap(state, Immutable.fromJS(action.response))

    case actions.LOAD_CATEGORY_SUCCESS:
      return state.setIn(
        ['categories', action.categoryId],
        extendCategoryWithUrl(
          Immutable.fromJS(
            Object.assign({}, action.response.category, {
              categoryId: action.response.category.guid,
            }),
          ),
        ),
      )

    case actions.LIST_CATEGORIES_SUCCESS: {
      const categories = action.response.reduce((acc, category) => {
        return { ...acc, [category.id]: category }
      }, {})

      return state.set('categoriesList', Immutable.fromJS(categories))
    }

    case actions.UPDATE_CATEGORY_SETTINGS_SUCCESS:
      return setCategorySlugsMap(
        state,
        mapDeep(state.get('navigation').get('editor'), (node) =>
          node.get('id') === action.categoryId ? node.set('slug', action.slug) : node,
        ),
      ).setIn(['categories', action.categoryId], extendCategoryWithUrl(Immutable.fromJS(action.response)))

    case actions.UPDATE_CATEGORY_SETTINGS_FAILURE:
      return state.set('categorySlugsToGUIDs', action.memorized?.categorySlugsToGUIDs)

    case actions.UPDATE_CATEGORY_CONTENT:
      return state.setIn(['categories', action.categoryId, 'content'], action.content)

    default:
      return state
  }
}
