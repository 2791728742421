import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'

import { useColorOptions } from '../../../utils/hooks/colorSettings'
import Button from '../../formComponents/Button'
import ButtonRow from '../../formComponents/ButtonRow'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ './SettingsLayer'))
const ColorpickerField = loadable(
  () => import(/* webpackChunkName: "editor" */ '../../formComponents/ColorpickerField'),
)

type Props = {
  color: string
  initialColor: string
  handleColorChange: (color: string) => void
  saveColorChange: () => void
} & TranslateProps
export default function BlockColorField({
  t,
  color,
  initialColor,
  handleColorChange,
  saveColorChange,
}: Props): React.ReactElement {
  const [showColorField, setShowColorField] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const themeName = useSelector<State, string>((state) => state.getIn(['shop', 'theme', 'name'], '').replace('.', '_'))
  const tThemeSettings = (subKey: string) => t(`themes:${themeName}.settings.${subKey}`)
  const tColorOptions = (subKey: string) => t(`components.colorFieldComponent.popover.colorPresets.${subKey}.tooltip`)
  const colorOptions = useColorOptions(tColorOptions, tThemeSettings)

  const saveColor = () => {
    setShowColorField(false)
    saveColorChange()
  }

  const cancelHandler = () => {
    handleColorChange(initialColor)
    setShowColorField(false)
  }

  const toggleColorField = () => {
    if (showColorField) {
      cancelHandler()
    } else setShowColorField(true)
  }
  const colorpickerFieldDimensions = {
    width: 270,
    height: 270,
    hueWidth: 14,
    spacing: 5,
  }
  const title = t('components.elementContextBarComponent.backgroundColorButton.settingsLayer.title')
  const body = (
    <>
      <ColorpickerField
        className="colorpicker"
        value={color}
        onChange={handleColorChange}
        presetColors={colorOptions.toJS()}
        dimensions={colorpickerFieldDimensions}
      />
      <button
        type="button"
        className="ep-color-settings-reset-button"
        style={{ bottom: '78px' }}
        onClick={() => handleColorChange('transparent')}
      >
        {t('components.colorFieldComponent.popover.resetButton.label')}
      </button>
    </>
  )
  const footer = (
    <ButtonRow>
      <Button type="button" variant="cancel" onClick={cancelHandler}>
        {t('components.elementContextBarComponent.backgroundColorButton.settingsLayer.cancelButton.label')}
      </Button>
      <Button type="button" onClick={saveColor}>
        {t('components.elementContextBarComponent.backgroundColorButton.settingsLayer.saveButton.label')}
      </Button>
    </ButtonRow>
  )

  return (
    <>
      <div
        ref={buttonRef}
        className="dali-block-actionbar-button dali-block-actionbar-button-background-color"
        title={t('components.elementContextBarComponent.backgroundColorButton.label')}
        onClick={() => toggleColorField()}
      />
      {showColorField && (
        <SettingsLayer
          className="ep-settingslayer-block-background-settings"
          referenceElement={buttonRef.current}
          placement="right"
          onEscapeKeyDown={cancelHandler}
        >
          {({ renderLayout }) => renderLayout({ title, body, footer })}
        </SettingsLayer>
      )}
    </>
  )
}
