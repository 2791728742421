import React, { ReactElement } from 'react'
import cc from 'classcat'

import Account from '../../epages.base/Account'
import HeaderLogo from '../../epages.base/HeaderLogo'
import MegaMenu from '../../epages.base/MegaMenu'
import MiniCart from '../../epages.base/MiniCart'
import OffCanvas from '../../epages.base/Navigation'
import SearchForm from '../../epages.base/SearchForm'

type Props = any
export default function Neutral(props: Props): ReactElement {
  const { shop, navigation, isPreview } = props
  return (
    <>
      <header className="section site-header">
        <div className="wrapper">
          <div className="header">
            <div className="header-container">
              <div className="header-left">
                <div className="header-search">
                  <SearchForm className="search-form" namespace="header" maxSuggestedItems={5} />
                </div>
              </div>
              <div className="header-right">
                <div className="header-account">
                  <Account accountUrl={shop.accountUrl} isPreview={isPreview} />
                </div>
                <div className="header-minicart">
                  <MiniCart cartUrl={shop.cartUrl} />
                </div>
              </div>
              <div className={cc(['header-logo', shop.logoUrl && !shop.shouldHideLogo && 'header-logo-adjustments'])}>
                <HeaderLogo shop={shop} />
              </div>
            </div>
          </div>
        </div>
      </header>

      <nav className="section navigation" role="navigation">
        <OffCanvas className="wrapper navigation-mobile" items={navigation} />
        <MegaMenu className="wrapper navigation-desktop" items={navigation} />
      </nav>
    </>
  )
}
