import PropTypes from 'prop-types'
import React from 'react'

export default function Layout({ shop, content }) {
  return (
    <div className="template-layout">
      <div>{shop.slug}</div>
      Globals: {typeof process} {typeof global} {typeof Promise}
      <main>{content}</main>
    </div>
  )
}

Layout.propTypes = { shop: PropTypes.object.isRequired, content: PropTypes.node.isRequired }
