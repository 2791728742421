import { fromJS } from 'immutable'

import * as actions from '../actions'
import { cartPath, extendCartWithProductUrls } from '../../urlGenerators'

export default function cart(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.LOAD_CART_SUCCESS: {
      // Keep countries, paymentMethod and shippingMethod in state.
      // They are not part of the cart response.
      const response = action.response

      const newCart = extendCartWithProductUrls(fromJS(response))
      const productLineItems = response ? response.productLineItems : undefined
      const quantities = {}
      if (productLineItems) {
        productLineItems.forEach((productLineItem) => {
          quantities[productLineItem.lineItemId] = productLineItem.quantity
        })
      }

      state = state.set(
        'cart',
        state.getIn(['cart', 'quantityMismatch']) ? newCart.set('quantityMismatch', true) : newCart,
      )

      if (productLineItems) {
        state = state.setIn(['view', 'cart', 'quantities'], fromJS(quantities))
      }

      return state
    }
    case actions.REDEEM_COUPON_SUCCESS: {
      const newCart = extendCartWithProductUrls(fromJS(action.response))

      // scroll to top if there is a success message and we are in the browser
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
      return state.set('cart', newCart.set('couponSuccess', true))
    }
    case actions.REMOVE_COUPON_SUCCESS: {
      const newCart = extendCartWithProductUrls(fromJS(action.response))
      return state.set('cart', newCart.set('couponSuccess', undefined))
    }
    case actions.ADD_TO_CART_SUCCESS:
    case actions.UPDATE_LINE_ITEM_QUANTITY_SUCCESS:
    case actions.SET_CART_SHIPPING_METHOD_SUCCESS:
    case actions.SET_CART_PAYMENT_METHOD_SUCCESS:
      return state.set('cart', extendCartWithProductUrls(fromJS(action.response)))
    case actions.SET_CART_BUSY_STATE:
      return state.setIn(['view', 'cart', 'isBusy'], action.busyState)
    case actions.SET_CART_CREATE_PAYMENT_AND_ORDER:
    case actions.ORDER_CART:
      return state.setIn(['view', 'cart', 'isBusy'], true)
    case actions.SET_CART_CREATE_PAYMENT_AND_ORDER_SUCCESS:
    case actions.SET_CART_CREATE_PAYMENT_AND_ORDER_FAILURE:
    case actions.ORDER_CART_SUCCESS:
    case actions.ORDER_CART_FAILURE:
      return state.setIn(['view', 'cart', 'isBusy'], false)
    case actions.SET_CART_COUPON_CODE:
      return state.setIn(['view', 'cart', 'couponCode'], action.couponCode)
    case actions.SET_CART_COUPON_ERROR:
      return state.setIn(['view', 'cart', 'hasCouponError'], action.hasCouponError)
    case actions.CLEAR_CART_COUPON_ERROR:
      return state.setIn(['cart', 'couponError'], null)
    case actions.SET_CART_QUANTITIES:
      return state.setIn(['view', 'cart', 'quantities'], fromJS(action.quantities))
    case actions.UPDATE_VIEW:
      // delete quantity mismatch info upon navigating anywhere but to the cart
      return state.getIn(['location', 'pathname']) !== `/${cartPath}` && state.getIn(['cart', 'quantityMismatch'])
        ? state.deleteIn(['cart', 'quantityMismatch'])
        : state
    case actions.LOAD_CART_SERVICEABLE_COUNTRIES_SUCCESS:
      return state.setIn(['checkout', 'countries'], fromJS(action.response))
    case actions.LOAD_ALL_COUNTRIES_SUCCESS:
      return state.setIn(['checkout', 'all-countries'], fromJS(action.response))
    case actions.LOAD_CART_APPLICABLE_SHIPPING_METHODS_SUCCESS:
      return state.setIn(['checkout', 'shippingMethods'], fromJS(action.response))
    case actions.LOAD_CART_APPLICABLE_PAYMENT_METHODS_SUCCESS:
      return state.setIn(['checkout', 'paymentMethods'], fromJS(action.response))
    case actions.SET_CUSTOMER_COMMENT:
      return state.setIn(['checkout', 'comment'], fromJS(action.comment))
    default:
      return state
  }
}
