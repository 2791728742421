import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Router, RouterContext } from 'react-router'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import React from 'react'

import {
  enableOptOut as enableGAOptOut,
  initializeGoogleAnalyticsEc,
  sendPageView,
  setGAUsedState,
  setLocation,
} from './utils/googleAnalytics'
import { hydrateNavigation, updateLocation, updateView } from './store/actions'
import { track } from './utils/tracking'
import DelayUpdate from './components/DelayUpdate'
import GotoProvider from './components/GotoProvider'
import I18nProvider from './components/I18nProvider'
import Theme from './components/Theme'
import getRoutes from './routes'
import runAllViewStoreUpdates from './utils/runAllViewStoreUpdates'

export const getPageViewTracker = () => {
  let lastTrackedPathname = ''
  let lastTrackedSearch = ''

  return function trackPageView({ pathname, search }) {
    if (lastTrackedPathname !== pathname || lastTrackedSearch !== search) {
      lastTrackedPathname = pathname
      lastTrackedSearch = search
      track('pageview', { url: pathname + search })
      setLocation(location)
      sendPageView(pathname)
    }
  }
}

export default class App extends React.Component {
  static propTypes = {
    storeInitialState: ImmutablePropTypes.map.isRequired,
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const storeState = props.store.getState()

    // `window.ga` (set by the Google Analytics script tag in the HTML <head>)
    // indicates whether Google Analytics can be used or not.
    //
    // This also works in case of user opt-out (when the GA opt-out cookie exists),
    // because contrary to the recommended implementation by Google, we just don't
    // load the analytics script at all in this case.
    // See: https://hackademix.net/2010/05/26/google-analytics-opt-out-snake-oil/
    const hasGoogleAnalyticsOnPage = Boolean(window.ga)

    this.scrollYPositions = {}
    this.currentLocation = null
    this.lastLocation = null

    this.isEditor = storeState.getIn(['view', 'editorMode'])
    this.mboBaseUrl = storeState.getIn(['shop', 'mboBaseUrl'])

    this.props.history.listen((location) => props.store.dispatch(updateLocation(location)))

    enableGAOptOut(storeState.getIn(['shop', 'userSettings', 'googleAnalytics', 'trackingId']))
    setGAUsedState(hasGoogleAnalyticsOnPage && !this.isEditor && window.top === window.self)
    initializeGoogleAnalyticsEc()
  }

  trackPageView = getPageViewTracker()

  componentDidMount() {
    // using ChildNode.remove() is not supported in IE (https://caniuse.com/#feat=childnode-remove)
    require('./polyfills/childNodeRemove')

    // http://stackoverflow.com/a/33004917
    // Tell the browser that we take care of restoring the last scroll position ourselves.
    // See `beforeViewUpdate` below.
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }

    this.props.store.dispatch(hydrateNavigation())
  }

  componentWillUnmount() {
    if (this.trackPageViewTimeoutId) window.clearTimeout(this.trackPageViewTimeoutId)
  }

  routerCreateElement = (viewUpdate) => (Component, routerProps) => {
    const { store, history } = this.props

    return (
      <DelayUpdate promise={viewUpdate} instant={Component === Theme}>
        <GotoProvider history={history} store={store}>
          <I18nProvider Component={Component} {...routerProps} />
        </GotoProvider>
      </DelayUpdate>
    )
  }

  render() {
    const { store, storeInitialState, history } = this.props
    let firstRender = true

    const routerRender = (routerProps) => {
      const nextLocation = routerProps.location
      // create a list of store update promises per view component to render
      // and set or clear view error in store according to overall outcome
      const skipStoreUpdates = firstRender && Boolean(storeInitialState)

      const shouldTrackPageView =
        // don't track when rendered in editor
        !this.isEditor &&
        // don't track when rendered within an iframe (e.g. theme preview)
        window.top === window.self

      // after this promise everything is ready for the view to be rendered
      const beforeViewUpdate = (async () => {
        await runAllViewStoreUpdates({ routerProps, store, skip: skipStoreUpdates })

        firstRender = false
        if (nextLocation.action === 'PUSH') {
          this.scrollYPositions[this.lastLocation.key] = window.scrollY
        }
      })()

      ;(async () => {
        await beforeViewUpdate
        store.dispatch(updateView())

        if (shouldTrackPageView) {
          // in order that GA sends the correct pageview field values (e.g. page title),
          // defer the tracking call to wait for the page to be rendered
          if (this.trackPageViewTimeoutId) window.clearTimeout(this.trackPageViewTimeoutId)
          this.trackPageViewTimeoutId = window.setTimeout(() => {
            this.trackPageView(nextLocation)
          }, 1)
        }

        if (this.isEditor) {
          // tell the MBO where we are (aka "See your site")
          window.parent.postMessage(
            {
              type: 'PREVIEW_PATH',
              // Note: this discards all query parameters, but that's fine since we don't have any.
              // If we need to pass them along later, we'll have to exclude ['editor', 'shop', 'token'].
              payload: nextLocation.pathname.replace(/\/editor/, '') || '/',
            },
            this.mboBaseUrl,
          )
        }

        if (nextLocation.action === 'PUSH' && !(nextLocation.state?.scrollToTop === false)) {
          window.scrollTo(0, 0)
        } else if (nextLocation.action === 'POP' && !nextLocation.hash) {
          setTimeout(() => window.scrollTo(0, this.scrollYPositions[nextLocation.key] || 0), 0)
        }

        // Anchor time!
        if (nextLocation.hash && document.querySelector(nextLocation.hash)) {
          document.querySelector(nextLocation.hash).scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        this.lastLocation = nextLocation
      })()

      return <RouterContext {...routerProps} createElement={this.routerCreateElement(beforeViewUpdate)} />
    }

    return (
      <HelmetProvider>
        <Provider store={store}>
          <Router history={history} render={routerRender}>
            {getRoutes(storeInitialState.getIn(['shop', 'locales']).toJS())}
          </Router>
        </Provider>
      </HelmetProvider>
    )
  }
}
