import { useCallback, useEffect, useState } from 'react'
import ResizeObserverPolyfill from 'resize-observer-polyfill'

export default function useResizeObserver(onResize) {
  const [element, setElement] = useState(null)
  const setRef = useCallback(setElement, [setElement])

  useEffect(() => {
    // At the time of writing, Resize Observer is natively supported in most
    // major browsers (including Chrome, Firefox and Safari).
    // MS Edge status: Under Consideration
    const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

    if (element) {
      const observer = new ResizeObserver(onResize)
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [element, onResize])

  return setRef
}
