import React, { FunctionComponent, ReactElement } from 'react'

import Editorial from './modularHeaders/Editorial'
import Essence from './modularHeaders/Essence'
import Limelight from './modularHeaders/Limelight'
import Neutral from './modularHeaders/Neutral'
import OneLineHeader from './modularHeaders/OneLineHeader'
import Structure from './modularHeaders/Structure'
import TwoLineHeader from './modularHeaders/TwoLineHeader'
import Uptown from './modularHeaders/Uptown'
import Vision from './modularHeaders/Vision'

const headerMap: Record<ModularHeaderTypes, FunctionComponent<any>> = {
  vision: Vision,
  oneRow: OneLineHeader,
  twoRows: TwoLineHeader,
  uptown: Uptown,
  neutral: Neutral,
  editorial: Editorial,
  essence: Essence,
  structure: Structure,
  limelight: Limelight,
}

type Props = any
export default function ModularHeader(props: Props): ReactElement {
  const { themeSettings } = props
  const selectedHeader: ModularHeaderTypes = themeSettings?.modularHeader?.headerType || 'vision'
  const Element = headerMap[selectedHeader]
  return <Element {...props} />
}
