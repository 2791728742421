import { useEffect, useState } from 'react'

import localStorage from '../localStorage'

export default function useFeatureToggle(key: string): boolean {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(Boolean(localStorage.getItem(key)))
  }, [key])

  return isActive
}
