import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import { loadCart } from '../../../store/actions'
import Link from '../../templateComponents/Link'

const MiniCart = ({ cartUrl }) => {
  const cart = useSelector((state) => state.get('cart'))
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(loadCart())
  }, [dispatch])

  return (
    <Link to={cartUrl} className="header-minicart-checkout">
      <span className="header-minicart-icon" />
      <span className="header-minicart-badge">{cart ? cart.get('totalNumberOfItems', 0) : ''}</span>
    </Link>
  )
}
MiniCart.propTypes = {
  cartUrl: PropTypes.string.isRequired,
}

export default MiniCart
