import { flatMap } from 'lodash'

import { CATEGORY_PAGE_LIMIT } from '../../utils/pageAndSort'
import { ProductDataType } from '../../components/templateComponents/Workspace/plugins/category/CategoryPlugin'
import { Query as ProductsQuery, setProductsForCategoryAsync } from './products'
import { getPlain } from '../utils'
import blocks from '../../components/templateComponents/Workspace/blocks'

export type PageActionTypes =
  | SetPageAction
  | ResolvedApiAction<UpdatePageContent>
  | ResolvedApiAction<UpdatePageSettings>
  | ResolvedApiAction<LoadLegalPage>
  | ResolvedApiAction<UpdateLegalPageContent>
  | ResolvedApiAction<DuplicatePage>

type SetPageAction = {
  type: typeof SET_PAGE
  payload: {
    pageSlug: string
    csrfToken: string | undefined
    page: Core.Page
  }
}
export const SET_PAGE = 'SET_PAGE'
export function setPage(pageSlug: string, csrfToken: string | undefined, page: Core.Page): SetPageAction {
  return {
    type: SET_PAGE,
    payload: {
      pageSlug,
      csrfToken,
      page,
    },
  }
}

export const setPageAsync = (slug: string): GlobalAction => async (dispatch, getState, api) => {
  const locale = getState().getIn(['shop', 'locale'])

  try {
    const { data, headers } = await api.get<Core.Page>(`/api/v2/pages/${slug}`, {
      params: { locale },
    })
    const elements: Core.Plugin[] = [
      ...flatMap(data.content.blocks, ({ type, data }) => blocks[type].getElements(data)),
      ...data.content.elements,
    ]

    const locationQuery: Record<string, string> = getState().getIn(['location', 'query']).toJS()
    const pagingAndSortingQuery: ProductsQuery = {
      page: locationQuery.page ? parseInt(locationQuery.page) : undefined,
      resultsPerPage: locationQuery.resultsPerPage ? parseInt(locationQuery.resultsPerPage) : undefined,
      sort: locationQuery.sort,
    }

    const categoryPlugin = elements.find(({ type, data: { categoryId } }) => type === 'ng.category' && categoryId)
    if (categoryPlugin) {
      const page = pagingAndSortingQuery.page ? Math.min(pagingAndSortingQuery.page, CATEGORY_PAGE_LIMIT) : 1

      const categoryId = categoryPlugin.data.categoryId
      const categoryProductData = getState().getIn(['categoryProductData', categoryId], { products: [] })
      const { sort, products, totalNumberOfProducts } = getPlain<ProductDataType>(categoryProductData)

      if (
        sort !== pagingAndSortingQuery.sort ||
        !products.length ||
        (products.length < page * categoryPlugin.data.pageSize &&
          (!totalNumberOfProducts || products.length < totalNumberOfProducts))
      ) {
        try {
          await dispatch(
            setProductsForCategoryAsync(categoryPlugin.data.categoryId, {
              ...pagingAndSortingQuery,
              page: 1,
              resultsPerPage: page * categoryPlugin.data.pageSize,
            }),
          )
          // Do not break the page when products are not available. E.g. when the merchant deletes the category.
        } catch {}
      }
    }
    dispatch(setPage(slug, headers['x-csrf-token'], data))
  } catch (error) {
    if (error.response) {
      const serverRequestId =
        error.response.headers['x-epages-requestid'] || error.response.headers['x-b3-traceid'] || 'N/A'
      const { message, statusCode } = error.response.data

      throw Object.assign(new Error(message), {
        status: statusCode,
        statusMessage: message,
        serverRequestId,
      })
    } else {
      // The above errors are axios (API) errors and
      // Every other error will just result in a 500
      throw error
    }
  }
}

type UpdatePageContent = {
  type: typeof UPDATE_PAGE_CONTENT
  payload: {
    pageSlug: string
    content: ImmutableMap
  }
  response: Core.Page
}
export const UPDATE_PAGE_CONTENT = 'UPDATE_PAGE_CONTENT'
export const UPDATE_PAGE_CONTENT_SUCCESS = 'UPDATE_PAGE_CONTENT_SUCCESS'
export const UPDATE_PAGE_CONTENT_FAILURE = 'UPDATE_PAGE_CONTENT_FAILURE'
export function updatePageContent(
  pageSlug: string,
  content: ImmutableMap,
  options?: ActionOptions,
): ApiAction<UpdatePageContent> {
  return {
    type: UPDATE_PAGE_CONTENT,
    idempotent: false,
    payload: {
      pageSlug,
      content,
    },
    callApi: (api, { pageSlug, content }, { locale }) =>
      api
        .patch(
          `/api/v2/pages/${pageSlug}`,
          {
            content: content.toJS(),
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

export type UpdatePageSettings = {
  type: typeof UPDATE_PAGE_SETTINGS
  payload: Pick<Core.Page, 'title' | 'titleTag' | 'metaDescription' | 'isVisible' | 'navigation'> & {
    pageId: string
    oldPageSlug: string
    newPageSlug: string
  }
  response: Core.Page
}
export const UPDATE_PAGE_SETTINGS = 'UPDATE_PAGE_SETTINGS'
export const UPDATE_PAGE_SETTINGS_SUCCESS = 'UPDATE_PAGE_SETTINGS_SUCCESS'
export const UPDATE_PAGE_SETTINGS_FAILURE = 'UPDATE_PAGE_SETTINGS_FAILURE'
export function updatePageSettings(
  payload: UpdatePageSettings['payload'],
  options?: ActionOptions,
): ApiAction<UpdatePageSettings> {
  return {
    type: UPDATE_PAGE_SETTINGS,
    idempotent: false,
    payload,
    memorize: (state) => ({
      navigation: state.get('navigation'),
    }),
    callApi: (api, { oldPageSlug, newPageSlug, title, titleTag, metaDescription, isVisible, navigation }, { locale }) =>
      api
        .patch(
          `/api/v2/pages/${oldPageSlug}`,
          {
            title,
            titleTag,
            metaDescription,
            slug: newPageSlug,
            isVisible,
            navigation,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type LoadLegalPage = {
  type: typeof LOAD_LEGAL_PAGE
  payload: {
    legalPageSlug: string
  }
  response: Core.Page
}
export const LOAD_LEGAL_PAGE = 'LOAD_LEGAL_PAGE'
export const LOAD_LEGAL_PAGE_SUCCESS = 'LOAD_LEGAL_PAGE_SUCCESS'
export const LOAD_LEGAL_PAGE_FAILURE = 'LOAD_LEGAL_PAGE_FAILURE'
export function loadLegalPage(legalPageSlug: string, options?: ActionOptions): ApiAction<LoadLegalPage> {
  return {
    type: LOAD_LEGAL_PAGE,
    idempotent: true,
    payload: {
      legalPageSlug,
    },
    callApi: (api, { legalPageSlug }, { locale }) =>
      api.get(`/api/v2/legal/${legalPageSlug}`, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type UpdateLegalPageContent = {
  type: typeof UPDATE_LEGAL_PAGE_CONTENT
  payload: {
    legalPageSlug: string
    content: ImmutableMap
  }
  response: Core.Page
}
export const UPDATE_LEGAL_PAGE_CONTENT = 'UPDATE_LEGAL_PAGE_CONTENT'
export const UPDATE_LEGAL_PAGE_CONTENT_SUCCESS = 'UPDATE_LEGAL_PAGE_CONTENT_SUCCESS'
export const UPDATE_LEGAL_PAGE_CONTENT_FAILURE = 'UPDATE_LEGAL_PAGE_CONTENT_FAILURE'
export function updateLegalPageContent(
  legalPageSlug: string,
  content: ImmutableMap,
  options?: ActionOptions,
): ApiAction<UpdateLegalPageContent> {
  return {
    type: UPDATE_LEGAL_PAGE_CONTENT,
    idempotent: false,
    payload: {
      legalPageSlug,
      content,
    },
    callApi: (api, { legalPageSlug, content }, { locale }) =>
      api
        .patch(
          `/api/v2/legal/${legalPageSlug}`,
          {
            content: content.toJS(),
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type DuplicatePage = {
  type: typeof DUPLICATE_PAGE
  payload: {
    newSlug: string
    newTitle: string
  }
  response: Core.Page
}
export const DUPLICATE_PAGE = 'DUPLICATE_PAGE'
export const DUPLICATE_PAGE_SUCCESS = 'DUPLICATE_PAGE_SUCCESS'
export const DUPLICATE_PAGE_FAILURE = 'DUPLICATE_PAGE_FAILURE'
export function duplicatePage(
  slug: string,
  payload: DuplicatePage['payload'],
  options?: ActionOptions,
): ApiAction<DuplicatePage> {
  return {
    type: DUPLICATE_PAGE,
    idempotent: false,
    payload,
    callApi: (api, { newSlug, newTitle }, { locale }) =>
      api
        .post(
          `/api/v2/pages/${slug}/duplicate`,
          {
            newSlug,
            newTitle,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}
