import { Helmet } from 'react-helmet-async'
import { TFunction } from 'i18next'
import { fromJS } from 'immutable'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import buildURL from 'axios/lib/helpers/buildURL'
import cc from 'classcat'

import { AvailableSortings, CATEGORY_PAGE_LIMIT, pagingDefaults, sortingDefaults } from '../../utils/pageAndSort'
import { GotoContext } from '../GotoProvider'
import { ProductDataType } from './Workspace/plugins/category/CategoryPlugin'
import { addProductsForCategoryAsync } from '../../store/actions'
import { track } from '../../utils/tracking'
import { useIsInView } from './ToolbarTop'
import ProductPage from './Workspace/plugins/category/ProductPage'
import ScrollToButton from '../ScrollToButton'
import Sorting from './Workspace/plugins/category/Sorting'
import useEffectExceptMount from '../../utils/hooks/useEffectExceptMount'
import usePrevious from '../../utils/hooks/usePrevious'

type Props = {
  productData: ProductDataType
  categoryData: State
  pageSize: number
  sortingOptions: Partial<AvailableSortings>
  t: TFunction
  hideTopbar?: boolean
  hideTopButton?: boolean
}

export default function CategoryContent({
  categoryData,
  productData,
  pageSize,
  sortingOptions,
  t,
  hideTopbar = false,
  hideTopButton = false,
}: Props): React.ReactElement {
  const { gotoState, replace } = React.useContext(GotoContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [renderUpButton, setRenderUpButton] = React.useState(false)
  const [refTopDiv, inView] = useIsInView()
  const dispatch = useDispatch()

  const location = useSelector<State, State>((state) => state.get('location'))
  const query: State = location?.get('query')
  const sort = query.get('sort', productData.defaultSort || sortingDefaults.sort) as string
  const page = parseInt(query.get('page', pagingDefaults.page))

  const totalNumberOfProducts = productData.totalNumberOfProducts || 0
  const pageCount = Math.ceil(totalNumberOfProducts / pageSize)

  categoryData = fromJS(categoryData)
  const products: State = fromJS(productData.products)

  const categoryId = productData.categoryId || ''

  // Tracking effects
  const prevProductData = usePrevious(productData) || productData
  React.useEffect(() => {
    track('category', { category: categoryData, products })
    // Only track it once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffectExceptMount(() => {
    const isSameCategory = productData.categoryId === prevProductData.categoryId
    const newProductsAmount = productData.products.length - prevProductData.products.length
    const sortChanged = productData.sort && !isEqual(productData.sort, prevProductData.sort)

    if (isSameCategory && newProductsAmount > 0) {
      // New products came in via click on load more button.
      // Track category with only the new product items.
      track('category', {
        category: categoryData,
        products: products.slice(-newProductsAmount),
      })
    } else if (isSameCategory && sortChanged) {
      // The "Sort by" option has been changed.
      // Track category with the product items on the page.
      track('category', {
        category: categoryData,
        products,
      })
    } else if (!isSameCategory) {
      // The category actually changed
      track('category', {
        category: categoryData,
        products,
      })
    }
  }, [productData, location, categoryData, prevProductData])
  // Tracking effects end

  React.useEffect(() => {
    setRenderUpButton(!inView)
  }, [inView])

  const loadedPageCount = Math.ceil(productData.products.length / pageSize)
  React.useEffect(() => {
    const page = parseInt(location.getIn(['query', 'page'], 0))

    if (page > CATEGORY_PAGE_LIMIT && page > loadedPageCount) {
      updateCurrentUrl(CATEGORY_PAGE_LIMIT)
    }
    // It should only trigger on first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateCurrentUrl = (page: number) => {
    const searchParams = new URLSearchParams()

    searchParams.set('sort', sort)
    searchParams.set('page', String(page))

    const url = buildURL(location.get('pathname'), searchParams)
    replace(url)
  }
  const changeSorting = React.useCallback(
    (sort: string) => {
      const searchParams = new URLSearchParams(location.get('query').toJS())
      searchParams.set('sort', sort)
      searchParams.set('page', '1')

      gotoState({
        pathname: location.get('pathname'),
        search: '?' + searchParams.toString(),
        state: { scrollToTop: false },
      })
    },
    [gotoState, location],
  )

  const loadMoreProducts = async () => {
    setIsLoading(true)
    const newPage = page + 1

    await dispatch(addProductsForCategoryAsync(categoryId, { page: newPage, resultsPerPage: pageSize, sort }))

    updateCurrentUrl(newPage)

    setIsLoading(false)
  }

  const trackProductClick = (product: Core.Product, productIndex: number) => {
    track('product:click', { type: 'category', detail: categoryData.get('title'), product, productIndex })
  }

  return (
    <>
      {!hideTopbar && totalNumberOfProducts > 1 && (
        <div className="toolbar-top">
          <Sorting t={t} onChange={changeSorting} options={sortingOptions} value={sort} />
        </div>
      )}
      {totalNumberOfProducts > 0 && (
        <>
          {pageCount > 1 && (
            <Helmet>
              {(query.get('sort') || query.get('page')) && (
                <meta key="robots" name="robots" content="noindex, follow" />
              )}
            </Helmet>
          )}
          {!hideTopButton && <div ref={refTopDiv} id="scroll-top" />}
          <ProductPage
            products={productData.products}
            trackProductClick={trackProductClick}
            imageSize={categoryData.get('imageSize')}
          />

          {loadedPageCount < pageCount && (
            <div className="product-list-footer toolbar-bottom">
              <button
                disabled={isLoading}
                type="button"
                className={cc(['show-more-button', { pending: isLoading }])}
                onClick={loadMoreProducts}
              >
                {t('components.collectionComponent.showMoreButton.label')}
              </button>
            </div>
          )}
          {renderUpButton && !hideTopButton && <ScrollToButton className="scroll-to-button" t={t} />}
        </>
      )}
    </>
  )
}
