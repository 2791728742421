import PropTypes from 'prop-types'
import React from 'react'

const Account = ({ accountUrl, isPreview }) => {
  if (!accountUrl) return null

  return (
    <div>
      <a className="header-account-login" href={isPreview ? null : accountUrl}>
        <span className="header-account-icon" />
      </a>
    </div>
  )
}

Account.propTypes = {
  accountUrl: PropTypes.string,
  isPreview: PropTypes.bool.isRequired,
}

export default Account
