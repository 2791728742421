import React, { ReactElement, useRef, useState } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ImageGallerySettings'))

function ImageGalleryPlugin({
  config,
  data = Immutable.fromJS({
    src: '',
    alt: '',
  }),
  editorMode,
  editorView,
  onEdit,
  onCancel,
  onDataChange,
  onSave,
  t,
}: WorkspacePluginProps & TranslateProps): ReactElement | null {
  const [isSettingActive, setIsSettingActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const isEmpty = !data.get('src')

  const pluginActiveClasses = cc([
    {
      'dali-grid-element-hightlighted': isSettingActive,
    },
  ])

  const imageGalleryPlaceholder = (
    <div className="dali-grid-element-placeholder">
      <span className="dali-plugin-imagegallery-placeholder" />
      <label className="dali-plugin-imagegallery-placeholder-button-add" onClick={onEdit}>
        <span>{t('components.imageGalleryComponent.addImagesButton.label')}</span>
      </label>
    </div>
  )

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={(isSettingActive: boolean) => setIsSettingActive(isSettingActive)}
        onEscapeKeyDown={onCancel}
        className="dali-settingslayer-imagegallery"
      >
        {({ renderLayout }) => <SettingsForm {...{ config, data, onDataChange, onSave, onCancel, renderLayout }} />}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {isEmpty ? imageGalleryPlaceholder : null}
        {editorMode === 'edit' ? renderSettingsLayer() : null}
      </div>
    )
  }

  return isEmpty ? null : <div className={pluginActiveClasses}></div>
}

ImageGalleryPlugin.actionBarButtons = { edit: true }

export default compose(withI18n('interface'), translate())(ImageGalleryPlugin)
