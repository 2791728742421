import React, { ReactElement } from 'react'
import cc from 'classcat'

import Account from '../../epages.base/Account'
import HeaderLogo from '../../epages.base/HeaderLogo'
import MiniCart from '../../epages.base/MiniCart'
import NestedMenu from '../../epages.base/NestedMenu'
import SearchForm from '../../epages.base/SearchForm'

type Props = any
export default function Structure(props: Props): ReactElement {
  const { shop, navigation, isPreview } = props
  return (
    <>
      <header className="section site-header">
        <div className="wrapper">
          <div className="header">
            <div className={cc(['header-logo', shop.logoUrl && !shop.shouldHideLogo && 'header-logo-adjustments'])}>
              <HeaderLogo shop={shop} />
            </div>

            <div className="header-search">
              <SearchForm className="search-form" namespace="header" maxSuggestedItems={5} />
            </div>

            <div className="header-right">
              <div className="header-account">
                <Account accountUrl={shop.accountUrl} isPreview={isPreview} />
              </div>
              <div className="header-minicart">
                <MiniCart cartUrl={shop.cartUrl} />
              </div>
            </div>
          </div>

          <nav role="navigation">
            <NestedMenu desktopMediaQuery="(min-width: 992px)">
              <div className="toggle-menu">
                <NestedMenu.MobileToggle className="burger-icon" />
              </div>
              <NestedMenu.Menu items={navigation} />
            </NestedMenu>
          </nav>
        </div>
      </header>
    </>
  )
}
