import { func, string } from 'prop-types'
import React from 'react'

const scrollOptions = {
  block: 'start',
  behavior: 'smooth',
}

const scrollTo = () => (document.getElementById('scroll-top') || document.body).scrollIntoView(scrollOptions)

const ScrollToButton = ({ t, className }) => {
  return (
    <button className={className} onClick={scrollTo}>
      {/* Unsetting transform so the button is positioned correctly in the Beyond preview. */}
      <style>{`.body { transform: unset; }`}</style>
      <span className="text">{t('components.backToTopButton.label')}</span>
      <i className="icon" aria-hidden="true" />
    </button>
  )
}

ScrollToButton.propTypes = {
  t: func.isRequired,
  className: string,
}

export default ScrollToButton
