import React, { createElement as h } from 'react'
import { Form } from '@epages/react-components'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import cc from 'classcat'
import loadable from '@loadable/component'

import ImageField, { withImageData, withMergedImageData } from './ImageField'
import CtaTextOverlay from '../components/CtaTextOverlay'
import LazyImage from '../../../../LazyImage'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const Settings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageSettings'))
const CropSettings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageCropSettings'))

class ImagePlugin extends React.Component {
  static propTypes = {
    config: PropTypes.shape({
      storeFile: PropTypes.func.isRequired,
      imageUrl: PropTypes.func,
      previewUrl: PropTypes.func,
    }).isRequired,
    editorView: PropTypes.bool.isRequired,
    editorMode: PropTypes.oneOf(['view', 'edit']).isRequired,
    editAction: PropTypes.oneOf(['crop']),
    data: ImmutablePropTypes.map.isRequired,
    onDataChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: Immutable.fromJS({
      src: '',
      width: null,
      height: null,
      alt: '',
      link: '',
      opentab: false,
      text: '',
      buttontext: '',
      headline: '',
      buttonenabled: false,
    }),
  }

  static actionBarButtons = {
    edit: true,
  }

  state = {
    isSettingActive: false,
  }

  ref = React.createRef()

  handlePluginActiveStateChange = (isSettingActive) => {
    this.setState({ isSettingActive })
  }

  handleImageLoaded = () => {
    if (this.props.editorMode === 'edit') {
      this.updateSettingsLayerPosition()
    }
  }

  render() {
    const { data, config, onSave } = this.props

    const isEmpty = !data.get('src')
    const isLinked = Boolean(data.get('link'))
    const pluginActiveClasses = cc([
      'dali-plugin-image',
      {
        'dali-grid-element-hightlighted': this.state.isSettingActive,
      },
    ])

    const emptyImage = (
      <div className="dali-grid-element-placeholder">
        <div className="dali-settingslayer-element">
          {this.state.error ? <div className="dali-notification-danger">{this.state.error}</div> : null}
        </div>
        <Form
          name="pluginImagePlaceholder"
          value={withImageData(data)}
          prepare={withImageData}
          onChange={(data) => onSave(withMergedImageData(data).remove('imageData'))}
          className="dali-form"
        >
          <ImageField
            withImageInfo
            name="imageData"
            onError={(error) => this.setState({ error })}
            onChange={() => this.setState({ error: null })}
            storeFile={config.storeFile}
          />
        </Form>
      </div>
    )

    if (this.props.editorView) {
      return (
        <div className={pluginActiveClasses} ref={this.ref}>
          {isEmpty ? emptyImage : this.renderImage(false)}
          {this.props.editorMode === 'edit' && this.renderSettingsLayer()}
        </div>
      )
    }

    return !isEmpty ? <div className={pluginActiveClasses}>{this.renderImage(isLinked)}</div> : null
  }

  renderImage(isLinked) {
    const { config, data } = this.props
    const imageSource = config.imageUrl ? config.imageUrl(data.get('src')) : data.get('src')

    const textSettingsCta = {
      text: data.get('text'),
      headline: data.get('headline'),
      buttontext: data.get('buttontext'),
      buttonenabled: data.get('buttonenabled'),
    }

    const image = (
      <React.Fragment>
        <div
          className="dali-plugin-image-content"
          style={{
            '--aspect-ratio': data.get('width') && data.get('height') ? data.get('width') / data.get('height') : null,
          }}
        >
          <LazyImage
            src={imageSource}
            originalWidth={data.get('width')}
            alt={data.get('alt')}
            onLoad={this.handleImageLoaded}
          />
        </div>
        <CtaTextOverlay textSettings={textSettingsCta} image={data} />
      </React.Fragment>
    )

    if (isLinked) {
      const linkTarget = data.get('opentab') ? '_blank' : null
      const rel = linkTarget ? 'noopener noreferrer' : null

      return (
        <a href={data.get('link')} target={linkTarget} rel={rel} className="dali-image-wrapper">
          {image}
        </a>
      )
    }

    return <div className="dali-image-wrapper">{image}</div>
  }

  renderSettingsLayer() {
    const { editAction, data, onDataChange, config, onCancel, onSave } = this.props

    return (
      <SettingsLayer
        referenceElement={this.ref.current}
        placement="right"
        onActiveStateChange={this.handlePluginActiveStateChange}
        onEscapeKeyDown={onCancel}
        className={editAction === 'crop' ? 'dali-settingslayer-image-crop' : null}
      >
        {({ updatePosition, renderLayout }) => {
          this.updateSettingsLayerPosition = updatePosition

          return h(editAction === 'crop' ? CropSettings : Settings, {
            data,
            onDataChange,
            config,
            onCancel,
            renderLayout,
            onSave: (data) => {
              this.setState({ error: null })
              onSave(data)
            },
          })
        }}
      </SettingsLayer>
    )
  }
}

export default ImagePlugin
