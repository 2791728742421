import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import Link from '../../templateComponents/Link'

export class MenuItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    selectedItem: PropTypes.object,
  }

  state = {
    isOpen: isMenuItemOpen(this.props),
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: isMenuItemOpen(nextProps),
    })
  }

  componentDidMount() {
    if (this.subMenu && this.state.isOpen) this.openSubMenu()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.subMenu) {
      if (!prevState.isOpen && this.state.isOpen) {
        this.openSubMenu()
      } else if (prevState.isOpen && !this.state.isOpen) {
        this.closeSubMenu()
      }
    }
  }

  openSubMenu() {
    // 1. Transition to inner content's height
    // 2. Set height to `auto` once the transition ended
    this.subMenu.style.height = `${this.subMenu.scrollHeight}px`
    this.subMenu.addEventListener('transitionend', this.handleTransitionEnd)
  }

  closeSubMenu() {
    // 1. Temporarily disable the transition effect and explicitly set the current
    //    pixel height value of the sub menu in order for the transition effect to
    //    work (can't transition out of `auto`)
    // 2. Transition the sub menu's height to `0px`
    this.subMenu.classList.add('notransition')
    window.requestAnimationFrame(() => {
      this.subMenu.style.height = `${this.subMenu.scrollHeight}px`
      this.subMenu.classList.remove('notransition')
      window.requestAnimationFrame(() => {
        this.subMenu.style.height = '0'
      })
    })
  }

  handleTransitionEnd = () => {
    this.subMenu.removeEventListener('transitionend', this.handleTransitionEnd)
    if (this.state.isOpen) this.subMenu.style.height = 'auto'
  }

  render() {
    const { item, selectedItem } = this.props
    const { isOpen } = this.state
    const hasSubMenu = item.children.length > 0

    return (
      <li
        className={cc({
          'has-sub-menu': hasSubMenu,
          open: isOpen,
          active: item.isCurrentPage,
        })}
      >
        <Link to={item.href}>{item.title}</Link>

        {hasSubMenu && (
          <>
            <div onClick={() => this.setState({ isOpen: !isOpen })} className={cc(['drop-icon', { opened: isOpen }])} />
            <ul ref={(node) => (this.subMenu = node)} className="sub-menu">
              {item.children.map((child) => (
                <MenuItem selectedItem={selectedItem} key={child.id} item={child} />
              ))}
            </ul>
          </>
        )}
      </li>
    )
  }
}

export function findActiveItem(items) {
  for (const item of items) {
    if (item.isCurrentPage) return item
    if (item.children.length) {
      const foundItem = findActiveItem(item.children)
      if (foundItem) return foundItem
    }
  }
}

export function isMenuItemOpen({ item, selectedItem }) {
  return !!(selectedItem && selectedItem.parents.find(({ id }) => id === item.id))
}
