import { Map } from 'immutable'
import { flatMap } from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import React from 'react'

import Element from '../Element'

export default function VideoWithText({ config, editorView, data, onDataChange }) {
  return (
    <div className="dali-grid-row">
      {data.get('columns').map((column, index) => (
        <div key={index} className="dali-grid-column">
          <div className="dali-grid-element">
            <Element
              config={config}
              editorView={editorView}
              element={column.get('video')}
              onElementChange={(element) => onDataChange(data.setIn(['columns', index, 'video'], element))}
            />
          </div>
          <div className="dali-grid-element">
            <Element
              config={config}
              editorView={editorView}
              element={column.get('text')}
              onElementChange={(element) => onDataChange(data.setIn(['columns', index, 'text'], element))}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

VideoWithText.propTypes = {
  config: PropTypes.any.isRequired,
  editorView: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  onDataChange: PropTypes.func.isRequired,
  settings: ImmutablePropTypes.map,
}

VideoWithText.defaultProps = {
  settings: new Map(),
}

VideoWithText.getElements = (data) => flatMap(data.columns, (col) => [col.video, col.text])
