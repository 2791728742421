import { bool, number, oneOfType, string } from 'prop-types'
import React from 'react'
import cc from 'classcat'

// https://github.com/ePages-de/epages-storage/blob/v0.10.7/src/universal/conf/application.conf#L61
const maxStorageDimension = 2560

/**
 * Lazy load an image (using lazysizes).
 *
 * Basic usage: <LazyImage src="/image/source.png" />
 * => Automatically responsive if "src" is an epages-storage URL.
 *
 * - Use the "src" or "srcSet" props to lazy load an image.
 * - Use the "sizes" prop:
 *   `sizes="auto"` automatically calculates the value
 *   (or explicitly set a valid "sizes" HTML attribute value).
 *
 * When "src" is set to an epages-storage URL:
 * - If the "width" and/or "height" props are used, the values are passed as
 *   query parameter to the storage service.
 * - If the "width"/"height"/"srcSet" props are not used and the "sizes" prop
 *   is either not used or set to "auto", the lazysizes RIaS plugin (Responsive
 *   Images as a Service) will be used to automatically set the best suitable
 *   image source (using the "srcset" attribute if supported in the browser).
 *   => Use the "originalWidth" prop to constrain the used widths to be up to
 *      the image's original width (in "srcset" width descriptors, etc.).
 */
export function LazyImageRaw({ src, srcSet, sizes, width, height, originalWidth, noscript, className, ...otherProps }) {
  const hasStorageSrc = /\/(api\/core-)?storage\/images\/.+[?&](hash|remote)=/.test(src)
  const hasSizeConstraint = Boolean(width || height)

  // The lazysizes RIaS plugin requires `data-sizes="auto"`.
  // If "sizes" is used with any other value, or "srcSet" is used, pass these
  // props as standard HTML <img> attributes and don't use the plugin.
  const useRiasPlugin = hasStorageSrc && !hasSizeConstraint && !srcSet && (!sizes || sizes === 'auto')

  // "width"/"height" needs to be constrained to the epages-storage maximum dimension.
  const dataSrc = useRiasPlugin
    ? `${src}&width={width}&height=${maxStorageDimension}`
    : hasStorageSrc && hasSizeConstraint
    ? `${src}&${[`width=${width || maxStorageDimension}`, `height=${height || maxStorageDimension}`].join('&')}`
    : src

  // <noscript> is used as a fallback for no-JS clients.
  // It is also needed for structured data, e.g. to be able to infer an image
  // URL when `itemprop="image"` is part of `otherProps`.
  const fallbackSrc = hasStorageSrc ? `${src}&width=600` : src

  return (
    <React.Fragment>
      <img
        className={cc(['lazyload', className])}
        sizes={sizes !== 'auto' ? sizes : null}
        data-src={dataSrc}
        data-srcset={srcSet}
        data-sizes={sizes === 'auto' || useRiasPlugin ? 'auto' : null}
        data-max-width={originalWidth}
        // Always set at least an empty "alt" attribute for accessibility
        // (screen readers may read out the image URL when omitted), and to
        // avoid a bug in Chrome where the image won't load when inserted after
        // page load. See: https://github.com/aFarkas/lazysizes/issues/174
        alt=""
        {...otherProps}
        // Suppress hydration warning because some prop values will unavoidably
        // be different between the server and the client. lazysizes will change
        // these attributes on the client.
        suppressHydrationWarning={true}
      />
      {noscript && (
        <noscript>
          <img className={className} src={fallbackSrc} {...otherProps} />
        </noscript>
      )}
    </React.Fragment>
  )
}

LazyImageRaw.propTypes = {
  src: string,
  srcSet: string,
  sizes: string,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  originalWidth: oneOfType([string, number]),
  noscript: bool,
  className: string,
}

LazyImageRaw.defaultProps = {
  noscript: true,
}

export default React.memo(LazyImageRaw)
