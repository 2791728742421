import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import Account from '../epages.base/Account'
import Breadcrumb from '../epages.base/Breadcrumb'
import CustomCss from '../../templateComponents/CustomCss'
import HeaderLogo from '../epages.base/HeaderLogo'
import HeaderUsp from '../epages.base/HeaderUsp'
import LanguageSwitch from '../../templateComponents/LanguageSwitch'
import LegalFooterNavigation from '../epages.base/LegalFooterNavigation'
import Logistics from '../epages.base/Logistics'
import MegaMenu from '../epages.base/MegaMenu'
import MiniCart from '../epages.base/MiniCart'
import ModularHeader from '../epages.base/ModularHeader'
import NewsletterForm from '../../templateComponents/NewsletterForm'
import OffCanvas from '../epages.base/Navigation'
import Payments from '../epages.base/Payments'
import SearchForm from '../epages.base/SearchForm'
import SocialMedia from '../epages.base/SocialMedia'
import useFeatureToggle from '../../../utils/hooks/useFeatureToggle'

export default function Layout(props) {
  const { themeSettings, navigation, content, shop, isPreview, breadcrumb, legalPages, footerPages, t } = props
  const headerFeatureToggle = useFeatureToggle('modularHeaders')
  return (
    <div className="container">
      <CustomCss src={(themeSettings.customStyle || {}).customCss} />

      <HeaderUsp headerUsps={shop.userSettings.headerUsps} />

      <div className="page-canvas">
        {headerFeatureToggle ? (
          <ModularHeader {...props} />
        ) : (
          <header className="section site-header">
            <div className="wrapper">
              <div className="header">
                <div className={cc(['header-logo', shop.logoUrl && !shop.shouldHideLogo && 'header-logo-adjustments'])}>
                  <HeaderLogo shop={shop} />
                </div>

                <div className="header-search">
                  <SearchForm className="search-form" namespace="header" maxSuggestedItems={5} />
                </div>

                <div className="header-right">
                  <div className="header-account">
                    <Account accountUrl={shop.accountUrl} isPreview={isPreview} />
                  </div>
                  <div className="header-minicart">
                    <MiniCart cartUrl={shop.cartUrl} />
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}

        {headerFeatureToggle ? null : (
          <nav className="section navigation" role="navigation">
            <OffCanvas className="wrapper navigation-mobile" items={navigation} />
            <MegaMenu className="wrapper navigation-desktop" items={navigation} />
          </nav>
        )}

        <section className="section">
          <div className="wrapper">
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
        </section>

        <main className="main">{content}</main>

        <footer className="section site-footer">
          <div className="wrapper">
            <div className="footer">
              <LegalFooterNavigation {...{ legalPages, footerPages }} />

              <div className="block">
                <NewsletterForm className="newsletter-form">
                  <label className="newsletter-form-label" htmlFor="newsletter-form-field">
                    {t('components.newsletterSubscriptionComponent.title')}
                  </label>
                  <div className="newsletter-form-register">
                    <input
                      className="newsletter-form-field"
                      id="newsletter-form-field"
                      name="email"
                      type="email"
                      required
                      placeholder={t('components.newsletterSubscriptionComponent.emailAddressInputField.placeholder')}
                    />
                    <button className="newsletter-form-button" type="submit">
                      <span>{t('components.newsletterSubscriptionComponent.submitButton.label')}</span>
                    </button>
                  </div>
                </NewsletterForm>

                <LanguageSwitch>
                  {(locales, switchLanguage) => (
                    <div className="multi-language">
                      <div className="multi-language-switch">
                        <select
                          defaultValue={locales.find((l) => l.isActive).identifier}
                          onChange={({ target }) => switchLanguage(target.value)}
                        >
                          {locales.map((locale) => (
                            <option key={locale.identifier} value={locale.identifier}>
                              {t(`enumerations.languages.${locale.identifier.substr(0, 2)}`)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </LanguageSwitch>

                <SocialMedia socialNetworks={shop.userSettings.socialNetworks} />
              </div>

              <div className="providers">
                <Payments paymentLogos={shop.userSettings.paymentLogos} t={t} />
                <Logistics shippingLogos={shop.userSettings.shippingLogos} />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  themeSettings: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  content: PropTypes.node.isRequired,
  footerPages: PropTypes.array.isRequired,
  navigation: PropTypes.array.isRequired,
  breadcrumb: PropTypes.array.isRequired,
  legalPages: PropTypes.array.isRequired,
  isPreview: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}
