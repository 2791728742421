import { bool, func, node } from 'prop-types'
import React from 'react'

export default function Lightbox({ show, onClose, children }) {
  if (!show) return null

  return (
    <div className="lightbox-backdrop" onClick={onClose}>
      <div className="lightbox">
        <div className="lightbox-close">
          <span className="lightbox-close-button" onClick={onClose} />
        </div>
        <div className="lightbox-body">{children}</div>
      </div>
    </div>
  )
}

Lightbox.propTypes = {
  show: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
}
