import { bool, node, string } from 'prop-types'
import React from 'react'
import cc from 'classcat'

/*
 * Simple form (save/submit) button. Can have different variants (save, cancel, danger).
 */
export default function Button({ variant = 'save', className, disabled, children, ...other }) {
  return (
    <button
      {...other}
      disabled={disabled}
      className={cc([
        className,
        {
          [`ep-modal-button-${variant}`]: true,
          'ep-modal-button-disabled': disabled,
        },
      ])}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  variant: string,
  className: string,
  disabled: bool,
  children: node,
}
