import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import NoResults from '../epages.base/NoResults'
import ProductListItem from '../../templateComponents/ProductListItem'
import ScrollToButton from '../../ScrollToButton'
import SearchSortingForm from '../epages.base/SearchSortingForm'
import ToolbarTop from '../../templateComponents/ToolbarTop'

export default function Search({
  t,
  searchData,
  showScrollButton,
  onScrollIntoView,
  showLoadMoreButton,
  isBusy,
  trackProductClick,
  loadMoreProducts,
}) {
  return (
    <div className="search">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>
              {searchData.queryString
                ? t('components.productSearchComponent.resultsCard.resultsState.title', {
                    queryString: searchData.queryString,
                  })
                : t('components.productSearchComponent.resultsCard.noQueryState.title')}
            </h1>

            {searchData.products.length > 0 && searchData.queryString ? (
              <>
                <ToolbarTop className="toolbar-top" onScrollIntoView={onScrollIntoView}>
                  <SearchSortingForm searchData={searchData} t={t} />
                </ToolbarTop>

                <div className="product-list column-grid-2 column-grid-3 column-grid-4">
                  {searchData.products.map((product, productIndex) => (
                    <ProductListItem key={product.productId} {...{ product, productIndex, trackProductClick }} />
                  ))}
                </div>

                {showLoadMoreButton && (
                  <div className="toolbar-bottom">
                    <button
                      type="button"
                      className={cc(['show-more-button', { pending: isBusy }])}
                      disabled={isBusy}
                      onClick={loadMoreProducts}
                    >
                      {t('components.productSearchComponent.resultsCard.showMoreButton.label')}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <NoResults t={t} />
            )}

            {showScrollButton && <ScrollToButton className="scroll-to-button" t={t} />}
          </div>
        </div>
      </section>
    </div>
  )
}

Search.propTypes = {
  showScrollButton: PropTypes.bool.isRequired,
  showLoadMoreButton: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  searchData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loadMoreProducts: PropTypes.func.isRequired,
  onScrollIntoView: PropTypes.func.isRequired,
  trackProductClick: PropTypes.func.isRequired,
}
