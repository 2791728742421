import { node } from 'prop-types'
import React from 'react'

// https://reactjs.org/docs/error-boundaries.html

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) return this.props.fallback

    return this.props.children
  }
}

ErrorBoundary.propTypes = { fallback: node.isRequired, children: node.isRequired }
