import { Helmet } from 'react-helmet-async'
import { array, bool, func, string } from 'prop-types'
import { connect } from 'react-redux'
import { list, map } from 'react-immutable-proptypes'
import React from 'react'
import cc from 'classcat'

import ThemeView from './ThemeView'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from './withI18n'

export class ThemeRaw extends React.Component {
  static propTypes = {
    isBeyondShop: bool.isRequired,
    isPreview: bool.isRequired,
    isEcommerceDisabled: bool.isRequired,
    currentView: string.isRequired,
    themeId: string.isRequired,
    shop: map,
    navigation: map,
    breadcrumb: list,
    legalPages: list,
    footerPages: list,
    scriptTags: list,
    children: func,
    error: map,
    title: string,
    isEditorMode: bool,
    withLayout: bool,
    titleShouldIncludeShop: bool,
    metaTags: array,
    linkTags: array,
    t: func.isRequired,
  }

  static defaultProps = {
    withLayout: false,
  }

  state = {
    // when we render (or have rendered) on the server, we don't need to wait for the theme stylesheet
    waitingForStylesheet: !(typeof window === 'undefined' || document.getElementById('themeStylesheet')),
    showUnsupportedBrowserBanner: false,
  }

  componentDidMount() {
    if (/msie|trident|edge/i.test(navigator.userAgent)) {
      this.setState({ showUnsupportedBrowserBanner: true })
    }
  }

  handleStylesheetFinished = ({ target }) => {
    this.setState({ waitingForStylesheet: false })
    ;['load', 'error'].forEach((event) => target.removeEventListener(event, this.handleStylesheetFinished))
  }

  handleHelmetChangeClientState = (_newState, addedTags) => {
    const stylesheet = (addedTags.linkTags || []).find(({ id }) => id === 'themeStylesheet')
    if (stylesheet) {
      ;['load', 'error'].forEach((event) => stylesheet.addEventListener(event, this.handleStylesheetFinished))
    }
  }

  // needed for live theme switching only
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.shop.get('themeStyleId') !== nextProps.shop.get('themeStyleId')) {
      this.setState({ waitingForStylesheet: true })
    }
  }

  renderView(view, props) {
    return <ThemeView view={view} {...props} />
  }

  render() {
    const {
      isBeyondShop,
      isPreview,
      isEcommerceDisabled,
      title,
      metaTags,
      linkTags,
      currentView,
      withLayout,
      themeId,
      titleShouldIncludeShop,
      isEditorMode,
      shop,
      error,
      children,
      navigation,
      breadcrumb,
      legalPages,
      footerPages,
      scriptTags,
      t,
    } = this.props
    const { showUnsupportedBrowserBanner } = this.state

    // might be undefined during language switch, some template components (e.g Cart)
    // will break hard when we render during that transition. Bare minimum template render props.
    // However we must make sure to only not render the respective template but load the stylesheet (see below)
    // The views passing their own props take care of checking these themselves
    const preventRendering = !error && (!shop || !navigation || !breadcrumb || !legalPages)

    // might be undefined while theme loader is loading new theme
    if (!shop.getIn(['theme', 'themeSettings', 'settings'])) return <div className="editor-content-spinner" />

    const themeStyleName = shop.getIn(['themeStyle', 'name'])
    const themeSettingsString = encodeURIComponent(
      isEditorMode
        ? '{}'
        : JSON.stringify(
            shop
              .getIn(['theme', 'themeSettings', 'settings'])
              .merge(shop.getIn(['themeStyle', 'themeStyleSettings', 'settings']))
              .toJS(),
          ),
    )

    const themeStylesheetPath = ['/themes', themeId, 'stylesheets', themeStyleName].join('/') + '.css'
    const themeStylesheetPathWithSettings = `${themeStylesheetPath}?settings=${themeSettingsString}`

    const themeStylesheetHref =
      // Pass all settings if the URL isn't too long (up to 2000 characters is still long but should be safe).
      // Avoids "414 Request-URI too large" error.
      themeStylesheetPathWithSettings.length <= 1900 ? themeStylesheetPathWithSettings : themeStylesheetPath

    const themeComponentProps = {
      shop: shop ? shop.toJS() : {},
      scriptTags: scriptTags ? scriptTags.toJS() : [],
      navigation: navigation ? navigation.get('storefront').toJS() : [],
      breadcrumb: breadcrumb ? breadcrumb.toJS() : [],
      footerPages: footerPages?.toJS() || [],
      legalPages: legalPages
        ? legalPages.toJS().reduce((items, item) => {
            if (item.isVisible) {
              // In non-Beyond shops, `title` is already translated
              if (isBeyondShop) item.title = t(item.title)

              items.push(item)
            }

            return items
          }, [])
        : [],
      isPreview,
    }

    const [titleString, content] = error
      ? [
          t('views.storefrontView.errorPageSection.errorcode', { code: error.get('statusCode') }),
          this.renderView('Error', { shop: shop.toJS(), error: error.toJS() }),
        ]
      : [title, children(this.renderView, themeComponentProps)]

    const viewTitle = titleShouldIncludeShop
      ? [titleString, shop.get('title')].filter(Boolean).join(' - ')
      : titleString

    const viewTemplate = withLayout
      ? this.renderView('Layout', {
          ...themeComponentProps,
          content,
          currentView,
        })
      : content

    const editorStorefrontLang = isEditorMode ? shop.get('locale').substr(0, 2) : null

    return (
      <div className={cc(['body', { 'no-ecommerce': isEcommerceDisabled }])} lang={editorStorefrontLang}>
        {showUnsupportedBrowserBanner && (
          <div className="unsupported-browser">
            <div className="unsupported-browser-info">
              {t('components.unsupportedBrowserNotice.explanation')}{' '}
              <a
                className="unsupported-browser-info-link"
                href="https://browser-update.org/update-browser.html"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {t('components.unsupportedBrowserNotice.link.label')}
              </a>
            </div>
          </div>
        )}
        <Helmet onChangeClientState={this.handleHelmetChangeClientState}>
          <title>{viewTitle}</title>
          {metaTags}
          {linkTags}
          <link rel="stylesheet" id="themeStylesheet" href={themeStylesheetHref} />
        </Helmet>

        {/* when rendering on the client only, make the theme CSS render-blocking to avoid a FOUC */}
        {this.state.waitingForStylesheet ? (
          <div className="editor-content-spinner"></div>
        ) : (
          !preventRendering && viewTemplate
        )}
      </div>
    )
  }
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state) => ({
    isBeyondShop: Boolean(state.getIn(['shop', 'beyond'])),
    shop: state.get('shop'),
    isEditorMode: state.getIn(['view', 'editorMode']),
    isPreview: Boolean(state.getIn(['location', 'query', 'previewTheme'])),
    isEcommerceDisabled: Boolean(
      state
        .getIn(['shop', 'attributes'])
        .find((attribute) => attribute.get('name') === 'ecommerce:disabled' && attribute.get('value') === 'true'),
    ),
    navigation: state.get('navigation'),
    themeId: state.getIn(['shop', 'theme', 'name']),
    breadcrumb: state.get('breadcrumb'),
    legalPages: state.get('legalPages'),
    footerPages: state.get('footerPages'),
    scriptTags: state.get('scriptTags'),
  })),
)(ThemeRaw)
