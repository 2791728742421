import buildURL from 'axios/lib/helpers/buildURL'

import * as plugins from './plugins'

export const allowedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']
export const isValidMimeType = (mimeType) => allowedMimeTypes.indexOf(mimeType) >= 0

export const storeFile = (api) => (file) =>
  api
    .post('/storage/images/' + encodeURI(file.name), file, { headers: { 'Content-Type': file.type } })
    .then((response) => response.data)
    // convert 0.4.x API response to >=0.5.x format
    .then((response) =>
      Object.assign(response, {
        width: response.width || response.metadata.width,
        height: response.height || response.metadata.height,
      }),
    )
    .then((response) =>
      Object.assign(response, {
        absoluteDownloadUrl: `/storage/images/${response.relativeDownloadUrl}`,
      }),
    )

export const imageUrl = (shopSlug) => (url) => buildURL(url, { shop: shopSlug })
export const resizedUrl = (width, height) => (url) => buildURL(url, { width, height })

export default function daliConfig(isEditor, api, shopSlug) {
  return {
    plugins: {
      'epages.text': { component: plugins.TextPlugin },
      'epages.image': {
        component: plugins.ImagePlugin,
        config: { storeFile: storeFile(api), imageUrl: imageUrl(shopSlug), previewUrl: resizedUrl(680) },
      },
      'epages.contentslider': {
        component: plugins.ContentSliderPlugin,
        config: { storeFile: storeFile(api), imageUrl: imageUrl(shopSlug), previewUrl: resizedUrl(680) },
      },
      'epages.productslider': {
        component: plugins.ProductSliderPlugin,
        config: {
          thumbnailUrl: resizedUrl(120, 120),
        },
      },
      'epages.video': {
        component: plugins.VideoPlugin,
      },
      'ng.category': {
        component: plugins.CategoryPlugin,
        config: {
          imageUrl: (url) => url,
        },
      },
      'epages.gmaps': {
        component: plugins.GoogleMapsPlugin,
        config: {},
      },
      'epages.html': {
        component: plugins.FreeHtmlPlugin,
        config: {},
      },
      'epages.contactform': {
        component: plugins.ContactFormPlugin,
        config: {},
      },
      'epages.divider': {
        component: plugins.DividerPlugin,
      },
      'epages.imagegallery': {
        component: plugins.ImageGalleryPlugin,
        config: { storeFile: storeFile(api) },
      },
    },
  }
}
