import 'es6-shim'
import { browserHistory as history } from 'react-router'
import Immutable from 'immutable'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import * as storeActions from '../web/store/actions'
import { createStore } from '../web/store'
import { ensureResourceBundlesLoaded } from '../web/i18next'
import { init as initTracking } from '../web/utils/tracking'
import App from '../web/App'

const { shopSlug } = window.__EP

// will be updated on postMessage from the MBO
const storeInitialState = Immutable.fromJS(JSON.parse(window.__EP.storeInitialState)).setIn(['view', 'mboMenu'], [])

if ('serviceWorker' in navigator) {
  const serviceWorkerScripts = storeInitialState
    .get('scriptTags', Immutable.List())
    .filter((tag) => tag.get('type') === 'SERVICEWORKER')

  const encodedScripts = serviceWorkerScripts
    .map((script) => 'url[]=' + encodeURIComponent(script.get('url')))
    .join('&')

  ;(async () => {
    const scriptURL = '/sw.js?' + encodedScripts

    // unregister ServiceWorker whenever scriptURL has changed ('SERVICEWORKER' script tag added or removed)
    const { serviceWorker } = navigator
    if (serviceWorker.controller && !serviceWorker.controller.scriptURL.endsWith(scriptURL))
      await serviceWorker.getRegistration().then((reg) => reg.unregister())

    if (serviceWorkerScripts.size > 0) await serviceWorker.register(scriptURL)
  })()
}

initTracking()

// get the token query-parameter
const queryToken = (window.location.search.match(/token=([^&]+)/) || [])[1]
const queryPreviewTheme = (window.location.search.match(/previewTheme=([^&]+)/) || [])[1]
// previewing themeStyle is only allowed together with previewTheme
const queryThemeStyle = queryPreviewTheme && (window.location.search.match(/themeStyle=([^&]+)/) || [])[1]
// create an axios instance for all api-actions
const api = axios.create({
  params: {
    shop: shopSlug,
    ...(queryPreviewTheme ? { previewTheme: queryPreviewTheme } : {}),
    ...(queryThemeStyle ? { themeStyle: queryThemeStyle } : {}),
  },
  headers: {
    // set authorization-header for all api-actions if a token is given as query-parameter
    ...(queryToken ? { Authorization: 'Bearer ' + queryToken } : {}),
  },
})
const store = createStore(api, storeInitialState)

if (process.env.NODE_ENV !== 'production') {
  Object.assign(window.__EP, {
    store,
    storeActions,
  })
}

window.addEventListener(
  'message',
  function (event) {
    if (event.data.type) {
      switch (event.data.type) {
        case 'MBO_MENU':
          store.dispatch(storeActions.setMboMenu(event.data.payload))
          break
        // Alter default authorization-header of the axios instance
        case 'TOKEN':
          api.defaults.headers.Authorization = 'Bearer ' + event.data.payload
          break
        case 'MBO_BASE_URL':
          store.dispatch(storeActions.setMboBaseUrl(event.data.payload))
          break
        default:
          break
      }
    } else {
      if (event.data.mboMenu) {
        store.dispatch(storeActions.setMboMenu(event.data.mboMenu))
      }

      if (event.data.token) {
        // Alter default authorization-header of the axios instance
        api.defaults.headers.Authorization = 'Bearer ' + event.data.token
      }
    }
  },
  false,
)

const loadResourceBundles = async () => {
  const shopLocale = storeInitialState.getIn(['shop', 'locale']).replace('_', '-')
  const interfaceLanguage = storeInitialState.getIn(['view', 'interfaceLanguage'])

  // always have the fallback language prepared
  await ensureResourceBundlesLoaded('en')
  await ensureResourceBundlesLoaded(shopLocale)

  if (interfaceLanguage) await ensureResourceBundlesLoaded(interfaceLanguage)
}

loadResourceBundles().then(() => {
  ReactDOM.hydrate(
    React.createElement(App, {
      storeInitialState,
      history,
      store,
    }),
    document.getElementById('app'),
  )
})
