import PropTypes from 'prop-types'
import React from 'react'
import scopeCss from 'scope-css'

// Once it breaks we need to come up with a more sophisticated solution.
// Also strips block comments
const CustomCss = ({ src }) => (src ? <style dangerouslySetInnerHTML={{ __html: scopeCss(src, '.body') }} /> : null)

CustomCss.propTypes = {
  src: PropTypes.string.isRequired,
}

CustomCss.defaultProps = {
  src: '',
}

export default CustomCss
