import { List } from 'immutable'
import { bool, object } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import React from 'react'
import omit from 'lodash/omit'

import {
  CATEGORY_PAGE_LIMIT,
  POSITION_ASC,
  PRICE_ASC,
  PRICE_DESC,
  createCategorySortings,
  pagingDefaults,
  sortingDefaults,
} from '../utils/pageAndSort'
import { addProductsForCategoryAsync, loadCategory, setProductsForCategoryAsync } from '../store/actions'
import { getPlain } from '../store/utils'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import createMultiLanguageLinkTags from './utils/createMultiLanguageLinkTags'
import withReduxContext from '../utils/withReduxContext'

export const PageOrCategoryContext = React.createContext()

// since we know that Category is Now-only component
const sortingOptions = { ...POSITION_ASC, ...PRICE_ASC, ...PRICE_DESC }

export class CategoryRaw extends React.Component {
  static propTypes = {
    shop: map.isRequired,
    store: object.isRequired,
    location: object.isRequired,
    params: object.isRequired,
    category: map.isRequired,
    viewError: map,
    productData: object.isRequired,
    isHomepage: bool,
  }

  state = {
    showScrollButton: false,
  }

  static storeUpdate = (props, state) => {
    const isForwardNavigation = props.location.action === 'PUSH'
    const guidOrRouterParam = CategoryRaw.getGuid(state, props) || props.params.splat

    const queryPage = props.location.query.page && Math.min(parseInt(props.location.query.page), CATEGORY_PAGE_LIMIT)
    async function loadAllCategoryProducts(dispatch) {
      const totalProductAmount = queryPage * pagingDefaults.resultsPerPage
      const productData = getPlain(state.getIn(['categoryProductData', guidOrRouterParam], { products: [] }))
      const products = productData.products
      const defaultSort = state.getIn(['shop', 'beyond']) ? 'price-asc' : 'position-asc'
      const sort = props.location.query.sort || defaultSort

      const categoryTotalNumberOfProducts = productData.totalNumberOfProducts

      if (
        !isForwardNavigation &&
        productData?.sort === sort &&
        products &&
        (products.length >= (queryPage || 1) * pagingDefaults.resultsPerPage ||
          products.length >= categoryTotalNumberOfProducts)
      ) {
        return null
      }

      if (queryPage > 0) {
        let page = 1
        let resultsToFetch = totalProductAmount
        const resultsPerPage = Math.min(resultsToFetch, 100)
        while (resultsToFetch > 0) {
          if (page === 1)
            await dispatch(
              setProductsForCategoryAsync(guidOrRouterParam, {
                ...omit(props.location.query, ['token']),
                page,
                resultsPerPage,
              }),
            )
          else
            await dispatch(
              addProductsForCategoryAsync(guidOrRouterParam, {
                ...omit(props.location.query, ['token']),
                page,
                resultsPerPage,
              }),
            )
          resultsToFetch -= 100
          page++
        }
      } else {
        return dispatch(setProductsForCategoryAsync(guidOrRouterParam, omit(props.location.query, ['token'])))
      }
    }

    const updates = [loadAllCategoryProducts]

    if (isForwardNavigation || !state.getIn(['categories', guidOrRouterParam])) {
      updates.push(loadCategory(guidOrRouterParam))
    }

    return updates
  }

  // look up the GUID to the requested slug in the store state in order to save a db roundtrip
  static getGuid(state, { params, isHomepage }) {
    const navigation = state.getIn(['navigation', 'storefront']) || new List()
    return state.getIn(['categorySlugsToGUIDs', isHomepage ? navigation.getIn([0, 'slug']) : params.splat])
  }

  static contentCreationDisabled = () => false

  get url() {
    return this.props.params.splat ? this.props.category.get('url') : '/'
  }

  render() {
    const { viewError, location, productData, category, shop, isHomepage } = this.props
    const { showScrollButton } = this.state
    const page = parseInt(location.query.page) || 1

    if (viewError) return <Theme withLayout error={viewError} currentView="category" />

    const { resultsPerPage, sort } = location.query

    const totalNumberOfProducts = productData.totalNumberOfProducts || 0
    const totalNumberOfPages = Math.ceil(totalNumberOfProducts / (resultsPerPage || pagingDefaults.resultsPerPage))

    const sortings = createCategorySortings(
      {
        pageUrl: this.url,
        page: pagingDefaults.page,
        totalNumberOfPages,
        resultsPerPage: parseInt(resultsPerPage || pagingDefaults.resultsPerPage),
        sort: sort || sortingDefaults.sort,
      },
      sortingOptions,
    )

    const templateName = isHomepage ? 'Home' : 'Category'

    // When the page title is not explicitly set, use `category title`
    // as the default for category pages and `shop name` or `category title` as the
    // default for the home page. The Theme takes care of maybe `concat`ing the `shop title`
    const pageTitle =
      category.get('titleTag') || (isHomepage ? shop.get('title') || category.get('title') : category.get('title'))

    const metaDescription = category.get('metaDescription')

    const metaTags = []
      .concat(metaDescription ? [<meta key="description" name="description" content={metaDescription} />] : [])
      .concat(sort || location.query.page ? [<meta key="robots" name="robots" content="noindex, follow" />] : [])

    const products = productData.products.slice(0, page * pagingDefaults.resultsPerPage)

    return (
      <PageOrCategoryContext.Provider value={this.props.category}>
        <Theme
          withLayout
          title={pageTitle}
          titleShouldIncludeShop={!isHomepage && !category.get('titleTag')}
          currentView={templateName}
          metaTags={metaTags}
          linkTags={createMultiLanguageLinkTags(shop, isHomepage ? '' : `c/${category.get('categoryId')}`)}
        >
          {(renderView, props) =>
            renderView(templateName, {
              ...props,
              category: category.toJS(),
              productData: {
                products,
                sort: sortings,
                totalNumberOfProducts,
                categoryId: productData.categoryId,
              },
              sortingOptions,
              totalNumberOfPages,
              showScrollButton,
              onScrollIntoView: (inView) => {
                this.setState({ showScrollButton: !inView })
              },
            })
          }
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default compose(
  connect((state, props) => {
    const guidOrRouterParam = CategoryRaw.getGuid(state, props) || props.params.splat
    const productData = getPlain(state.getIn(['categoryProductData', guidOrRouterParam]))

    return {
      shop: state.get('shop'),
      categorySlugsToGUIDs: state.get('categorySlugsToGUIDs'),
      categories: state.get('categories'),
      viewError: state.getIn(['view', 'error']),
      category: state.get('categories').get(guidOrRouterParam),
      productData: { products: [], ...productData },
    }
  }),
  withReduxContext,
)(CategoryRaw)
