import { ReactReduxContext } from 'react-redux'
import React from 'react'
import hoistStatics from 'hoist-non-react-statics'

export default function withReduxContext(Component) {
  class WrapperComponent extends React.Component {
    render() {
      return (
        <ReactReduxContext.Consumer>
          {(context) => <Component {...this.props} store={context.store} />}
        </ReactReduxContext.Consumer>
      )
    }
  }

  return hoistStatics(WrapperComponent, Component)
}
