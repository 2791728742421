import PropTypes from 'prop-types'
import React from 'react'
import ReactProgressBarPlus from 'react-progress-bar-plus'

import withReduxContext from '../utils/withReduxContext'

export class XhrLoadingBar extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  state = { runningRequests: 0, percent: -1 }

  componentDidMount() {
    const { api } = this.props.store

    this.requestInterceptor = api.interceptors.request.use(
      (config) => {
        this.setState({ runningRequests: this.state.runningRequests + 1 })
        return config
      },
      (err) => {
        return Promise.reject(err)
      },
    )

    this.responseInterceptor = api.interceptors.response.use(
      (response) => {
        this.setState({ runningRequests: this.state.runningRequests - 1 })
        return response
      },
      (err) => {
        this.setState({ runningRequests: this.state.runningRequests - 1 })
        return Promise.reject(err)
      },
    )
  }

  componentWillUnmount() {
    const { api } = this.props.store

    this.timeout && clearTimeout(this.timeout)

    api.interceptors.request.eject(this.requestInterceptor)
    api.interceptors.response.eject(this.responseInterceptor)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.runningRequests > 0 && this.state.runningRequests === 0) {
      this.setState({ percent: 100 })

      // reset the progress bar after having shown it for some time
      this.timeout = setTimeout(() => {
        this.setState({ percent: -1 })
      }, 400)
    }

    if (prevState.runningRequests === 0 && this.state.runningRequests > 0) {
      this.timeout && clearTimeout(this.timeout)
      this.setState({ percent: 1 })
    }
  }

  render() {
    return <ReactProgressBarPlus {...this.props} percent={this.state.percent} autoIncrement={true} intervalTime={500} />
  }
}

export default withReduxContext(XhrLoadingBar)
