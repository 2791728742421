import PropTypes from 'prop-types'
import React from 'react'

import Link from '../../templateComponents/Link'
import NewsletterForm from '../../templateComponents/NewsletterForm'

export default function Order({ shop, order, t }) {
  return (
    <div className="order">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>{t('views.storefrontView.orderConfirmationPageSection.title')}</h1>
            {(order.paymentData.failed || order.paymentData.canceled) && (
              <div className="order-confirmation-warning">
                <span>{t('views.storefrontView.orderConfirmationPageSection.errorStatus.paymentWarningMessage')}</span>
              </div>
            )}
            <header className="order-confirmation">
              {order.paymentData.success && (
                <div className="order-confirmation-status">
                  <div className="status-order">
                    <h2>{t('views.storefrontView.orderConfirmationPageSection.successStatus.orderReceived')}</h2>
                    <p className="status-order-email">
                      {t('views.storefrontView.orderConfirmationPageSection.successStatus.emailNote', {
                        email: order.billingAddress.emailAddress,
                      })}
                    </p>
                    <p className="status-order-number">
                      {t('views.storefrontView.orderConfirmationPageSection.successStatus.orderNumber', {
                        orderNumber: order.orderNumber,
                      })}
                    </p>
                  </div>
                  <NewsletterForm className="status-newsletter-form">
                    <div className="status-newsletter">
                      <h2>{t('components.newsletterSubscriptionComponent.title')}</h2>
                      <div className="status-newsletter-form-register">
                        <input
                          className="status-newsletter-form-field"
                          name="email"
                          type="email"
                          required
                          placeholder={t(
                            'components.newsletterSubscriptionComponent.emailAddressInputField.placeholder',
                          )}
                        />
                        <button className="status-newsletter-form-button" type="submit">
                          <span>{t('components.newsletterSubscriptionComponent.submitButton.label')}</span>
                        </button>
                      </div>
                    </div>
                  </NewsletterForm>
                  <div className="status-home">
                    <h2>{t('views.storefrontView.orderConfirmationPageSection.successStatus.whatNext')}</h2>
                    <Link to={shop.href} className="status-home-link" title={shop.title}>
                      {t('views.storefrontView.returnToStartpageLink.label')}
                    </Link>
                  </div>
                </div>
              )}

              {(order.paymentData.failed || order.paymentData.canceled) && (
                <div className="order-confirmation-status">
                  <div className="status-order">
                    <h2>{t('views.storefrontView.orderConfirmationPageSection.errorStatus.orderReceived')}</h2>
                    <p>
                      {t('views.storefrontView.orderConfirmationPageSection.successStatus.emailNote', {
                        email: order.billingAddress.emailAddress,
                      })}
                    </p>
                    <p>{t('views.storefrontView.orderConfirmationPageSection.errorStatus.contactUs')}</p>
                    <p className="status-order-number">
                      {t('views.storefrontView.orderConfirmationPageSection.successStatus.orderNumber', {
                        orderNumber: order.orderNumber,
                      })}
                    </p>
                  </div>
                </div>
              )}
              {order.shippingAddress && (
                <div className="order-confirmation-address shipping">
                  <h3>{t('views.storefrontView.orderConfirmationPageSection.successStatus.shippingAddress')}</h3>
                  <ul>
                    {order.shippingAddress.company && <li>{order.shippingAddress.company}</li>}
                    <li>
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </li>
                    <li>{order.shippingAddress.street}</li>
                    {order.shippingAddress.streetDetails && <li>{order.shippingAddress.streetDetails}</li>}
                    <li>
                      {order.shippingAddress.zipCode} {order.shippingAddress.city}
                    </li>
                    <li>{order.shippingAddress.country}</li>
                  </ul>
                </div>
              )}
              <div className="order-confirmation-address billing">
                {order.shippingAddress ? (
                  <h3>{t('views.storefrontView.orderConfirmationPageSection.successStatus.billingAddress')}</h3>
                ) : (
                  <h3>{t('views.storefrontView.orderConfirmationPageSection.successStatus.billingShippingAddress')}</h3>
                )}
                <ul>
                  {order.billingAddress.company && <li>{order.billingAddress.company}</li>}
                  <li>
                    {order.billingAddress.firstName} {order.billingAddress.lastName}
                  </li>
                  <li>{order.billingAddress.street}</li>
                  {order.billingAddress.streetDetails && <li>{order.billingAddress.streetDetails}</li>}
                  <li>
                    {order.billingAddress.zipCode} {order.billingAddress.city}
                  </li>
                  <li>{order.billingAddress.country}</li>
                </ul>
              </div>
            </header>
          </div>
        </div>
      </section>
    </div>
  )
}

Order.propTypes = {
  order: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}
