import React, { useState } from 'react'
import { func, number, shape, string } from 'prop-types'

import Lightbox from './Lightbox'
import Portal from './Portal'

export default function EnergyLabel({ energyLabel, energyLabelSourceFile, t }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="energy-label-wrapper">
      <span
        onClick={() => setIsOpen(true)}
        className={`energy-label energy-label-grade-${energyLabel.colorId}`}
        title={t('components.energyLabelComponent.energyEfficencyClass')}
      >
        {energyLabel.text}
      </span>
      <Portal>
        <Lightbox show={isOpen} onClose={() => setIsOpen(false)}>
          <img className="energy-label-image" src={energyLabelSourceFile} alt={energyLabel.text} />
        </Lightbox>
      </Portal>
    </div>
  )
}

EnergyLabel.propTypes = {
  energyLabel: shape({ colorId: number.isRequired, text: string.isRequired }).isRequired,
  energyLabelSourceFile: string.isRequired,
  t: func.isRequired,
}
