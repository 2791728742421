import { bool, string } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import React from 'react'

import { PageOrCategoryContext } from './Category'
import { setPageAsync } from '../store/actions'
import Theme from '../components/Theme'

class Page extends React.Component {
  static propTypes = {
    isBeyondShop: bool.isRequired,
    shopTitle: string.isRequired,
    viewError: map,
    page: map,
  }

  static storeUpdate = (props, state) =>
    props.location.action === 'PUSH' || !state.getIn(['pages', props.params.pageSlug])
      ? [setPageAsync(props.params.pageSlug)]
      : []

  static contentCreationDisabled = () => false

  render() {
    const { viewError, page, isBeyondShop, shopTitle } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="page" />
    if (!page) return null

    const isBeyondHomepage = isBeyondShop && page.get('slug') === 'home'

    // When a page title is not explicitly set, use the normal title of the page as the default.
    //
    // If it is a Beyond shop, the page could be the homepage.
    // In this case, use the shop name if set, otherwise the normal title of the page, as the default.
    const pageTitle = page.get('titleTag') || (isBeyondHomepage ? shopTitle || page.get('title') : page.get('title'))

    const metaDescription = page.get('metaDescription')
    const metaTags = metaDescription ? [<meta key="description" name="description" content={metaDescription} />] : []

    return (
      <PageOrCategoryContext.Provider value={this.props.page}>
        <Theme
          withLayout
          currentView="page"
          title={pageTitle}
          titleShouldIncludeShop={!isBeyondHomepage && !page.get('titleTag')}
          metaTags={metaTags}
        >
          {(renderView, props) =>
            renderView('Page', {
              ...props,
              page: page.toJS(),
            })
          }
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default connect((state, props) => ({
  viewError: state.getIn(['view', 'error']),
  page: state.get('pages').get(props.params.pageSlug),
  isBeyondShop: Boolean(state.getIn(['shop', 'beyond'])),
  shopTitle: state.getIn(['shop', 'title']),
}))(Page)
